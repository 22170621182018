import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment-timezone';

import { AttachedTab, LogPluginComponents, apiUrls, apiUtils, controls } from '@verdaccio/crminfo';

import authFetch from '../../../utils/authFetch';

import styles from './index.styl';

const LOG_EVENT_GROUP = [
  ['freelancers_pool_calibration', 'freelancers_pool_calibration'],
  ['gpt_project_pool_calibration', 'gpt_project_pool_calibration'],
  ['easy_orders_modeling_&_calibration', 'easy_orders_modeling_&_calibration'],
  ['orders_settings', 'orders_settings'],
  ['other_settings', 'other_settings'],
  ['nt_pool_calibration', 'nt_pool_calibration'],
];

const Log = () => {
  const [isOpened, toggleIsOpened] = useState(false);
  const [sorting, setSorting] = useState({
    field: 'created_at',
    isAsc: false,
  });
  const [filter, seFilter] = useState({});
  const [isLoading, toggleLoading] = useState(true);
  const [logData, setLogData] = useState([]);
  const [logTotal, setLogTotalPage] = useState(0);

  const logRef = useRef({
    isFirstRender: true,
    page: 1
  });

  const onToggleIsOpened = () => {
    toggleIsOpened(!isOpened);
  };

  const onSetSorting = (key, value) => {
    setSorting(__prevValue => ({ ...__prevValue, field: key, isAsc: value }))
  };

  const onSetFilter = (key, value) => {
    seFilter(__prevValue => ({ ...__prevValue, [key]: value }));
  };

  const renderHeader = () => {
    const filterOptions = {
      eventFilterList: LOG_EVENT_GROUP,
      eventOwnerFilterList: [],
    };
  
    const customHeadersConfigs = {
      eventTagFilterConfig: {
        isMultiple: true,
        value: filter ? filter.event_tag : '',
        options: LOG_EVENT_GROUP,
        onChange: value => onSetFilter('event_tag', value),
      },
      timeFilterConfig: {
        name: 'created_at',
        onChange: isAsc => onSetSorting('created_at', isAsc),
      },
    };

    return (
      <LogPluginComponents.LogHeader 
        sorting={sorting}
        customHeadersConfigs={customHeadersConfigs}
        filterOptions={filterOptions}
      />
    )
  };

  const renderBody = () => {
    const groupedLogData = logData.reduce((prev, next) => {
      const { created_at: createdAt } = next;
      const currentDateLog = moment(createdAt).format('DD MMM YYYY');
      if (!prev.find(item => item.isStriped && item.date === currentDateLog)) {
        prev = [...prev, {
          isStriped: true,
          date: currentDateLog
        }, next]
      } else {
        prev = [...prev, next];
      }

      return prev;
    }, []); 

    return (
      <div>
        {
          groupedLogData.map(logItem => {
            if (logItem.isStriped) {
              return (
                <LogPluginComponents.LogDateRow
                  key={logItem.date}
                  date={logItem.date}
                />
              )
            }
            return (
              <LogPluginComponents.LogRow
                key={logItem._id}
                log={logItem}
              />
            )
          })
        }
      </div>
    )
    

  }

  const getData = (mergeResults) => {
    toggleLoading(true);
    authFetch(apiUrls.systemSettingsLogs(apiUtils.getA5TableQueryString(logRef.current.page, sorting, filter)))
      .then((data) => {
        const { results = [], page, total } = data;
        
        if (mergeResults) {
          setLogData(__prevData => ([...__prevData, ...results]));
        } else {
          setLogData(results);
        }

        setLogTotalPage(total);
        toggleLoading(false);
      })
  };

  const onShowMore = () => {
    logRef.current.page += 1;
    getData(true);
  }

  useEffect(() => {
    if (!isOpened || logData.length > 0) return
    
    getData();
  }, [isOpened]);

  useEffect(() => {
    if (logRef.current.isFirstRender) {
      logRef.current.isFirstRender = false;
      return;
    }

    logRef.current.page = 1;

    getData();
  }, [filter, sorting]);

  return (
    <div className={styles.settings_log}>
      <AttachedTab title="Log" opened={isOpened} onClick={onToggleIsOpened} isDetach={false}>
        <LogPluginComponents.LogBody classes={styles.settings_log_log_body} loading={isLoading && logRef.current.page === 1} renderHeader={renderHeader} renderBody={renderBody} />
        {isLoading && logRef.current.page > 1 && <div className={styles.settings_log_loader}><controls.Loader smLoader /></div>}
        {!isLoading && logTotal > logData.length && <div className={styles.settings_log_show_more} onClick={onShowMore}>Show more</div>}
      </AttachedTab>
    </div>
  )
};

export default Log;
