import { useRef, useEffect } from 'react';

/*
how to use

const myPreviousState = usePrevious(apiOptions);
const [data, updateData] = useState([]);
useEffect(() => {
  if (myPreviousState && !_.isEqual(myPreviousState, apiOptions)) {
    updateData(apiOptions);
  }
}, [apiOptions])
*/


const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export default usePrevious;
