import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  pluginConfigs,
  mainHubActions,
  controls
} from '@verdaccio/crminfo';

import styles from './create-promo-button.styl';

class CreatePromoButton extends Component {

  render() {
    return (
      <div className={styles.container}>
        <div className={styles.btn_add}>
          <controls.Button primary onClick={() => { this.props.createDiscount(); }}>New</controls.Button>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    createDiscount: () => dispatch(mainHubActions.addNewConfig(pluginConfigs.CreateDiscountPlugin())),
  };
};

export default connect(null, mapDispatchToProps)(CreatePromoButton);
