import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';

import {
    notificationsEntityStorePack,
    statsEntityStorePack,
    profileStorePack,
    settingsActions,
} from '@verdaccio/crminfo';
// import { isOnlyQaffOrCsr } from './../utils/roles';
import styles from './mainNav.styl';

class MainNav extends Component {
    componentDidMount() {
        const { load } = this.props;
        load();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.loading && !this.props.loading) {
            this.props.loadStats();
        }
    }

    render() {
        const { activeTickets, unreadMessages, ordersOffered, profile } = this.props;
        const roles = (profile && profile.roles) || [];

        return (
            <nav className={styles.nav_container}>
                <div className={styles.container}>
                    <ul className={styles.main_nav}>
                        <li className={styles.item}><NavLink activeClassName={styles.active} to="/orders">
                            Orders
                            {!!ordersOffered && <i className={styles.new}>{ordersOffered}</i>}
                        </NavLink></li>
                        <li className={styles.item}><NavLink activeClassName={styles.active} to="/tickets">
                            Tickets
                            {!!activeTickets && <i className={styles.new}>{activeTickets}</i>}
                        </NavLink></li>
                        <li className={styles.item}><NavLink activeClassName={styles.active} to="/threads">
                            Threads
                            {!!unreadMessages && <i className={styles.new}>{unreadMessages}</i>}
                        </NavLink></li>
                        {profile && profile.roles && !!roles.filter(r => r.indexOf('qaff') < 0 && r.indexOf('csr') < 0).length &&
                            <li className={styles.item}><NavLink activeClassName={styles.active} to="/writers">Freelancers</NavLink></li>
                        }
                        <li className={styles.item}><NavLink activeClassName={styles.active} to="/clients">Clients</NavLink></li>
                        {profile && profile.roles && !!roles.filter(r => r.indexOf('ntff') < 0).length &&
                          <li className={styles.item}><NavLink activeClassName={styles.active} to="/campaigns">Freelance Platform</NavLink></li>
                        }
                        {profile && profile.roles && roles.includes('manager') &&
                            <li className={styles.item}><NavLink activeClassName={styles.active} to="/promos">Promos</NavLink></li>
                        }
                        {profile && profile.roles && !!roles.filter(r => r.indexOf('qaff') < 0 && r.indexOf('csr') < 0).length &&
                            <li className={styles.item}><NavLink activeClassName={styles.active} to="/applicants">Applicants</NavLink></li>
                        }
                        {profile && profile.roles && (roles.includes('manager') || roles.includes('csr') || roles.includes('csr_at')) &&
                            <li className={styles.item}><NavLink activeClassName={styles.active} to="/callbacks">Callbacks</NavLink></li>
                        }
                        {profile && profile.roles && roles.includes('ppc_report') &&
                            <li className={styles.item}><NavLink activeClassName={styles.active} to="/reports">Reports</NavLink></li>
                        }
                        {profile && profile.roles && roles.includes('finance_manager') &&
                            <li className={styles.item}><NavLink activeClassName={styles.active} to="/finances">Finances</NavLink></li>
                        }
                        {profile && profile.roles && (roles.includes('csr_teamlead') || roles.includes('csr_manager')) &&
                            <li className={styles.item}><NavLink activeClassName={styles.active} to="/shifts">Shifts</NavLink></li>
                        }
                        {profile && profile.roles && (roles.includes('defense_controller')) &&
                            <li className={styles.item}><NavLink activeClassName={styles.active} to="/defense_controls">Defense Controls</NavLink></li>
                        }
                        {profile && profile.roles && (roles.includes('sms_sender')) &&
                            <li className={styles.item}><NavLink activeClassName={styles.active} to="/sms_sender">SMS sender</NavLink></li>
                        }
                    </ul>
                </div>
            </nav>
        );
    }
}

const mapStateToProps = state => {
    const statsEntity = state[statsEntityStorePack.name];
    const notificationsEntity = state[notificationsEntityStorePack.name];
    const profileEntity = state[profileStorePack.name];
    const roles = (!profileEntity.loading && profileEntity.data && profileEntity.data.roles) || [];
    const activeByGroup = (!statsEntity.loading && statsEntity.data && statsEntity.data.tickets_by_group) || {};
    const activeTickets = roles.reduce((result, item = '') => {
        const byRoleCount = activeByGroup[item.toUpperCase()] || 0;
        return result + byRoleCount;
    }, 0);

    const loading =
        !notificationsEntity ||
        notificationsEntity.loading ||
        !profileEntity ||
        profileEntity.loading ||
        state.settings.loading;
    return {
        loading,
        activeTickets,
        profile: profileEntity && profileEntity.data,
        unreadMessages: notificationsEntity.loading || notificationsEntity.error ? 0 : notificationsEntity.data.messages,
        ordersOffered: notificationsEntity.loading || notificationsEntity.error ? 0 : notificationsEntity.data.orders_offered,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        load: () => {
            dispatch(notificationsEntityStorePack.getEntityAction());
            dispatch(profileStorePack.getEntityActionIfNeeded());
            dispatch(settingsActions.getSettingIfNeeded())
        },
        loadStats: () => dispatch(statsEntityStorePack.getEntityAction()),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MainNav));
