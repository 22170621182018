import { Component } from 'react';
import queryString from 'query-string';

import { setToken } from './../utils/tokenStorage';

class LoginPage extends Component {
  componentDidMount() {
    const { match, history } = this.props;
    const { token } = match.params;
    setToken(`Bearer ${token}`);
    const params = queryString.parse(window.location.search);
    const redirectUrl = params.redirect_url;
    let path = '/';
    if (redirectUrl) {
      const url = new URL(Buffer.from(redirectUrl, 'base64').toString());
      path = url.pathname + url.search;
    }
    history.replace(path);
  }

  render() {
    return null;
  }
}

export default LoginPage;
