import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Table,
  clientsTableStorePack,
  settingsActions,
  utils,
  pluginConfigs,
  mainHubActions,
  controls,
  clientTags,
} from '@verdaccio/crminfo';

import CreateClientButton from './../components/CreateClientButton';
import styles from './client-page.styl';

const CLIENTS_TAGS = clientTags.map(tag => [tag, tag]);

class ClientsPage extends Component {
  componentDidMount() {
    this.props.getSettings();
    const clientId = this.props.match.params.clientId;
    if (clientId) {
      this.props.pluginSetConfig(pluginConfigs.ClientPlugin({ clientId, options: { closeUrl: '/clients' } }));
    }
  }

  componentDidUpdate(prevProps) {
    const clientId = this.props.match.params.clientId;
    const prevClientId = prevProps.match.params.clientId;
    if (clientId !== prevClientId && clientId) {
      this.props.pluginSetConfig(pluginConfigs.ClientPlugin({ clientId, options: { closeUrl: '/clients' } }));
    }
  }

  onClickRow(item) {
    const routeClientId = this.props.match.params.clientId;
    routeClientId === item.id
      ? this.props.pluginSetConfig(pluginConfigs.ClientPlugin({ clientId: item.id, options: { closeUrl: '/clients' } }))
      : this.props.history.push('/clients/' + item.id);
  }

  render() {
    const { setFilter, clientSites, setSorting, countryList } = this.props;

    const tableConfig = {
      onClickRow: item => this.onClickRow(item),
      fields: [
        {
          name: 'When created',
          orderable: true,
          formatter: (item) => utils.formatTime(item.created_at, 'd'),
          sortingConfig: {
            name: 'created_at',
            onChange: isAsc => setSorting('created_at', isAsc),
          },
        },
        {
          name: 'Site',
          formatter: (item) => item.site,
          filterConfig: {
            value: this.props.filter.client_site,
            options: clientSites,
            onChange: value => setFilter('client_site', value),
            isMultiple: true,
          },
        },
        {
          name: 'Client Name',
          formatter: (item) => <div> {item.name || item.phone || item.email} <controls.NewWindowButton inTable className="icon_new_window" href={`/clients/${item.id}`} /></div>
        },
        {
          name: 'Country',
          formatter: (item) => item.country,
          filterConfig: {
            value: this.props.filter.country,
            options: countryList,
            onChange: value => setFilter('country', value),
            isMultiple: true,
          }
        },
        {
          name: 'Email',
          formatter: (item) => item.email,
          width: 2,
        },
        {
          name: 'Phone number',
          formatter: (item) => item.phone
        },
        {
          name: 'Last order',
          formatter: (item) => utils.formatTime(item.last_order_at, 'd'),
          sortingConfig: {
            name: 'profile.last_order_id',
            onChange: isAsc => setSorting('profile.last_order_id', isAsc),
          },
        },

        {
          name: 'Placed orders',
          formatter: (item) => item.orders_count,
          sortingConfig: {
            name: 'profile.orders_count',
            onChange: isAsc => setSorting('profile.orders_count', isAsc),
          },
          classnames: ['centered'],
        },
        {
          name: 'Paid orders',
          formatter: (item) => item.paid_orders_count,
          sortingConfig: {
            name: 'profile.paid_orders_count',
            onChange: isAsc => setSorting('profile.paid_orders_count', isAsc),
          },
          classnames: ['centered'],
        },
        {
          name: 'Tags',
          formatter: (item) => item.tags.length > 0 ? <div className={styles.tags}>#{item.tags.join(', ')}</div> : '',
          filterConfig: {
            value: this.props.filter.tags,
            options: CLIENTS_TAGS,
            onChange: value => setFilter('tags', value),
            isMultiple: true,
            withCustom: true,
          },
          ddRight: true,
        }
      ]
    }
    return (
      <div>
        <CreateClientButton />
        <Table {...this.props} tableConfig={tableConfig} sticky stickyThreshold="58px" mainLoader />
      </div>
    )
  }
}

const mapStateToProps = state => {

  const clientSites = [];
  const sites = state.settings.sites || [];
  sites.map(site => {
    if (site.name.length <= 0) return null;
    return clientSites.push([site.name, site._id])
  });
  const countryList = (state.settings.country_list || []).map(r => [r, r]);

  return {
    loading: state.clients.loading,
    loadingNext: state.clients.loadingNext,
    list: state.clients.data.list,
    total: state.clients.data.total,
    countList: state.clients.data.countList,
    page: state.clients.data.page,
    error: state.clients.error,
    filter: state.clients.filter,
    sorting: state.clients.sorting,
    clientSites,
    countryList,
  }
};

const mapDispatchToProps = dispatch => ({
  load: () => dispatch(clientsTableStorePack.getTableAction()),
  loadNext: () => dispatch(clientsTableStorePack.getTableNextAction()),
  pluginSetConfig: config => dispatch(mainHubActions.addNewConfig(config)),
  getSettings: () => dispatch(settingsActions.getSettingIfNeeded()),
  // setSorting: (field, isAsc) => {
  //   dispatch(clientsTableStorePack.setTableSortingAction(field, isAsc));
  //   dispatch(clientsTableStorePack.getTableAction());
  // },
  setSorting: (field, isAsc) => {
    dispatch(clientsTableStorePack.setTableSortingAction(isAsc === null ? null : field, isAsc));
  },
  setFilter: (field, value) => {
    dispatch(clientsTableStorePack.setTableFilterAction(field, value));
  },
  // setFilter: (field, value) => {
  //   dispatch(clientsTableStorePack.setTableFilterAction(field, value));
  //   dispatch(clientsTableStorePack.getTableAction());
  // },
  onResetFilters: () => {
    dispatch(clientsTableStorePack.resetTableFilterAction());
    dispatch(clientsTableStorePack.getTableAction());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ClientsPage);
