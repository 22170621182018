import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
    controls,
    profileStorePack,
} from '@verdaccio/crminfo';


class ReportsPage extends Component {
  componentWillMount() {
    const { profile, history } = this.props;
    if (Object.keys(profile).length === 0) {
      return null;
    }
    if (!profile || !profile.roles || !profile.roles.includes('ppc_report')) {
      history.replace('/tickets');
    }
  }

  render() {

    return (
      <div>
        <controls.ReportsForm />
      </div>
    )
  }
}

const mapStateToProps = state => {
  const profileEntity = state[profileStorePack.name];

  return {
    profile: profileEntity && profileEntity.data,
  }
};

export default connect(mapStateToProps, null)(ReportsPage);
// export default ReportsPage;
