import React from 'react';
import { NavLink } from 'react-router-dom';

import stylesMainNav from '../mainNav.styl';
import styles from './index.styl';

const ToBugFormBtn = () => {
  return (
    <>
      <ul className={stylesMainNav.main_nav}>
        <li className={stylesMainNav.item}>
          <NavLink className={styles.link} activeClassName={styles.active} to="/bug_tracker">
            Bug tracker
          </NavLink>
        </li>
      </ul>
    </>
  )
};

export default ToBugFormBtn;
