import React, { useState, useEffect } from 'react';
import classnames from 'classnames';

import {
  controls,
} from '@verdaccio/crminfo';

import styles from './index.styl';

const ORDERS_SETTINGS_MAP = [
  {
    title: 'Monthly order limit (for all groups), orders',
    field: 'monthly_order_limit_all',
    size: 'orders',
    max: 999,
    defaultValue: 100,
  },
  {
    title: 'Order reserve time',
    field: 'allocate_reserve_time',
    size: 'minutes',
    max: 20,
    defaultValue: 7,
  },
  {
    title: 'A group orders in progress limit',
    field: 'allocate_order_limits_a',
    max: 99,
    defaultValue: 10,
  },
  {
    title: 'B group orders in progress limit',
    field: 'allocate_order_limits_b',
    max: 99,
    defaultValue: 10,
  },
  {
    title: 'Beginner, C-group orders in progress limit',
    field: 'allocate_order_limits_beginner',
    max: 99,
    defaultValue: 10,
  },
  {
    title: 'Newbie orders in progress limit',
    field: 'allocate_newbie_count',
    max: 99,
    defaultValue: 10,
  },
];

const ordersSettingsMapper = (systemSettings) => {
  return ORDERS_SETTINGS_MAP.reduce((prev, next) => {
    const { field, defaultValue } = next;
    const value = systemSettings[field] !== undefined ? systemSettings[field] : defaultValue;
    
    prev[field] = {...next, value };

    return prev
    
  }, {});
};

const OrdersSettings = ({ systemSettings, updateFinalData }) => {
  const [ordersSettingsValues, setOrdersSettingsValues] = useState(ordersSettingsMapper(systemSettings));

  const onOrdersSettingUpdate = (field) => {

    return (e) => {
      const { target: { validity: { valid }, value } } = e;

      if (!valid) return;

      let numValue = Number(value);

      const { max } = ordersSettingsValues[field];

      if (numValue < 0) numValue = 0;

      if (numValue > max) numValue = max;


      setOrdersSettingsValues(__prevValue => {
        return {
          ...__prevValue,
          [field]: {
            ...__prevValue[field],
            value: numValue
          }
        }
      })
    }
  };

  useEffect(() => {
    const obj = Object.entries(ordersSettingsValues).reduce((prev, next) => {
      const [ key, value ] = next;
      prev[key] = value.value
      return prev;
    }, {});
    updateFinalData((_prevValue) => {
      return {
        ..._prevValue, 
        ...obj
      }
    })
  }, [ordersSettingsValues]);

  return (
    <div>
      <h2 className={styles.settings_page_main_content__header}>Orders settings</h2>
      {
        ORDERS_SETTINGS_MAP.map(ordersSetting => {
          const ordersSettingValue = ordersSettingsValues[ordersSetting.field] || {};
          const { value, field, title, size } = ordersSettingValue;
          
          return (
            <div className={styles.settings_page_main_content_item} key={field}>
              <div className={classnames(styles.settings_page_main_content_item__flex)}>
                <div className={styles.first_flex_item}>{title}</div>
                <div className={classnames(styles.settings_page_main_content_item__flex, styles.settings_page_main_content_item__flex_gap_8)}>
                  <controls.Input pattern="[0-9]*" onChange={onOrdersSettingUpdate(field)} value={value} className={styles.settings_page_main_content_item__input} />
                  <div>{size}</div>
                </div>
              </div>
            </div>
          )
          
        })
      }
    </div>
  )
};

export default OrdersSettings;
