import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';

import {
  controls,
  icons,
  modals,
  apiUrls,
  mainHubActions,
  sandboxFCMOperatorsStorePack,
} from '@verdaccio/crminfo';

import authFetch from '../../utils/authFetch';

import styles from './index.styl';

const AssignFCMModal = ({ FCMoperatorsCount, unassignedCount }) => {
  const [checkedOperators, setCheckedOperators] = useState([]);
  const [isLoading, toggleLoading] = useState(false);
  const [errorWhileListLoading, toggleErrorWhileListLoading] = useState(false);
  const [isCheckedUnassignedCount, toggleCheckedUnassignedCount] = useState(false);
  const dispatch = useDispatch();
  const fcmOperatorsStore = useSelector(state => (state[sandboxFCMOperatorsStorePack.name]));

  const onSetCheckedOperators = (e) => {
    const { target: { name } } = e;
    setCheckedOperators(__prevValues => {
      if (__prevValues.includes(name)) {
        return __prevValues.filter(__operator => __operator !== name);
      }

      return  [...__prevValues, name];
    });
  };

  const onToggleCheckedUnassignedCount = (e) => {
    const { target: { checked } } = e;
    toggleCheckedUnassignedCount(checked);
  }

  const onSubmit = () => {
    const { sandboxFCMOperatorAssignee } = apiUrls;
    toggleLoading(true);
    authFetch(sandboxFCMOperatorAssignee, {
        method: 'POST',
        body: JSON.stringify({
          fcm_operators: checkedOperators.map(__operator => ({ email: __operator })),
          is_unassigned: !isCheckedUnassignedCount,
        })
      })
      .then(() => {
        setTimeout(() => {
          addModal(<modals.ModalSuccess classes={{ text: styles.assign_modal_container__success_text }} message="Assigning process was successfully initiated. Selected FCMs will be assigned to applicants with status new stage await_result in a few seconds." />)
        }, 1);
      })
      .catch(err => {
        console.error(err);
        setTimeout(() => {
          addModal(<modals.WarningModal message="Something went wrong." />)
        }, 1);
      })
      .finally(() => {
        toggleLoading(false);
        closeModal();
      });
  };

  const closeModal = () => {
    dispatch(mainHubActions.popModalComponent());
  };

  const addModal = (component) => {
    dispatch(mainHubActions.pushModalComponent(component));
  };

  const renderOperatorsList = () => {
    const { data: { fcmOperators = [] }, loading: FCMOperatorsLoading } = fcmOperatorsStore;

    if (isLoading || FCMOperatorsLoading) return <div className={styles.assign_modal_container__loader}><controls.Loader /></div>
    if (errorWhileListLoading) return <div className={classnames(styles.assign_modal_container__loader, styles['assign_modal_container__loader--error'])}>Error while loading FCM operators</div>
    if (fcmOperators.length === 0) return <div className={styles.assign_modal_container__loader}>No FCM operators</div>
    return (
      <>
        <ul className={styles.assign_modal_container_check_list}>
          {fcmOperators.map(({ email }) => {
            return (
              <li key={email}>
                <controls.Checkbox name={email} onChange={onSetCheckedOperators} checked={checkedOperators.includes(email)} className={styles.assign_modal_container_check_list__operator}>{email}</controls.Checkbox>
              </li>
            )
          })}
        </ul>
        <controls.Checkbox name="unassignedCount" onChange={onToggleCheckedUnassignedCount} checked={isCheckedUnassignedCount} className={styles.assign_modal_container_check_list__operator}>{`Override previous assigns (+ ${FCMoperatorsCount - unassignedCount} applicants)`}</controls.Checkbox>
        <div className={styles.assign_modal_container__btns}>
          <controls.Button onClick={onSubmit} disabled={checkedOperators.length === 0} className={styles['assign_modal_container__btns--submit']} primary>Submit</controls.Button>
        </div>
      </>
    )
  }

  useEffect(() => {
    const { data: { fcmOperators = [] }, loading, error } = fcmOperatorsStore;
    if (loading) return;

    if (error) {
      toggleErrorWhileListLoading(true);
      return;
    }

    if (fcmOperators.length > 0) {
      toggleLoading(false);
      return;
    }

    dispatch(sandboxFCMOperatorsStorePack.getEntityAction())
    
  }, [fcmOperatorsStore]);

  return (
    <div className={styles.assign_modal_container}>
      <div className={styles.assign_modal_container_header}>
        <h3 className={styles.assign_modal_container_header__header}>Assign FCMs</h3>
        <icons.CloseIcon onClick={closeModal} className={styles.assign_modal_container_header__close} />
      </div>
      <p className={styles.assign_modal_container__title}>Please select FCMs from the list below. They will be equally assigned to all existing applicants with status new, stage await_result with FCM not selected. {unassignedCount} total applicants will be affected.</p>
      {renderOperatorsList()}
    </div>
  );
};

export default AssignFCMModal;
