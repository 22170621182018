import React, { Component } from 'react';

import {
  controls,
  styles as crmStyles,
} from '@verdaccio/crminfo';

import Icon from './Icon';
import classnames from 'classnames';
import authFetch from './../utils/authFetch';
import { urls } from './../config';
import styles from './dropdown-select-project.styl';
import { getTokenExact } from './../utils/tokenStorage';

class DropdownSelectProject extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      apps: [],
    };

    this.onOpenChange = this.onOpenChange.bind(this);
    this.onSelect = this.onSelect.bind(this);
  }

  componentDidMount() {
    authFetch(urls.me).then((data) => {
      this.setState({
        apps: data.available_apps,
      });
    }).catch((e) => {
      debugger;
      console.log(e);
    });
  }

  onOpenChange(value) {
    this.setState({ isOpen: value });
  }

  onSelect(value) {
    this.onOpenChange(false);
  }

  render() {
    const { isOpen, apps } = this.state;
    const tokenExact = getTokenExact();
    const hostname = window.location.hostname;

    return (
      <div className={styles.container}>
        <controls.Dropdown.Dropdown onOpenChange={this.onOpenChange} isOpen={isOpen}>
          <div className={styles.dropdown_content}>
            <controls.Dropdown.Panel>
              <ul className={classnames(crmStyles.selectStyles.container, styles.project_list)}>
                {apps.map((item) => {
                  if (!item.front_app_uri) {
                    return null;
                  }
                  if (item.front_app_uri.indexOf(hostname) > 0) {
                    return null;
                  }
                  const loginUrl = `${item.front_app_uri}/login/${tokenExact}`;
                  if (item.name.toLowerCase().indexOf('smstool') === 0) {
                    return (
                      <li key={item.name} className={classnames(crmStyles.selectStyles.item, styles['menu_smstool'], styles.item_proj)}>
                        <a href={loginUrl} onClick={() => this.onSelect(item)} target="_blank">{item.name}</a>
                      </li>
                    )
                  }
                  if ((item.name).toLowerCase().indexOf('tn-company') === 0 ||
                    (item.name).toLowerCase().indexOf('crm') === 0 ||
                    (item.name).toLowerCase().indexOf('operator') === 0 ||
                    (item.name).toLowerCase().indexOf('albatros') === 0) {
                    return (
                      <li key={item.name} className={classnames(crmStyles.selectStyles.item, styles['menu_albatros'], styles.item_proj)}>
                        <a href={loginUrl} onClick={() => this.onSelect(item)} target="_blank">{item.name}</a>
                      </li>
                    )
                  }
                  return (
                    <li key={item.name} className={classnames(crmStyles.selectStyles.item, styles['menu_other'], styles.item_proj)}>
                      <a href={loginUrl} onClick={() => this.onSelect(item)} target="_blank">{item.name}</a>
                    </li>
                  )
                })}
              </ul>
            </controls.Dropdown.Panel>
          </div>
        </controls.Dropdown.Dropdown>
        <controls.Button emptyDefault onClick={() => { this.onOpenChange(true); }}><Icon className="svg_icon" iconName="tools-menu" /></controls.Button>
      </div>
    );
  }
}

export default DropdownSelectProject;
