import React, { Component } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';

import FinanceNav from './../components/FinanceNav';
import styles from './finances.styl';
import { PAGES, hasAcces } from './../utils/userAccess';

import {
  Table,
  settingsActions,
  profileStorePack,
  controls,
  styles as crmStyles,
} from '@verdaccio/crminfo';

class PaymentAccountsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.onOpenChange = this.onOpenChange.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.getDropControl = this.getDropControl.bind(this);
  }
  componentWillMount() {
    const { profile, history } = this.props;

    if (!hasAcces(PAGES.FINANCES, profile.roles)) {
      history.replace('/tickets');
      return;
    }
  }

  onSelect(id, system, value, isParent = false, paymentAccountsAllowed) {
    if (isParent) {
      const { sitePaymentAccounts } = this.props;
      const children = sitePaymentAccounts[value].filter(sP => paymentAccountsAllowed[value].includes(sP.value));
      this.onOpenChange(true, system, id, children, value);
      return;
    }
    const { updatePaymentAccount } = this.props;
    const data = {
      action: 'site_payment_account',
      site_id: id,
      system,
      value
    };
    updatePaymentAccount(data);
    this.onOpenChange(false, system, id);
  }

  onOpenChange(value, system, id, children = [], parent = '') {
    this.setState({
      [id]: {
        [system]: {
          isOpen: value,
          children,
          parent
        }
      },
    });
  }

  getDropControl(system = '', values = [], selectPaymentAccounts = {}, id, isParent = false, paymentAccountsAllowed) {
    const siteDrop = this.state[id] || {};
    const { sitePaymentAccounts } = this.props;
    let displayName = 'Disabled';

    let cardSystemSelected = null;
    const siteDropSystem = siteDrop[system];
    const selectValue = selectPaymentAccounts[system];
    
    let isDisabled = false;
    if (selectValue) {
      displayName = values.filter(it => it.value === selectValue)[0].name;
    }

    if (system === 'cards') {
      if (selectPaymentAccounts['stripe']) {
        displayName = `Stripe - ${sitePaymentAccounts.stripe.filter(it => it.value === selectPaymentAccounts['stripe'])[0].name}`;
        cardSystemSelected = 'stripe';
      }

      if (selectPaymentAccounts['solidgate']) {
        displayName = `Solidgate - ${sitePaymentAccounts.solidgate.filter(it => it.value === selectPaymentAccounts['solidgate'])[0].name}`;
        cardSystemSelected = 'solidgate';
      }

      if ((!paymentAccountsAllowed['stripe'] || !paymentAccountsAllowed['stripe'].length) && 
        (!paymentAccountsAllowed['solidgate'] || !paymentAccountsAllowed['solidgate'].length)) return (
          <span>
            { displayName }
          </span>
        );
    } else {
      if (!paymentAccountsAllowed[system] || !paymentAccountsAllowed[system].length) return (
        <span>
          { displayName }
        </span>
      );
    }

    
    if (system === 'cards') {
      values = values.filter(it => paymentAccountsAllowed[it.value] && paymentAccountsAllowed[it.value].length > 0);
      isDisabled = values.length === 1 && paymentAccountsAllowed[values[0].value].length === 1

    } else {
      values = values.filter(it => paymentAccountsAllowed.paypal.includes(it.value));
      values = [...values, { value: '', name: 'Disabled' }];
    }
    return (
      <span>
        {isDisabled ? 
          (
            <span>{ displayName }</span>
          ) : (
            <a onClick={() => this.onOpenChange(true, system, id)}>
              { displayName }
            </a>
          )
        }
      <controls.Dropdown.Dropdown onOpenChange={(value) => this.onOpenChange(value, system, id)} isOpen={siteDropSystem && siteDropSystem.isOpen}>
            <div className={styles.dropdown_content}>
              <controls.Dropdown.Panel>
                <ul className={classnames(crmStyles.selectStyles.container, { [styles.multi_select]: isParent })}>
                  {values.map(item =>
                    <li key={item.value} className={classnames(crmStyles.selectStyles.item, {
                      [crmStyles.selectStyles.active]: item.value === selectValue || (!selectValue && item.value === '') || item.value === cardSystemSelected,
                      [styles.select_parent_item]: isParent
                    })}>
                      <a onClick={() => this.onSelect(id, system, item.value, isParent, paymentAccountsAllowed)}>{item.name}</a>
                      { isParent &&
                        <div className={styles.arrow_container}>
                          <controls.ArrowDown size={7} />
                        </div>
                      }
                      {
                        siteDropSystem && siteDropSystem.isOpen && siteDropSystem.children && siteDropSystem.children.length > 0 && siteDropSystem.parent === item.value &&  
                            <div className={styles.nested_list}>
                              <ul className={crmStyles.selectStyles.container}>
                                {
                                  siteDropSystem.children.map(child =>
                                    <li key={child.value} className={classnames(crmStyles.selectStyles.item, {
                                      [crmStyles.selectStyles.active]: child.value === selectPaymentAccounts[cardSystemSelected] || (!selectPaymentAccounts[cardSystemSelected] && child.value === ''),
                                    })}>
                                      <a onClick={() => this.onSelect(id, item.value, child.value)}>{child.name}</a>
                                    </li>
                                  )
                                }
                              </ul>
                            </div>
                      }
                    </li>
                  )}
                </ul>
              </controls.Dropdown.Panel>
            </div>
          </controls.Dropdown.Dropdown>
      </span>
    )
  }

  render() {
    const { sitePaymentAccounts = {} } = this.props;

    const tableConfig = {
      fields: [
        {
          name: 'Site name',
          formatter: item => item.name,
          width: 1.2,
        },
        {
          name: 'Paypal',
          formatter: item => this.getDropControl('paypal', sitePaymentAccounts.paypal, item.paymentAccounts, item.id, false, item.paymentAccountsAllowed),
        },
        {
          name: 'Cards',
          formatter: item => this.getDropControl('cards', [{ value: 'stripe', name: 'Stripe' }, { value: 'solidgate', name: 'Solidgate' }], item.paymentAccounts, item.id, true, item.paymentAccountsAllowed),
        },
      ]
    };

    return (
      <div>
        <div className={styles.filter}>
          <FinanceNav />
        </div>
        <Table {...this.props} tableConfig={tableConfig} sticky mainLoader />
      </div>
    )
  }
}
const mapStateToProps = state => {
  const profileEntity = state[profileStorePack.name];
  const profile = profileEntity && profileEntity.data;

  const _sites = state.settings && state.settings.sites && (state.settings.sites.filter(site => site.has_payment_accounts) || []);
  const paymentAccounts = state.settings && (state.settings.payment_accounts || []);
  
  let list = [];
  let sitePaymentAccounts = {};

  if (paymentAccounts) {
    sitePaymentAccounts = paymentAccounts.reduce((prev, next = {}) => {
      const prevValue = prev[next.type];
      if (prevValue) prev[next.type] = [...prevValue, ...[{ value: next.value , name: next.name }] ];
      else {
        prev[next.type] = [{ value: next.value , name: next.name }];
      }
      return prev
    }, {});
  }

  if (_sites) {
  
    list = _sites.map(site => ({
      name: site.name,
      id: site._id,
      paymentAccounts: site.payment_accounts || {},
      paymentAccountsAllowed: site.payment_accounts_allowed || {},
    }));
  }
  
  return {
    loading: state.settings && state.settings.loading,
    loadingNext: state.settings && state.settings.loading,
    list: list || [],
    total: list.length || 1,
    page: 1,
    error: state.settings && state.settings.error,
    profile,
    sitePaymentAccounts,
  }
};

const mapDispatchToProps = dispatch => ({
  load: () => () => {},
  loadNext: () => () => {},
  updatePaymentAccount: (data) => dispatch(settingsActions.updateSettings(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentAccountsPage);
