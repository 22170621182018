import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  campaignsStorePack,
  controls,
  readFileList,
} from '@verdaccio/crminfo';

import styles from './create-campaign-upload.styl';

class CreateCampaignUpload extends Component {
  constructor(props) {
    super(props);

    this.onFileChange = this.onFileChange.bind(this);
    this.uploadFile = this.uploadFile.bind(this);
  }

  onFileChange(event) {
    const fileList = event.target.files;
    this.uploadFile(fileList);
    event.target.value = null;
  }

  uploadFile(fileList) {
    const { create } = this.props;
    if (!fileList.length) {
      return;
    }

    readFileList([fileList[0]]).then(fileTexts => {
      create(fileTexts);
    });
  }

  render() {
    const { loading, error } = this.props;

    return (
      <div className={styles.container}>
        {!!error &&
          <div className={styles.error}>{error}</div>
        }
        <controls.UploadContainer
          onChange={this.uploadFile}
          loading={loading}
          progress={0}
          text="Drop here"
        >
          <controls.UploadButton primary onChange={this.onFileChange} disabled={loading} accept=".yaml">
            Add New Campaign
          </controls.UploadButton>
        </controls.UploadContainer>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const campaignsState = state[campaignsStorePack.name];
  return {
    loading: campaignsState.loadingCreate,
    error: campaignsState.errorCreate
  };
};
const mapDispatchToProps = dispatch => ({
  create: campaigns => dispatch(campaignsStorePack.createTableAction(campaigns)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateCampaignUpload);
