import React, { useEffect, useMemo, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { controls, Table, utils } from '@verdaccio/crminfo';
import moment from 'moment-timezone';

import { crminfo } from '../../config';
import authFetch from '../../utils/authFetch';

import styles from './index.styl';

const BASE_URL = `${crminfo.apiHost}${crminfo.apiVersionPart}/defense`;
const GET_IP_URL = `${BASE_URL}/iplist`;
const DOWNLOAD_IP_URL = `${GET_IP_URL}?export=true`;
const GET_SETTINGS_URL = `${BASE_URL}/settings`;

const DefenseControls = () => {
  const [idData, setIpData] = useState([]);
  const [defenseSettings, setDefenseSettings] = useState({});
  const [loading, toggleLoading] = useState(true);
  const [downloadLoading, toggleDownloadLoading] = useState(false);

  const tableConfig = useMemo(() => ({
    fields: [
      {
        name: 'Date (UTC)',
        formatter: ({ created_at }) => utils.formatTime(moment.utc(created_at), 'short'),
        width: 1,
      },
      {
        name: 'Ip',
        formatter: ({ ip }) => ip,
        width: 3,
      },
    ]
  }));

  const onDefenseSystemSwitch = () => {
    const { stateon } = defenseSettings;
    setDefenseSettings({...defenseSettings, stateon: !stateon });
    
    authFetch(GET_SETTINGS_URL, {
      method: 'PATCH',
      body: JSON.stringify({ stateon: !stateon })
    })
    .then((defenseSettings) => {
      setDefenseSettings(defenseSettings);
    });
  };

  const onDownloadAll = () => {
    toggleDownloadLoading(true);
    authFetch(DOWNLOAD_IP_URL)
      .then(({ link }) => {
        setTimeout(() => {
          window.open(link, '_blank');
        }, 1);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        toggleDownloadLoading(false);
      });
  };

  useEffect(() => {
    const promises = [authFetch(GET_IP_URL), authFetch(GET_SETTINGS_URL)];
    Promise.all(promises).then(([ ipData, defenseSettings ]) => {
      const { ips } = ipData || {};
      unstable_batchedUpdates(() => {
        setIpData(ips);
        setDefenseSettings(defenseSettings);
        toggleLoading(false);
      });
    })
    .catch((error) => {
      console.error(JSON.stringify(error));
      toggleLoading(false);
    });
  }, []);

  if (loading) return <div className={styles.defense_controls_page_loader_wrapper}><controls.Loader /></div>

  const { attack = 'No', stateon = false } = defenseSettings;

  return (
    <div className={styles.defense_controls_page}>
      <controls.Switch className={styles.defense_controls_page__defense_system} isOn={stateon} handleToggle={onDefenseSystemSwitch} onColor="#3099ff"><div>Defense system:</div></controls.Switch>
      <div className={styles.defense_controls_page__defense_system}>
        <div>System is Under attack:</div>
        <strong className={styles.defense_controls_page__value}>{attack}</strong>
      </div>
      <div className={styles.defense_controls_page__defense_system}>
        <controls.Button onClick={onDownloadAll} disabled={downloadLoading}>{downloadLoading ? <controls.Loader smLoader /> : 'Download All'}</controls.Button>
      </div>
      <Table staticData={idData} tableConfig={tableConfig} sticky stickyThreshold="58px" infinity />
    </div>
  );

};

export default DefenseControls;
