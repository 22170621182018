 import React, { useState, useEffect, useRef } from 'react';
 import { useDispatch } from 'react-redux';

 import classnames from 'classnames';

 import { apiUrls, controls, modals, mainHubActions } from '@verdaccio/crminfo';

 import authFetch, { authFetchFormData } from '../../utils/authFetch';

 import styles from './index.styl';

 const INFO_MAPPER = [
  {
    title: 'Payoneer 1 (Nerdy):',
    field: 'free_payoneer_tokens_count_type1',
    btnName: 'token',
  },
  {
    title: 'Payoneer 2 (ETTOutsourceLTD):',
    field: 'free_payoneer_tokens_count_type2',
    btnName: 'token2',
  },
  {
    title: 'Payoneer 3 (EDSPIREDLIMITEDAccount):',
    field: 'free_payoneer_tokens_count_type3',
    btnName: 'token3',
  }
 ]

 const Info = ({ cacheInfoData, setCacheInfoData, setCacheInfoLoadingsData, cacheInfoFileLoadings }) => {
  const [infoData, setInfoData] = useState(cacheInfoData);
  const [loading, toggleLoading] = useState(false);
  const [isLoadingCsvFile, toggleLoadingCsvFile] = useState(cacheInfoFileLoadings || {
    token: false,
    token2: false,
    token3: false,
  });
  const fileInputRef = useRef(null);
  const infoRef = useRef({
    nowUploading: null,
  });

  const dispatch = useDispatch();

  const addModal = (component) => {
    dispatch(mainHubActions.pushModalComponent(component));
  };

  const onUploadTrigger = (e) => {
    if (!fileInputRef.current) return;
    
    const { dataset } = e.target;

    if (isLoadingCsvFile[dataset.event]) return;
    
    infoRef.current.nowUploading = dataset.event

    fileInputRef.current.click();
  }

  const onUploadHandler = (e) => {
    if (isLoadingCsvFile[infoRef.current.nowUploading]) return;
  
    const { target: { files } } = e;
    const { size } = files[0];

    const sizeInMB = (size / (1024 * 1024)).toFixed(2);

    if (sizeInMB > 1) {
      infoRef.current.nowUploading = null;
      return;
    }
  
    const currentUploading = infoRef.current.nowUploading;
    const formData = new FormData();
    formData.append('file', files[0]);
    formData.append('token_type', currentUploading);
    toggleLoadingCsvFile((__prevValue) => {
      const newData = {
        ...__prevValue,
        [currentUploading]: true,
      };
      setCacheInfoLoadingsData(newData);

      return newData;
    });

    const { systemSettings } = apiUrls;

    fileInputRef.current.value = null;

    authFetchFormData(`${systemSettings}/upload_payoneers`, {
      'method': 'POST',
      body: formData
    })
      .then((response) => {
        const { payoneer_tokens_loaded = '0', payoneer_tokens_not_loaded = '0' } = response || {};
        addModal(<modals.ModalSuccess classes={{ text: styles.settings_page_info_success_text }} message={`Processing finished. Results: ${payoneer_tokens_loaded} tokens were successfully added, ${payoneer_tokens_not_loaded} not added due to errors`} />)
      })
      .catch((response) => {
        const { instance } = response;
        if (instance) {
          instance.text()
            .then((errorText = '') => {
              const { error_message: errorMessage = 'error' } = JSON.parse(errorText) || {};
              addModal(<modals.WarningModal classes={{ text: styles.settings_page_info_success_text }} message={errorMessage} />)
            })
        }
      })
      .finally(() => {
        toggleLoadingCsvFile((__prevValue) => {
          const newData = {
            ...__prevValue,
            [currentUploading]: false,
          };
          setCacheInfoLoadingsData(newData);
  
          return newData;
        });
      });
  }

  useEffect(() => {
    if (cacheInfoData) return;

    const { systemSettings } = apiUrls;
    toggleLoading(true);
    authFetch(`${systemSettings}/info`)
      .then((__InfoData) => {
        setInfoData(__InfoData);
        setCacheInfoData(__InfoData);
      })
      .catch(err => console.error(err))
      .finally(() => {
        toggleLoading(false);
      });
  }, []);

  if (loading) return <div className={styles.settings_page_loader_wrapper}><controls.Loader /></div>

  if (!infoData) return null;

    return (
      <div className={classnames(styles.settings_page_main_content, [styles['settings_page_main_content--block']], [styles['settings_page_main_content--w-50']])}>
        <h2 className={styles.settings_page_main_content__header}>Available tokens:</h2>
          {INFO_MAPPER.map(({ title, field, btnName }) => {
            return (
              <div key={field} className={styles.settings_page_main_content_item}>
                <div className={classnames(styles.settings_page_main_content_item__flex, styles.settings_page_main_content_item__flex_sbtw, styles.settings_page_main_content_item__flex_lrP)}>
                  {title}
                  <div className={classnames(styles.settings_page_main_content_item__flex__mW_126, styles.settings_page_main_content_item__flex)}>
                    {infoData[field]}
                    <controls.Tooltip positionLeft content={".csv file less 1MB"} hideTooltip={isLoadingCsvFile[btnName]}>
                      <controls.Button dataEvent={btnName} onClick={onUploadTrigger} link className={styles.settings_page_payoneer_btn}>{isLoadingCsvFile[btnName] ? 'Processing…' : 'Upload'}</controls.Button>
                    </controls.Tooltip>
                  </div>
                </div>
              </div>
            )
          })}
        <input onChange={onUploadHandler} ref={fileInputRef} type="file" accept=".csv" className={styles.settings_page_file_input} />
      </div>
    )
 };

 export default Info;
