import React from 'react';
import ReactDOM from 'react-dom';
import '../node_modules/normalize.css/normalize.css';
import '../node_modules/react-quill/dist/quill.snow.css';

import '@verdaccio/crminfo/dist/style.css';
import '@verdaccio/crminfo/dist/reach-editable-style.css';
import '@verdaccio/crminfo/dist/react_quill.css';
import App from './App';
import getStore from './store/store';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
// import registerServiceWorker from './registerServiceWorker';
import { init, lrtracker } from '@verdaccio/crminfo';
import { urls, crminfo } from './config';

init(crminfo)
Sentry.init({ dsn: urls.apiSentry, integrations: [new BrowserTracing()], tracesSampleRate: 1.0, });
lrtracker.initTracker()


const store = getStore();

ReactDOM.render(<App store={store} />, document.getElementById('root'));
// registerServiceWorker();
