import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
  Table,
  discountsTableStorePack,
  settingsActions,
  utils,
  pluginConfigs,
  profileStorePack,
  mainHubActions,
  controls,
} from '@verdaccio/crminfo';

import CreatePromoButton from './../components/CreatePromoButton';

const PROMO_STATUSES = [
  ['Pending', 'Pending'],
  ['Active', 'Active'],
  ['Expired', 'Expired'],
  ['Canceled', 'Canceled'],
];

const AMOUNT_TYPES = {
  balance: 'Applied to balance',
  order: 'Applied to order',
};

const DISCOUNT_TYPES = [
  ['Addon', 'addon'],
  ['Fixed', 'fixed'],
  ['Percentage', 'percentage'],
];

const ADDON_TYPES = {
  plagreport: 'Plagreport',
  vip_support: 'VIP support',
  draft_needed: 'Draft',
  top_writer: 'Top writer',
  prioritize_my_task: 'Prioritize task',
};

class PromosPage extends Component {
  componentDidMount() {
    this.props.getSettings();
    const discountId = this.props.match.params.discountId;
    if (discountId) {
      this.props.pluginSetConfig(pluginConfigs.DiscountPlugin({ discountId, options: { closeUrl: '/promos' } }));
    }
  }

  componentDidUpdate(prevProps) {
    const { profile } = this.props;
    // if (profile && profile.roles && !profile.roles.includes('tickettool_manager')) {
    if (profile && profile.roles && !profile.roles.includes('manager')) {
      window.location = '/tickets';
    }
    const discountId = this.props.match.params.discountId;
    const prevDiscountId = prevProps.match.params.discountId;
    if (discountId && discountId !== prevDiscountId) {
      this.props.pluginSetConfig(pluginConfigs.DiscountPlugin({ discountId, options: { closeUrl: '/promos' } }));
    }
  }

  renderSite(id) {
    const siteArray = this.props.clientSites.find(site => site[1] === id);
    return siteArray && siteArray[0];
  }

  renderSites(sites) {
    const sitesString =  sites.map(site => this.renderSite(site)).join(', ');
    if (sitesString.length < 39) { // two string contain 38 symbols =)
      return sitesString;
    }
    return sitesString.slice(0, 35).concat('...');
  }

  renderAmount(discount) {
    if (discount.discount_type === 'addon') {
      return ADDON_TYPES[discount.value];
    }
    if (discount.discount_type === 'percentage') {
      return discount.value;
    }
    const floatValue = Number.parseFloat(discount.value.slice(1));
    return `${floatValue.toFixed(2)}`;
  }

  onClickRow(item) {
    const routeDiscountId = this.props.match.params.discountId;
    routeDiscountId === item.id
      ? this.props.pluginSetConfig(pluginConfigs.DiscountPlugin({ discountId: item.id, options: { closeUrl: '/promos' } }))
      : this.props.history.push('/promos/' + item.id);
  }

  render() {
    const { setFilter, clientSites, setSorting } = this.props;

    const tableConfig = {
      onClickRow: item => this.onClickRow(item),
      fields: [
        {
          name: 'Code',
          // formatter: item => item.code,
          formatter: item => <controls.CopyClipboard text={item.code}>{item.code}</controls.CopyClipboard>,
        },
        {
          name: 'Site',
          formatter: (item) => this.renderSites(item.sites),
          filterConfig: {
            value: this.props.filter.site,
            options: clientSites,
            onChange: value => setFilter('site', value),
            isMultiple: true,
          },
        },
        {
          name: 'Activation',
          formatter: (item) => utils.formatTime(item.activation, 'short').split(', ').join('\r\n'),
          sortingConfig: {
            name: 'activation',
            onChange: isAsc => setSorting('activation', isAsc),
          },
        },
        {
          name: 'Expiration',
          formatter: item => utils.formatTime(item.expiration, 'short').split(', ').join('\r\n') || 'Unlimited',
          sortingConfig: {
            name: 'expiration',
            onChange: isAsc => setSorting('expiration', isAsc),
          },
        },
        {
          name: 'Amount',
          formatter: item => this.renderAmount(item),
          filterConfig: {
            value: this.props.filter.discount_type,
            options: DISCOUNT_TYPES,
            onChange: value => setFilter('discount_type', value),
          },
          classnames: ['centered'],
        },
        {
          name: 'Applied to',
          formatter: item => AMOUNT_TYPES[item.applied_to],
        },
        {
          name: 'Min paid orders',
          formatter: item => item.paid_orders,
          classnames: ['centered'],
        },
        {
          name: 'Max paid orders',
          formatter: item => item.paid_orders_max > 0 ? item.paid_orders_max : 'No limit',
        },
        {
          name: 'Min order price',
          formatter: item => item.min_price,
          classnames: ['centered'],
        },
        {
          name: 'Reusability',
          formatter: item => `${item.reusability.charAt(0).toUpperCase()}${item.reusability.slice(1)}`,
        },
        {
          name: 'Orders',
          formatter: item => item.applied_orders || 0,
          width: 0.5,
          classnames: ['centered'],
        },
        {
          name: 'Total',
          formatter: item => item.total_amount || 0,
          width: 0.5,
          classnames: ['centered'],
        },
        {
          name: 'Status',
          formatter: (item) => {
            return (
              <controls.DiscountStatusDropdown
                status={item.status || ''}
                cancelBubble
                onSubmit={(value) => this.props.updateRow({ id: item.id, status: value, })}
                positionRight
              />
            );
          },
          filterConfig: {
            value: this.props.filter.status,
            options: PROMO_STATUSES,
            onChange: value => setFilter('status', value),
          },
          classnames: ['centered'],
          ddRight: true,
        },
      ]
    }
    return (
      <div>
        <CreatePromoButton />
        <Table {...this.props} tableConfig={tableConfig} sticky stickyThreshold="98px" mainLoader />
      </div>
    )
  }
}

const mapStateToProps = state => {
  const profileEntity = state[profileStorePack.name];
  const clientSites = [];
  const sites = state.settings.sites || [];
  sites.map(site => {
    if (!site.has_discount) return null;
    return clientSites.push([site.name, site._id])
  });

  return {
    loading: state.discounts.loading,
    loadingNext: state.discounts.loadingNext,
    list: state.discounts.data.list,
    total: state.discounts.data.total,
    page: state.discounts.data.page,
    error: state.discounts.error,
    filter: state.discounts.filter,
    sorting: state.discounts.sorting,
    profile: profileEntity && profileEntity.data,
    clientSites,
  }
};

const mapDispatchToProps = dispatch => ({
  load: () => dispatch(discountsTableStorePack.getTableAction()),
  loadNext: () => dispatch(discountsTableStorePack.getTableNextAction()),
  updateRow: discountRow => dispatch(discountsTableStorePack.updateTableAction(discountRow)),
  pluginSetConfig: config => dispatch(mainHubActions.addNewConfig(config)),
  getSettings: () => dispatch(settingsActions.getSettingIfNeeded()),
  setSorting: (field, isAsc) => {
    dispatch(discountsTableStorePack.setTableSortingAction(isAsc === null ? null : field, isAsc));
  },
  setFilter: (field, value) => {
    dispatch(discountsTableStorePack.setTableFilterAction(field, value));
  },
  onResetFilters: () => {
    dispatch(discountsTableStorePack.resetTableFilterAction());
    dispatch(discountsTableStorePack.getTableAction());
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PromosPage));
