import React, { Component } from 'react';
import { connect } from 'react-redux';
import stable from 'stable';

import moment from 'moment';

import {
  Table,
  campaignsStorePack,
  profileStorePack,
  mainHubActions,
  controls,
  utils,
} from '@verdaccio/crminfo';

import { SORTINGS } from '../../utils/campaignsSortings';

import CreateCampaignUpload from '../../components/CreateCampaignUpload';
import CopyCampaign from '../../components/CopyCampaign';

import { PAGES, hasAcces } from '../../utils/userAccess';

import classnames from 'classnames';
import gridStyles from './../../styles/grid.styl';
import styles from './../writer-page.styl';
import stylesBonus from './bonus-campaigns-page.styl';

const CAMPAIGN_STATUS = [
  ['Active', 'Active'],
  ['Inactive', 'Inactive'],
  ['Finished', 'Finished'],
  ['Deffer', 'Deffer'],
];

const STATUS_DELETE = 'Hidden';

const getPercent = (value, total) => !total ? 100 : Math.round(value * 100 / total);

class BonusCampaignsPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      selected: [],
      hasDeleted: [],
      isNotDisabled:  false,
    };

    this.onChangeSelected = this.onChangeSelected.bind(this);
    this.onChangeSelectedAll = this.onChangeSelectedAll.bind(this);
    this.onChangeStatusClick = this.onChangeStatusClick.bind(this);
    this.setSortingOrReset = this.setSortingOrReset.bind(this);
  }

  componentWillMount() {
    const { profile, history } = this.props;

    if (!hasAcces(PAGES.CAMPAIGNS, profile.roles)) {
      history.replace('/tickets');
    }
  }

  onChangeStatusClick(status) {
    const { selected } = this.state;
    const { setStatus } = this.props;
    if (!selected.length) {
      return;
    }
    if (status === STATUS_DELETE) {
      return this.props.addModal(
        <controls.ModalCondition
          title="Are you sure? This action can't be undone."
          onClickAgree={() => { this.props.closeModal(); setStatus(selected, status); this.setState({ selected: [] }); }}
          btnNameAgree={'Delete'}
          btnNameReject={'Cancel'}
          isWarning
        />,
      );
    } else {
      setStatus(selected, status)
      this.setState({ selected: [] });
    }
  }

  getFilteredList() {
    const { list, filter } = this.props;
    let filteredList = [...list].filter(item => item.status !== STATUS_DELETE);
    const { search, status } = filter;

    if (search) {
      const searchLower = search.toLowerCase();
      filteredList = filteredList.filter(campaign => campaign.name.toLowerCase().search(searchLower) > -1);
    }

    if (status && status.length && !search) {
      filteredList = filteredList.filter(campaign => status.indexOf(campaign.status) > -1);
    }

    return filteredList;
  }

  getSortedFilteredList() {
    let list = this.getFilteredList();
    const { field, isAsc } = this.props.sorting;
    const asc = isAsc ? 1 : -1;

    list = stable(list, (a, b) => (SORTINGS[field] && SORTINGS[field](a, b, asc)) || 0);

    return list;
  }

  onChangeSelectedAll(value, visibleIds) {
    const selected = value ? visibleIds : [];
    this.setState({ selected });
  }

  onChangeSelected(campaign) {
    let selected = this.state.selected.slice();
    let { hasDeleted, isNotDisabled } = this.state;

    if (selected.indexOf(campaign.id) === -1) {
      selected.push(campaign.id);
      if (campaign.status === 'Inactive') {
        hasDeleted = [...this.state.hasDeleted, campaign.id]
      }
    } else {
      selected = selected.filter(campaignId => campaignId !== campaign.id);
      hasDeleted = hasDeleted.filter(campaignId => campaignId !== campaign.id)
    }

    isNotDisabled = hasDeleted.length === selected.length
    this.setState({ selected, isNotDisabled, hasDeleted });
  }

  setSortingOrReset(fieldName, isAsc) {
    if (isAsc === null) {
      this.props.setSorting(null, true);
    } else {
      this.props.setSorting(fieldName, isAsc);
    }
  }

  getSubjectCategoryTooltip(subjectCategory) {
    return subjectCategory.reduce((prev, next, index) => {
      if (index > 0) prev += `\n${next}`;
      else prev += `${next}`;
      return prev;
    }, '');
  }

  render() {
    const tableConfig = {
      selectable: true,
      onChangeSelected: this.onChangeSelected,
      onChangeSelectedAll: this.onChangeSelectedAll,
      fields: [
        {
          name: 'Campaign status',
          formatter: item => {
            const style = item._loading ? { color: 'red' } : {};
            if (item.status === 'Active') {
              return <span style={style} className={styles.success_text}>Active</span>
            }
            if (item.status === 'Inactive') {
              return <span style={style} className={styles.disabled_text}>Inactive</span>
            }
            return <span style={style}>{item.status}</span>
          },
          filterConfig: {
            value: this.props.filter.status,
            options: CAMPAIGN_STATUS,
            isMultiple: true,
            onChange: value => this.props.setFilter('status', value),
          },
          width: .6,
        },
        {
          name: 'Campaign name',
          formatter: item => <span className={classnames({ [styles.disabled_text]: (item.status === 'Inactive') })}>{item.name}</span>,
        },
        {
          name: 'Reward',
          formatter: item => <span className={classnames({ [styles.disabled_text]: (item.status === 'Inactive') })}>{utils.formatAmount(item.reward)}</span>,
          classnames: ['centered'],
          sortingConfig: {
            name: 'reward',
            onChange: isAsc => this.setSortingOrReset('reward', isAsc),
          },
          width: 0.4,
        },
        {
          name: 'Start date',
          sortingConfig: {
            name: 'start_at',
            onChange: isAsc => this.setSortingOrReset('start_at', isAsc),
          },
          formatter: item => <span className={classnames({ [styles.disabled_text]: (item.status === 'Inactive') })}>{utils.formatTime(item.start_at, 'd')}</span>,
          width: 0.5,
        },
        {
          name: 'Deadline',
          sortingConfig: {
            name: 'deadline',
            onChange: isAsc => this.setSortingOrReset('deadline', isAsc),
          },
          formatter: item => <span className={classnames({ [styles.disabled_text]: (item.status === 'Inactive') })}>{moment(item.deadline).utc().format('ll')}</span>,
          width: 0.5,
        },
        {
          name: 'Date Created',
          sortingConfig: {
            name: 'created_at',
            onChange: isAsc => this.setSortingOrReset('created_at', isAsc),
          },
          formatter: item => <span className={classnames({ [styles.disabled_text]: (item.status === 'Inactive') })}>{utils.formatTime(item.created_at, 'ydt')}</span>,
          width: 0.7,
        },
        {
          name: 'Subject category',
          formatter: item => <controls.Tooltip positionDown className={stylesBonus.sub_category_tooltip} content={item.subject_category && item.subject_category.length > 0 ? this.getSubjectCategoryTooltip(item.subject_category) : null}>{item.subject_category && item.subject_category.length > 0 ? item.subject_category.length : 'all'}</controls.Tooltip>,
          sortingConfig: {
            name: 'subject_category',
            onChange: isAsc => this.setSortingOrReset('subject_category', isAsc),
          },
          width: 0.7,
          classnames: ['centered'],
        },
        {
          name: 'Total Writers',
          formatter: item => <span className={classnames({ [styles.disabled_text]: (item.status === 'Inactive') })}>{item.stat_total}</span>,
          sortingConfig: {
            name: 'stat_total',
            onChange: isAsc => this.setSortingOrReset('stat_total', isAsc),
          },
          width: 0.7,
          classnames: ['centered'],
        },
        {
          name: 'In progress',
          formatter: item => {
            const percent = getPercent(item.stat_progress, item.stat_total);
            return (
              <span className={classnames({ [styles.disabled_text]: (item.status === 'Inactive') })}>
                {item.stat_progress} {!!percent && `(${percent}%)`}
              </span>
            );
          },
          sortingConfig: {
            name: 'stat_progress',
            onChange: isAsc => this.setSortingOrReset('stat_progress', isAsc),
          },
          width: 0.6,
          classnames: ['centered'],
        },
        {
          name: 'Done',
          formatter: item => {
            const percent = getPercent(item.stat_done, item.stat_total);
            return (
              <span className={classnames({ [styles.disabled_text]: (item.status === 'Inactive') })}>
                {item.stat_done} {!!percent && `(${percent}%)`}
              </span>
            );
          },
          width: 1,
          ddRight: true,
          sortingConfig: {
            name: 'stat_done',
            onChange: isAsc => this.setSortingOrReset('stat_done', isAsc),
          },
          classnames: ['centered'],
          width: 0.5,
        },
        {
          name: 'Deleted',
          formatter: item => {
            const percent = getPercent(item.stat_deleted, item.stat_total);
            return (
              <span className={classnames({ [styles.disabled_text]: (item.status === 'Inactive') })}>
                {item.stat_deleted} {!!percent && `(${percent}%)`}
              </span>
            );
          },
          sortingConfig: {
            name: 'stat_deleted',
            onChange: isAsc => this.setSortingOrReset('stat_deleted', isAsc),
          },
          classnames: ['centered'],
          width: 0.6,
          ddRight: true,
        },
      ]
    }
    const { selected, isNotDisabled } = this.state;
    const notSelected = !selected.length;
    const selectedOne = selected.length === 1;
    let campaignId;
    if (selectedOne) {
      campaignId = selected[0];
    }
    const list = this.getSortedFilteredList();
    let params = { ...this.props, list };

    return (
      <div>
        <div className={classnames(gridStyles.row, gridStyles.space_between, styles.campaigns_filter)}>
          <div className={classnames(gridStyles.row, gridStyles.col_6, gridStyles.vertical)}>
            <div className={stylesBonus.btn_wrap}>
              <controls.Button
                disabled={notSelected}
                empty
                onClick={() => { this.onChangeStatusClick('Active') }}
              >
                Activate
              </controls.Button>
            </div>
            <div className={stylesBonus.btn_wrap}>
              <controls.Button
                disabled={notSelected}
                empty
                onClick={() => { this.onChangeStatusClick('Inactive') }}
              >
                Inactivate
              </controls.Button>
            </div>
            {!!selected.length &&
              <span className={stylesBonus.selected}>{selected.length} campaigns selected</span>
            }
          </div>
          <div className={classnames(gridStyles.row, gridStyles.vertical)}>
            <div className={stylesBonus.btn_wrap}>
              <controls.Button
                disabled={notSelected || !isNotDisabled}
                onClick={() => { this.onChangeStatusClick(STATUS_DELETE) }}
              >
                Delete
              </controls.Button>
            </div>
            {selectedOne && campaignId && <CopyCampaign campaignId={campaignId} />}
            <CreateCampaignUpload />
          </div>
        </div>

        <Table
          {...this.state}
          {...params}
          localFilters
          selected={selected}
          tableConfig={tableConfig}
          sticky
          stickyThreshold="98px"
          mainLoader
        />
      </div>
    )
  }
}

const mapStateToProps = state => {
  const campaignsState = state[campaignsStorePack.name];
  const profileEntity = state[profileStorePack.name];

  return {
    loading: campaignsState.loading,
    loadingNext: campaignsState.loadingNext,
    list: campaignsState.data.list.filter(item => item.status !== STATUS_DELETE),
    total: campaignsState.data.total,
    page: campaignsState.data.page,
    error: campaignsState.error,
    filter: campaignsState.filter,
    sorting: campaignsState.sorting,
    profile: profileEntity && profileEntity.data,
  }
};

const mapDispatchToProps = dispatch => ({
  load: () => dispatch(campaignsStorePack.getTableAction()),
  loadNext: () => dispatch(campaignsStorePack.getTableNextAction()),
  setSorting: (field, isAsc) => {
    dispatch(campaignsStorePack.setTableSortingAction(field, isAsc));
  },
  setFilter: (field, value) => {
    dispatch(campaignsStorePack.setTableFilterAction(field, value));
    dispatch(campaignsStorePack.getTableAction());
  },
  setStatus: (ids, status) => dispatch(campaignsStorePack.updateTableAction(ids.map(id => ({ _id: id, id, status })))),
  onResetFilters: () => {
    dispatch(campaignsStorePack.resetTableFilterAction());
    dispatch(campaignsStorePack.getTableAction());
  },
  addModal: component => dispatch(mainHubActions.pushModalComponent(component)),
  closeModal: () => dispatch(mainHubActions.popModalComponent()),
});

export default connect(mapStateToProps, mapDispatchToProps)(BonusCampaignsPage);
