import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import {
  campaignStorePack,
  campaignsStorePack,
  controls
} from '@verdaccio/crminfo';

import styles from './copy-campaign.styl';


class CopyCampaign extends Component {
  constructor(props) {
    super(props);

    this.onCopyCampaignClick = this.onCopyCampaignClick.bind(this);
  }

  /**
   * 
   * @param {String} value in ISO String
   */
  formatDate(value) {
    const momentObj = moment(value);
    return momentObj.format('YYYY-MM-DD');
  }

  onCopyCampaignClick() {
    const { createCopy, data } = this.props;

    const campaingData = {
      campaign_name: data.name,
      reward_name: data.reward_name,
      description: data.description,
      goal_units: data.goal_units,
      goal_value: data.goal_value,
      reward: data.reward,
      start_date: this.formatDate(data.start_at),
      deadline: this.formatDate(data.deadline),
      users: data.users,
    }

    createCopy(campaingData);
  }

  componentWillMount() {
    const { getCampaign } = this.props;
    getCampaign();
  }

  render() {
    const { loading } = this.props;
    return (
      <div className={styles.btn_wrap}>
        <controls.Button
          disable={loading}
          onClick={this.onCopyCampaignClick}
        >
          Copy
        </controls.Button>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const campaign = state.campaign[ownProps.campaignId]
  if (!campaign) {
    return {
      data: null,
      loading: true,
      error: null,
    }
  }
  return {
    data: campaign.data,
    loading: campaign.loading,
    error: campaign.error,
  }
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  getCampaign: () => dispatch(campaignStorePack.getEntityActionIfNeeded(ownProps.campaignId)),
  createCopy: campaigns => dispatch(campaignsStorePack.createTableAction(campaigns)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CopyCampaign);
