import React, { useState, useMemo } from 'react';
import moment from 'moment-timezone';

import { useDispatch } from 'react-redux';

import SendSMSModal from '../../components/SendSMSModal';

import authFetch from '../../utils/authFetch';

import styles from './index.styl';

import {
  Table,
  utils,
  controls,
  mainHubActions,
} from '@verdaccio/crminfo';

const BASE_URL = 'https://tt.dsh-agency.com/sms/';

const SMSSender = () => {
  const [isMsgLoading, toggleIsMsgLoading] = useState(true);
  const [isShowMore, setIsShowMore] = useState(true);
  const [smsData, setSmsData] = useState([]);

  const dispatch = useDispatch(); 

  const tableConfig = useMemo(() => ({
    fields: [
      {
        name: 'Date (UTC)',
        formatter: ({ date_created }) => utils.formatTime(moment.utc(date_created), 'short'),
        width: 0.5,
      },
      {
        name: 'Source phone',
        formatter: ({ from }) => from,
        width: 0.5,
      },
      {
        name: 'Target phone',
        formatter: ({ to }) => to,
        width: 0.5,
      },
      {
        name: 'Message text',
        formatter: ({ body }) => body,
        width: 3,
      },
    ]
  }));

  const setNewSMS = (data) => {
    setSmsData(__prevData => ([data, ...__prevData]))
  }

  const onSMSSend = () => {
    dispatch(mainHubActions.pushModalComponent(<SendSMSModal BASE_URL={BASE_URL} setNewSMS={setNewSMS} />))
  };

  const getMessages = (fromDate) => {
    toggleIsMsgLoading(true);
    authFetch(`${BASE_URL}messages?limit=25${fromDate ? `&date_sent_before=${encodeURIComponent(fromDate)}` : ''}`)
      .then((messages = []) => {
        if (messages.length === 0) {
          setIsShowMore(false);
          return;
        }
        setSmsData(__prevData => ([...__prevData, ...messages]))
      })
      .catch(err => {
        console.error(err);
      })
      .finally(() => {
        toggleIsMsgLoading(false);
      })
  }

  return (
    <div className={styles.sms_sender_page}>
      <div className={styles.sms_sender_page__btn_block}>
        <controls.Button onClick={onSMSSend}>Send SMS</controls.Button>
      </div>
      <Table loading={isMsgLoading} load={getMessages} list={smsData} tableConfig={tableConfig} sticky stickyThreshold="58px" infinity />
      {isShowMore && <div className={styles.sms_sender_page__show_more} onClick={() => getMessages((smsData.at(-1) || {}).date_created)}>Show more</div>}
    </div>
  );
};

export default SMSSender;
