import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
// import moment from 'moment-timezone';
import authFetch from './../../utils/authFetch';

import styles from './communications.styl';

import {
  Table,
  utils,
  apiUrls,
  controls,
  mainHubActions,
  profileStorePack,
  communicationsStorePack,
  communicationEntityStorePack,
} from '@verdaccio/crminfo';

const STATUSES = [
  ['Draft', 'draft'],
  ['Published', 'published'],
  ['Scheduled', 'scheduled'],
]

const TAGS = [
  ['Administrative', 'administrative'],
  ['Finance', 'finance'],
  ['Interface', 'interface'],
  ['Opportunities', 'opportunities'],
  ['Policies', 'policies'],
]

const STATUS_DELETED = 'deleted';

const Communications = (props) => {
  const { list } = props;
  const [selected, setSelected] = useState([]);
  const [stateList, setList] = useState(list);

  // const onClickRow = item => {
  //   // console.log('item click: ', item);
  // }

  useEffect(() => {
    if (!props.list) {
      props.load();
    }
  }, [])

  useEffect(() => {
    setList(list);
  }, [list])

  const onChangeSelected = (communication) => {
    let newSelected = [...selected]
    if (newSelected.indexOf(communication.id) === -1) {
      newSelected.push(communication.id);
    } else {
      newSelected = newSelected.filter(communicationId => communicationId !== communication.id);
    }
    setSelected(newSelected);
  }

  const onDelete = (id) => {
    const data = {
      action: 'delete',
      id,
    };
    props.updateRow(data);
  }

  const onDeleteRows = () => {
    const data = {
      action: 'delete',
      ids: selected,
    };
    authFetch(apiUrls.communications, {
      method: 'PATCH',
      body: JSON.stringify(data),
    }).then(() => {
      // setList(__prevState => [...__prevState].filter(item => !data.ids.includes(item.id)));
      props.load();
    })
      .catch(err => {
        console.error(err);
      });
  }

  const onPublish = (id) => {
    const data = {
      action: 'publish',
      publish_at: Date.now(),
      id,
    };
    props.updateRow(data);
  }

  const onEdit = (id) => {
    props.addModal(<controls.UpdateConstructorForm onCreate={onCreate} onUpdate={onUpdate} communicationId={id} />)
  }

  const onDuplicate = (id) => {
    const data = {
      action: 'duplicate',
      id,
    };
    props.updateCommunication(data).then(() => props.load());
  }

  const onCreate = (data) => {
    const { create, closeModal } = props;
    create(data);
    closeModal();
  }

  const onUpdate = (data) => {
    const { updateRow, closeModal } = props;
    updateRow(data)
    closeModal();
  }

  const tableConfig = {
    selectable: true,
    onChangeSelected: onChangeSelected,
    fields: [
      {
        name: 'Name',
        formatter: item => item.name,
      },
      {
        name: 'Audience/Seen',
        formatter: item => `${item.audience_cnt} / ${item.seen_cnt}`,
      },
      {
        name: 'Date created',
        formatter: item => utils.formatTime(item.created_at, 'dt'),
        sortingConfig: {
          name: 'created_at',
          onChange: isAsc => props.setSorting('created_at', isAsc),
        },
      },
      {
        name: 'Publish date',
        formatter: item => utils.formatTime(item.publish_at, 'dt'),
        sortingConfig: {
          name: 'publish_at',
          onChange: isAsc => props.setSorting('publish_at', isAsc),
        },
      },
      {
        name: 'Tags',
        formatter: item => item.tags.length > 0 && `#${item.tags.join(', #')}`,
        filterConfig: {
          value: props.filter.tags,
          isMultiple: true,
          options: TAGS,
          onChange: value => props.setFilter('tags', value),
        },
      },
      {
        name: 'Status',
        formatter: item => item.status,
        filterConfig: {
          value: props.filter.status,
          isMultiple: true,
          options: STATUSES,
          onChange: value => props.setFilter('status', value),
        },
      },
      {
        name: 'Actions',
        formatter: (item) => {
          const isDisabledPublish = (!item.title || !item.name || !item.text_filled || item.tags.length < 1) || item.status === 'published';

          return (
            <controls.CommunicationActionDropdown
              id={item.id}
              onEdit={onEdit}
              onDelete={onDelete}
              onPublish={onPublish}
              onDuplicate={onDuplicate}
              isDisabledPublish={isDisabledPublish}
            />
          );
        },
      },
    ],
  };

  const notSelected = !selected.length;

  // console.log('stateList: ', stateList);

  return (
    <div>
      <div className={styles.btn_wrap}>
        <controls.Button
          disabled={notSelected}
          onClick={onDeleteRows}
        >
          Delete
        </controls.Button>
        <controls.Button
          primary
          onClick={() => { props.addModal(<controls.UpdateConstructorForm onCreate={onCreate} onUpdate={onUpdate} />) }}
        >
          Create new update
        </controls.Button>
      </div>
      <Table {...props} list={stateList} selected={selected} tableConfig={tableConfig} sticky stickyThreshold="58px" mainLoader infinity />
    </div>
  )
}

const mapStateToProps = state => {
  const profileEntity = state[profileStorePack.name];
  let list = state.communications.data.list || [];
  list = list.filter(item => item.status !== STATUS_DELETED);

  return {
    profile: profileEntity && profileEntity.data,
    list,
    loading: state.communications.loading,
    loadingNext: state.communications.loadingNext,
    total: state.communications.data.total,
    page: state.communications.data.page,
    countList: state.communications.data.countList,
    error: state.communications.error,
    sorting: state.communications.sorting,
    filter: state.communications.filter,
  };
};

const mapDispatchToProps = dispatch => ({
  load: () => dispatch(communicationsStorePack.getTableAction()),
  loadNext: () => dispatch(communicationsStorePack.getTableNextAction()),
  updateRow: communicationRow => dispatch(communicationsStorePack.updateTableAction(communicationRow)),
  updateCommunication: data => dispatch(communicationEntityStorePack.updateEntityAction(data)),
  // getAuthSettings: () => {
  //   dispatch(settingsAuthEntityStorePack.getEntityAction());
  // },
  setFilter: (field, value) => {
    dispatch(communicationsStorePack.setTableFilterAction(field, value));
  },
  setSorting: (field, isAsc) => {
    dispatch(communicationsStorePack.setTableSortingAction(isAsc === null ? null : field, isAsc));
  },
  create: data => dispatch(communicationsStorePack.createTableAction(data)),
  onResetFilters: () => {
    dispatch(communicationsStorePack.resetTableFilterAction());
    dispatch(communicationsStorePack.getTableAction());
  },
  addModal: component => dispatch(mainHubActions.pushModalComponent(component)),
  closeModal: () => dispatch(mainHubActions.popModalComponent()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Communications);
