import React from 'react';
import { connect } from 'react-redux';

import {
  controls,
  orderTags,
  ordersTableStorePack,
} from '@verdaccio/crminfo';

const TAG_OPTIONS = orderTags.map(t => [t, t]);

const OrderTagFilterDropdown = (props) => {
  const { filterTags, setFilter, loading } = props;
  const filterTagsSelected = filterTags.length ? filterTags.join(', ') : 'All';

  return (
    <controls.DropdownChildren text={filterTagsSelected} loading={loading}>
      <controls.FilterPanelType3
        filterValue={filterTags || ''}
        fieldId='custom_tags'
        onChange={setFilter}
        value={filterTags}
        options={TAG_OPTIONS}
        name='custom_tags'
        width='widthMd'
        isMultiple
        withCustom
      />
    </controls.DropdownChildren>
  );
};

const mapStateToProps = (state) => {
  return {
    filterTags: state.orders.filter.custom_tags || [],
    loading: state.orders.loading || false,
  };
};

const mapDispatchToProps = dispatch => ({
  setFilter: (value) => {
    dispatch(ordersTableStorePack.setTableFilterAction('custom_tags', value));
    dispatch(ordersTableStorePack.getTableAction());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderTagFilterDropdown);
