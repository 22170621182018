import React, { Component } from 'react';
import { connect } from 'react-redux';
import debounce from 'debounce';
import moment from 'moment-timezone';
import classnames from 'classnames';
import clipboardCopy from 'clipboard-copy';
import { withRouter } from 'react-router-dom';

import {
  Table,
  utils,
  pluginConfigs,
  mainHubActions,
  settingsActions,
  writersTableStorePack,
  writerEntityStorePack,
  profileStorePack,
  controls,
} from '@verdaccio/crminfo';

import Icon from './../components/Icon';
import WriterTagFilterDropdown from './../components/WriterTagFilterDropdown';

import gridStyles from './../styles/grid.styl';
import styles from './writer-page.styl';

const JOBS = [
  ['Writers', 'writing'],
  ['Graders', 'grading'],
];

const WRITER_TYPE = [
  ['DE only', 'de'],
  ['EN only', 'en'],
];

const WRITER_STATUS2 = [
  ['Active', 'active'],
  ['Dormant', 'dormant'],
  ['Disabled', 'disabled'],
  ['Frozen', 'frozen'],
];

// const LOCAL_TIMES = [
//   ['Only day time', 'day'],
//   ['Only night time', 'night'],
// ];

const PROFILE_STATUS = [
  ['Freelance', 'freelancers'],
  ['Fulltime', 'full_timers'],
  ['Group', 'kenian'],
  ['Stem', 'stem'],
  ['US', 'US'],
  ['Promo', 'Promo'],
];

// const GROUPS = [
//   ['Newbie', 'newbie'],
//   ['Beginner', 'beginner'],
//   ['Group F', 'F'],
//   ['Group D', 'D'],
//   ['Group C', 'C'],
//   ['Group B', 'B'],
//   ['Group A', 'A'],
//   ['Unlimited', 'unlimited'],
// ];

const GROUPS = [
  ['АPlus-(pro)', 'A+-pro', 1],
  ['АPlus-(standard)', 'A+-standard', 2],
  ['АPlus-(outsider)', 'A+-outsider', 3],
  ['A (pro)', 'A-pro', 4],
  ['A (standard)', 'A-standard', 5],
  ['A (outsider)', 'A-outsider', 6],
  ['B (pro)', 'B-pro', 7],
  ['B (standard)', 'B-standard', 8],
  ['B (outsider)', 'B-outsider', 9],
  ['C (standard)', 'C-standard', 10],
  ['C (outsider)', 'C-outsider', 11],
  ['D (standard)', 'D-standard', 12],
  ['D (outsider)', 'D-outsider', 13],
  ['F', 'F', 14],
  ['Newbie (standard)', 'newbie-standard', 15],
  ['Newbie (outsider)', 'newbie-outsider', 16],
  ['Beginner (standard)', 'beginner-standard', 17],
  ['Beginner (outsider)', 'beginner-outsider', 18],
  ['Unlimited', 'unlimited', 19],
];

const AVAILABILITIES = [
  ['Online', 'is_writer_online'],
  ['Available', 'available_for_now'],
];

const DETENTIONS = [
  ['Yes', 'put'],
  ['No', 'not'],
]

const PUBLIC_PROFILE_STATUSES = [
  ['Not set', 'not_set'],
  ['Waiting approval', 'waiting_approval'],
  ['UPD waiting approval', 'upd_waiting_approval'],
  ['Approved', 'profile_approved'],
]

const RS_FILTER = [
  ['Recommended', 'Recommended'],
  ['Suggested', 'Suggested'],
  ['None', 'None'],
];

const CREDIBLE = [
  ['Yes', 'yes'],
  ['No', 'no'],
];

const getFilterKey = (values, key) => {
  let result = '';
  values.forEach((value) => {
    if (value[1] === key) result = value[0];
  });
  return result;
}

const getFilterKeys = (values, keys) => {
  let result = [];
  values.forEach((value) => {
    if (keys.includes(value[1])) {
      result.push(value[0]);
    }
  });
  return result.join(', ');
}

class WritersPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      emailCopied: false,
      emailCopiedWriterId: null,
      phoneCopied: false,
      phoneCopiedWriterId: null,
      decryptedWriterIds: [],
    }

    this.copyEmailOnReceiveData = false;
    this.copyPhoneOnReceiveData = false;
    this.receiveWriterId = null;

    this.debounceGetTableAction = debounce(this.debounceGetTableAction, 1000);
    this.setAvailablityFilter = this.setAvailablityFilter.bind(this);
    this.onClickSuggestOrder = this.onClickSuggestOrder.bind(this);
    this.onCopyEmail = this.onCopyEmail.bind(this);
    this.onCopyPhone = this.onCopyPhone.bind(this);
    this.onDecrypt = this.onDecrypt.bind(this);
    this.onStrictCopyEmail = this.onStrictCopyEmail.bind(this);
    this.onStrictCopyPhone = this.onStrictCopyPhone.bind(this);
  }

  debounceGetTableAction() {
    this.props.getTableAction();
  }

  componentDidMount() {
    this.props.getSettings();
    const writerId = this.props.match?.params?.writerId;
    if (writerId) {
      const isGrader = this.props.match.params.isGrader === 'grader';
      const config = isGrader ? pluginConfigs.GraderPlugin : pluginConfigs.WriterPlugin;
      this.props.pluginSetConfig(config({ writerId, options: { closeUrl: '/writers' } }));
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const writerId = this.props.match?.params?.writerId;
    const prevWriterId = prevProps.match?.params?.writerId;
    if (writerId !== prevWriterId && writerId) {
      const isGrader = this.props.match.params.isGrader === 'grader';
      const config = isGrader ? pluginConfigs.GraderPlugin : pluginConfigs.WriterPlugin;
      this.props.pluginSetConfig(config({ writerId, options: { closeUrl: '/writers' } }));
    }

    // when decode writer data copy his cred-s
    if (this.receiveWriterId && this.props.writers[this.receiveWriterId] && !this.props.writers[this.receiveWriterId].loading) {
      let writerData = this.props.writers[this.receiveWriterId].data;
      if (!this.state.decryptedWriterIds.includes(writerData.id)) {
        this.setState({ decryptedWriterIds: [...this.state.decryptedWriterIds, writerData.id] });
        this.receiveWriterId = null;
      }
      if (this.copyEmailOnReceiveData) {
        clipboardCopy(writerData.email);
        this.setState({
          emailCopied: true,
          emailCopiedWriterId: this.receiveWriterId,
        });
        this.copyEmailOnReceiveData = false;
        this.receiveWriterId = null;
      }
      if (this.copyPhoneOnReceiveData) {
        clipboardCopy(writerData.phone);
        this.setState({
          phoneCopied: true,
          phoneCopiedWriterId: this.receiveWriterId,
        });
        this.copyPhoneOnReceiveData = false;
        this.receiveWriterId = null;
      }
    }

    // for 'email copy' notification
    if (!prevState.emailCopied && this.state.emailCopied) {
      clearTimeout(this.emailCopiedTimeout);
      this.emailCopiedTimeout = setTimeout(() => this.setState({
        emailCopied: false,
        emailCopiedWriterId: null,
      }), 1500);
    }

    // for 'phone copy' notification
    if (!prevState.phoneCopied && this.state.phoneCopied) {
      clearTimeout(this.phoneCopiedTimeout);
      this.phoneCopiedTimeout = setTimeout(() => this.setState({
        phoneCopied: false,
        phoneCopiedWriterId: null,
      }), 1500);
    }
  }

  isChrome() {
    return navigator.userAgent.search(/Chrome/) > 0;
  }

  onDecrypt(e, id) {
    e.stopPropagation();
    this.props.getWriterDecryptAction(id);
    this.receiveWriterId = id;
    // decryptedWriterId: null,
  }

  onCopyEmail(e, id) {
    e.stopPropagation();
    this.props.getWriterDecryptAction(id);
    this.copyEmailOnReceiveData = true;
    this.receiveWriterId = id;
  }

  onStrictCopyEmail(e, id) {
    e.stopPropagation();
    let writerData = this.props.writers[id].data;
    clipboardCopy(writerData.email)
    this.setState({
      emailCopied: true,
      emailCopiedWriterId: id,
    });
  }
  //
  onCopyPhone(e, id) {
    e.stopPropagation();
    this.props.getWriterDecryptAction(id);
    this.copyPhoneOnReceiveData = true;
    this.receiveWriterId = id;
  }

  onStrictCopyPhone(e, id) {
    e.stopPropagation();
    let writerData = this.props.writers[id].data;
    clipboardCopy(writerData.phone);
    this.setState({
      phoneCopied: true,
      phoneCopiedWriterId: id,
    });
  }
  //
  onClickRow(item) {
    const routeWriterId = this.props.match?.params?.writerId;
    const routeIsGrader = this.props.match?.params?.isGrader === 'grader';

    if (routeWriterId === item.id) {
      const config = routeIsGrader ? pluginConfigs.GraderPlugin : pluginConfigs.WriterPlugin;
      this.props.pluginSetConfig(config({ writerId: item.id, options: { closeUrl: '/writers' } }));
      return;
    }

    const routePath = item.is_grader ? `/writers/${item.id}/grader` : `/writers/${item.id}`;
    this.props.history.push(routePath);
  }

  onClickSuggestOrder(e, item) {
    e.stopPropagation();
    this.props.pluginSetConfig(
      pluginConfigs.OrderAllocationDashboardPlugin({
        type: 'suggest',
        writerId: item.id,
      })
    )
  }

  setAvailablityFilter(value) {
    AVAILABILITIES.forEach(a => {
      this.props.setFilter(a[1], value === a[1] || null);
    });
    this.props.load();
  }

  getAvailabilityValue() {
    const { is_writer_online, available_for_now } = this.props.filter;
    if (is_writer_online) {
      return 'is_writer_online';
    }
    if (available_for_now) {
      return 'available_for_now';
    }
    return '';
  }

  renderOiwStats(writer) {
    const data = writer.metric_data;
    const ddl24 = data.current_orders_count_24 || 0;
    const ddl48 = data.current_orders_count_48 || 0;
    const ddl72 = data.current_orders_count_great || 0;
    const sum = ddl24 + ddl48 + ddl72;
    const ddl24pnt = sum ? Math.round((ddl24 * 100) / sum) : 0;
    const ddl48pnt = sum ? Math.round((ddl48 * 100) / sum) : 0;
    const ddl72pnt = sum ? Math.round((ddl72 * 100) / sum) : 0;

    return (
      <div>
        <div>DDL 24hrs: {ddl24} [{ddl24pnt}%]</div>
        <div>DDL 48hrs: {ddl48} [{ddl48pnt}%]</div>
        <div>DDL 72hrs: {ddl72} [{ddl72pnt}%]</div>
      </div>
    );
  }

  render() {
    const { orderSubject, setFilter, callStatuses, countryList, setSorting, callReasons, loading } = this.props;

    const isChrome = this.isChrome();

    const tableConfig = {
      onClickRow: item => this.onClickRow(item),
      classnames: ['table_fix'],
      fields: [
        {
          name: 'Date applied',
          formatter: item => utils.formatTime(item.created_at, 'd'),
          dateRangeFilterConfig: {
            valueFrom: this.props.filter.gte__date__created_at,
            valueTo: this.props.filter.lte__date__created_at,
            onChange: (valueFrom, valueTo) => {
              this.props.setFilter('gte__date__created_at', valueFrom)
              this.props.setFilter('lte__date__created_at', valueTo)
            }
          },
          sortingConfig: {
            name: 'created_at',
            onChange: isAsc => setSorting('created_at', isAsc),
          },
          width: 0.8,
        },
        {
          name: 'Freelancer',
          formatter: item => {
            const href = item.is_grader ? `/writers/${item.id}/grader` : `/writers/${item.id}`;
            return (
              <span className={classnames(styles.group_name, styles[item.qc_group.substring(0, 1)])}>
                {item.name}
                <controls.NewWindowButton inTable className="icon_new_window" href={href} />
              </span>
            )
          },
          width: 1.2,
          filterConfig: {
            value: this.props.filter.group,
            options: GROUPS,
            onChange: value => setFilter('group', value),
            isMultiple: true,
          },
        },
        {
          name: 'Phone number',
          formatter: item => (
            <span className={styles.writer_phone}>
              {item.phone}
            </span>
          ),
          width: 0.9,
        },
        {
          name: 'Copy',
          formatter: item => (
            <div>
              {(isChrome || this.state.decryptedWriterIds.includes(item.id)) &&
                <div>
                  <div className={styles.copy_credential}>
                    {this.state.emailCopied && this.state.emailCopiedWriterId === item.id && <span className={styles.copied_mark}>Copied</span>}
                    <controls.Button
                      emptyDefault
                      disabled={this.copyEmailOnReceiveData}
                      title="Copy e-mail"
                      onClick={(e) => { isChrome ? this.onCopyEmail(e, item.id) : this.onStrictCopyEmail(e, item.id) }}
                    >
                      <i className={styles.icon_email} />
                    </controls.Button>
                  </div>
                  <div className={styles.copy_credential}>
                    {this.state.phoneCopied && this.state.phoneCopiedWriterId === item.id && <span className={styles.copied_mark}>Copied</span>}
                    <controls.Button
                      emptyDefault
                      disabled={this.copyPhoneOnReceiveData}
                      title="Copy phone number"
                      onClick={(e) => { isChrome ? this.onCopyPhone(e, item.id) : this.onStrictCopyPhone(e, item.id) }}
                    >
                      <i className={styles.icon_phone} />
                    </controls.Button>
                  </div>
                </div>
              }
              {(!isChrome && !this.state.decryptedWriterIds.includes(item.id)) &&
                <div className={styles.copy_credential}>
                  <controls.Button
                    emptyDefault
                    disabled={this.copyPhoneOnReceiveData}
                    title="Decrypt writer data before copy"
                    onClick={(e) => this.onDecrypt(e, item.id)}
                  >
                    <i className={styles.icon_unlock} />
                  </controls.Button>
                </div>
              }
            </div>
          ),
          width: 0.55,
          classnames: ['centered'],
        },
        {
          name: 'Country Phone',
          formatter: item => item.country_phone,
          filterConfig: {
            value: this.props.filter.country_phone,
            options: countryList,
            onChange: value => setFilter('country_phone', value),
            isMultiple: true,
            unsortFields: [['NA', 'NA']]
          },
          width: 0.9,
        },
        // {
        //   name: 'Local time',
        //   formatter: (item) => {
        //     const local = moment(item.local_time);
        //     local.subtract(moment().utcOffset(), 'm');
        //     return (
        //       <div>
        //         {utils.formatTime(local, 'tt')}
        //         {utils.isBetween(local, '09:00:00 am', '09:00:00 pm')
        //           ? <Icon className="svg-icon" iconName="sun" />
        //           : <Icon className="svg-icon" iconName="moon" />}
        //       </div>
        //     );
        //   },
        //   filterConfig: {
        //     value: this.props.filter['local_time'],
        //     options: LOCAL_TIMES,
        //     onChange: value => setFilter('local_time', value),
        //   },
        //   width: .8,
        // },
        {
          name: 'Last call',
          formatter: item => utils.formatTime(item.call_last, 'd'),
          width: 0.8,
          dateRangeFilterConfig: {
            valueFrom: this.props.filter['gte__date__profile.call_last'],
            valueTo: this.props.filter['lte__date__profile.call_last'],
            onChange: (valueFrom, valueTo) => {
              this.props.setFilter('gte__date__profile.call_last', valueFrom)
              this.props.setFilter('lte__date__profile.call_last', valueTo)
            }
          },
          sortingConfig: {
            name: 'profile.call_last',
            onChange: isAsc => setSorting('profile.call_last', isAsc),
          },
        },
        {
          name: 'CR',
          formatter: item => item.call_reason,
          width: 0.38,
          filterConfig: {
            value: this.props.filter['eq__profile.call_reason'],
            options: callReasons,
            onChange: value => setFilter('eq__profile.call_reason', value),
            isMultiple: true,
          },
          classnames: ['centered'],
        },
        {
          name: 'CS',
          formatter: item => item.call_status,
          width: 0.38,
          filterConfig: {
            value: this.props.filter['eq__profile.call_status'],
            options: callStatuses,
            onChange: value => setFilter('eq__profile.call_status', value),
            isMultiple: true,
          },
          classnames: ['centered'],
        },
        {
          name: 'Last seen',
          formatter: item => {
            if (item.online) {
              if (item.available_for) {
                const millisecondsDiff = moment(item.available_for).diff(moment());
                if (millisecondsDiff > 0) {
                  return (
                    <span>
                      Available for: {utils.millisecondsToDurationString(millisecondsDiff, true)}
                    </span>
                  );
                }
              }
              return (<span className={styles.online}>Online now</span>);
            }
            return utils.formatTime(item.last_login, 'ydt');
          },
          // not working now
          dateRangeFilterConfig: {
            valueFrom: this.props.filter['gte__date__profile.lastlogin'],
            valueTo: this.props.filter['lte__date__profile.lastlogin'],
            onChange: (valueFrom, valueTo) => {
              this.props.setFilter('gte__date__profile.lastlogin', valueFrom)
              this.props.setFilter('lte__date__profile.lastlogin', valueTo)
            }
          },
          sortingConfig: {
            name: 'last_login',
            onChange: isAsc => setSorting('last_login', isAsc),
          },
          width: 1.1
        },
        {
          name: 'Last order',
          formatter: item => utils.formatTime(item.metric_data.last_writer_accept_at, 'ydt'),
          dateRangeFilterConfig: {
            valueFrom: this.props.filter['gte__date__profile.last_writer_accept_at'],
            valueTo: this.props.filter['lte__date__profile.last_writer_accept_at'],
            onChange: (valueFrom, valueTo) => {
              this.props.setFilter('gte__date__profile.last_writer_accept_at', valueFrom)
              this.props.setFilter('lte__date__profile.last_writer_accept_at', valueTo)
            }
          },
          sortingConfig: {
            name: 'profile.last_writer_accept_at',
            onChange: isAsc => setSorting('profile.last_writer_accept_at', isAsc),
          },
          width: 1.1
        },
        {
          name: 'OLM',
          formatter: item => item.metric_data.last_completed_orders_count_month,
          width: .5,
          sortingConfig: {
            name: 'profile.last_completed_orders_count_month',
            onChange: isAsc => setSorting('profile.last_completed_orders_count_month', isAsc),
          },
          classnames: ['centered'],
        },
        {
          name: 'OTM',
          formatter: item => item.metric_data.completed_orders_count_month,
          width: .5,
          sortingConfig: {
            name: 'profile.completed_orders_count_month',
            onChange: isAsc => setSorting('profile.completed_orders_count_month', isAsc),
          },
          classnames: ['centered'],
        },
        {
          name: 'OIW',
          formatter: item => {
            const value = item.metric_data.current_orders_count;
            if (!value) {
              return null;
            }
            return (
              <controls.Tooltip content={this.renderOiwStats(item)}>
                {value}
              </controls.Tooltip>
            );

          },
          width: .5,
          sortingConfig: {
            name: 'profile.current_orders_count',
            onChange: isAsc => setSorting('profile.current_orders_count', isAsc),
          },
          classnames: ['centered'],
        },
        // {
        //   name: 'RO',
        //   formatter: item => item.metric_data.current_orders_count_recommended,
        //   width: .5,
        //   sortingConfig: {
        //     name: 'profile.current_orders_count_recommended',
        //     onChange: isAsc => setSorting('profile.current_orders_count_recommended', isAsc),
        //   },
        //   ddRight: true,
        //   classnames: ['centered'],
        // },
        {
          name: 'R/S',
          formatter: item => `${item.metric_data.current_orders_count_recommended || 0}/${item.metric_data.current_orders_count_suggested || 0}`,
          width: .5,
          classnames: ['no_padding'],
          filterConfig: {
            value: this.props.filter['writer_has_suggest'],
            isMultiple: true,
            options: RS_FILTER,
            onChange: value => setFilter('writer_has_suggest', value),
          },
          ddRight: true,
          classnames: ['centered'],
        },
        {
          name: 'TCO',
          formatter: item => {
            const value = item.metric_data.completed_orders_count;
            if (!value) {
              return null;
            }
            const tooltipValue = `${item.metric_data.completed_orders_size_pages || 0} pages`;
            return (
              <controls.Tooltip content={tooltipValue}>
                {value}
              </controls.Tooltip>
            );

          },
          width: .65,
          numberRangeFilterConfig: {
            valueFrom: this.props.filter['gte__int__profile.completed_orders_count'],
            valueTo: this.props.filter['lte__int__profile.completed_orders_count'],
            onChange: (valueFrom, valueTo) => {
              this.props.setFilter('gte__int__profile.completed_orders_count', valueFrom)
              this.props.setFilter('lte__int__profile.completed_orders_count', valueTo)
            }
          },
          sortingConfig: {
            name: 'profile.completed_orders_count',
            onChange: isAsc => setSorting('profile.completed_orders_count', isAsc),
          },
          classnames: ['centered'],
          ddRight: true,
        },
        {
          name: 'CM',
          formatter: item => {
            if (!item.operator_comment) {
              return null;
            }
            return (
              <div style={{ textAlign: 'right' }}>
                <controls.Tooltip content={item.operator_comment} positionRight widthMedium>
                  <Icon className="svg-icon" iconName="comment" />
                </controls.Tooltip>
              </div>
            );
          },
          width: 0.3,
          classnames: ['centered'],
        },
        {
          name: 'Actions',
          formatter: item => {
            return (
              <div style={{ textAlign: 'right' }}>
                <controls.Button
                  title="Suggest Order"
                  className={styles.btn_action}
                  onClick={(e) => this.onClickSuggestOrder(e, item)}
                >
                  +O
                </controls.Button>
              </div>
            );
          },
          width: 0.7,
          classnames: ['centered'],
        },
      ]
    }

    return (
      <div>
        <div className={classnames(gridStyles.row, styles.filter)}>
          <div>
            <h4 className={styles.label_filter}>Role:</h4>
            <controls.DropdownChildren
              text={this.props.filter.job ? getFilterKey(JOBS, this.props.filter.job) : 'All'}
              loading={loading}
            >
              <controls.FilterPanelType3
                filterValue={this.props.filter.job || ''}
                fieldId='job'
                onChange={v => this.props.setFilterUpdate('job', v)}
                value={this.props.filter.job || ''}
                options={JOBS}
                name='job'
              />
            </controls.DropdownChildren>
          </div>

          <div>
            <h4 className={styles.label_filter}>Type:</h4>
            <controls.DropdownChildren
              text={this.props.filter.writer_type ? getFilterKey(WRITER_TYPE, this.props.filter.writer_type) : 'All'}
              loading={loading}
            >
              <controls.FilterPanelType3
                filterValue={this.props.filter.writer_type || ''}
                fieldId='writer_type'
                onChange={v => this.props.setFilterUpdate('writer_type', v)}
                value={this.props.filter.writer_type || ''}
                options={WRITER_TYPE}
                name='writer_type'
              />
            </controls.DropdownChildren>
          </div>

          <div>
            <h4 className={styles.label_filter}>Account status:</h4>
            <controls.DropdownChildren
              text={this.props.filter.writer_status2 ? getFilterKey(WRITER_STATUS2, this.props.filter.writer_status2) : 'All'}
              loading={loading}
            >
              <controls.FilterPanelType3
                filterValue={this.props.filter.writer_status2 || ''}
                fieldId='writer_status2'
                onChange={v => this.props.setFilterUpdate('writer_status2', v)}
                value={this.props.filter.writer_status2 || ''}
                options={WRITER_STATUS2}
                name='writer_status2'
              />
            </controls.DropdownChildren>
          </div>

          <div>
            <h4 className={styles.label_filter}>Profile status:</h4>
            <controls.DropdownChildren
              text={this.props.filter.view_status && this.props.filter.view_status.length > 0 ?
                getFilterKeys(PROFILE_STATUS, this.props.filter.view_status) :
                'All'}
              loading={loading}
            >
              <controls.FilterPanelType3
                filterValue={this.props.filter.view_status || ''}
                fieldId='view_status'
                onChange={v => this.props.setFilterUpdate('view_status', v)}
                value={this.props.filter.view_status || ''}
                options={PROFILE_STATUS}
                name='view_status'
                width='widthMd'
                isMultiple
              />
            </controls.DropdownChildren>
          </div>

          <div>
            <h4 className={styles.label_filter}>Subjects:</h4>
            <controls.DropdownChildren
              // text={this.props.filter.bysubject ? getFilterKey(orderSubject, this.props.filter.bysubject) : 'All'}
              text={this.props.filter.bysubject && this.props.filter.bysubject.length > 0 ?
                getFilterKeys(orderSubject, this.props.filter.bysubject) :
                'All'}
              loading={loading}
            >
              <controls.FilterPanelType3
                isMultiple
                filterValue={this.props.filter.bysubject || ''}
                fieldId='bysubject'
                onChange={v => this.props.setFilterUpdate('bysubject', v)}
                value={this.props.filter.bysubject || ''}
                options={orderSubject}
                name='bysubject'
                width='widthMd'
              />
            </controls.DropdownChildren>
          </div>

          <div>
            <h4 className={styles.label_filter}>Availability:</h4>
            <controls.DropdownChildren
              text={this.getAvailabilityValue() ? getFilterKey(AVAILABILITIES, this.getAvailabilityValue()) : 'All'}
              loading={loading}
            >
              <controls.FilterPanelType3
                filterValue={this.getAvailabilityValue()}
                fieldId='availability'
                onChange={(v) => this.setAvailablityFilter(v)}
                value={this.getAvailabilityValue()}
                options={AVAILABILITIES}
                name='availability'
              />
            </controls.DropdownChildren>
          </div>
          <div>
            <h4 className={styles.label_filter}>Detention:</h4>
            <controls.DropdownChildren
              text={this.props.filter.writer_detention ? getFilterKey(DETENTIONS, this.props.filter.writer_detention) : 'All'}
              loading={loading}
            >
              <controls.FilterPanelType3
                filterValue={this.props.filter.writer_detention || ''}
                fieldId='writer_detention'
                onChange={v => this.props.setFilterUpdate('writer_detention', v)}
                value={this.props.filter.writer_detention || ''}
                options={DETENTIONS}
                name='writer_detention'
                width='widthMd'
              />
            </controls.DropdownChildren>
          </div>
          <div>
            <controls.DropdownSelect
              openButtonType="buttonDropdown"
              label="Responsible"
              responsibles
              value={this.props.filter.responsible || ''}
              onDropdownChange={(value, name) => {
                this.props.setFilter('__responsible_name', name);
                this.props.setFilterUpdate('responsible', value);
              }}
              text={this.props.filter.__responsible_name || ''}
              className="dropdown_filter"
            />
          </div>
          <div>
            <h4 className={styles.label_filter}>Tags:</h4>
            <WriterTagFilterDropdown />
          </div>
          <div>
            <h4 className={styles.label_filter}>Public status:</h4>
            <controls.DropdownChildren
              text={this.props.filter.profile_public_status && this.props.filter.profile_public_status.length > 0 ?
                getFilterKeys(PUBLIC_PROFILE_STATUSES, this.props.filter.profile_public_status) :
                'All'}
              loading={loading}
            >
              <controls.FilterPanelType3
                filterValue={this.props.filter.profile_public_status || ''}
                fieldId='profile_public_status'
                onChange={v => this.props.setFilterUpdate('profile_public_status', v)}
                value={this.props.filter.profile_public_status || ''}
                options={PUBLIC_PROFILE_STATUSES}
                name='profile_public_status'
                width='widthMd'
                isMultiple
              />
            </controls.DropdownChildren>
          </div>
          <div>
            <h4 className={styles.label_filter}>Credible:</h4>
            <controls.DropdownChildren
              text={this.props.filter.credibility && this.props.filter.credibility.length > 0 ?
                getFilterKeys(CREDIBLE, this.props.filter.credibility) :
                'All'}
              loading={loading}
            >
              <controls.FilterPanelType3
                filterValue={this.props.filter.credibility || ''}
                fieldId='credibility'
                onChange={v => this.props.setFilterUpdate('credibility', v)}
                value={this.props.filter.credibility || ''}
                options={CREDIBLE}
                name='credibility'
                width='widthMd'
              />
            </controls.DropdownChildren>
          </div>
        </div>
        <Table {...this.props} tableConfig={tableConfig} sticky stickyThreshold="125px" mainLoader />
      </div>
    )
  }
}

const mapStateToProps = state => {
  const { settings } = state || null;
  const orderSubject = (settings.orders_subjects || []).map(subject => [subject, subject]);
  const callStatuses = (settings.calls_status_list || []).map(s => [s, s]);
  const callReasons = (settings.calls_reason_list || []).map(r => [r, r]);
  const countryList = (settings.country_list || []).map(r => [r, r]);
  const profileEntity = state[profileStorePack.name];


  return {
    loading: state.writers.loading,
    loadingNext: state.writers.loadingNext,
    list: state.writers.data.list,
    total: state.writers.data.total,
    countList: state.writers.data.countList,
    page: state.writers.data.page,
    error: state.writers.error,
    filter: state.writers.filter,
    sorting: state.writers.sorting,
    writers: state.writer,
    profile: profileEntity && profileEntity.data,
    orderSubject,
    callStatuses,
    callReasons,
    countryList,
    settings,
  }
};

const mapDispatchToProps = dispatch => ({
  load: () => dispatch(writersTableStorePack.getTableAction()),
  loadNext: () => dispatch(writersTableStorePack.getTableNextAction()),
  getSettings: () => dispatch(settingsActions.getSettingIfNeeded()),
  pluginSetConfig: config => dispatch(mainHubActions.addNewConfig(config)),
  setSorting: (field, isAsc) => {
    dispatch(writersTableStorePack.setTableSortingAction(isAsc === null ? null : field, isAsc));
  },
  setFilter: (field, value) => {
    dispatch(writersTableStorePack.setTableFilterAction(field, value));
  },
  setFilterUpdate: (field, value) => {
    dispatch(writersTableStorePack.setTableFilterAction(field, value));
    dispatch(writersTableStorePack.getTableAction());
  },
  getTableAction: () => {
    dispatch(writersTableStorePack.getTableAction());
  },
  getWriterDecryptAction: (id) => {
    dispatch(writerEntityStorePack.getEntityAction(id, { decrypt: true }));
  },
  resetFilterCreatedAt: () => {
    dispatch(writersTableStorePack.setTableFilterAction('gte__date__created_at', null));
    dispatch(writersTableStorePack.setTableFilterAction('lte__date__created_at', null));
    dispatch(writersTableStorePack.getTableAction());
  },
  resetFilterLastLogin: () => {
    dispatch(writersTableStorePack.setTableFilterAction('gte__date__profile.last_login', null));
    dispatch(writersTableStorePack.setTableFilterAction('lte__date__profile.last_login', null));
    dispatch(writersTableStorePack.getTableAction());
  },
  resetFilterLastSeen: () => {
    dispatch(writersTableStorePack.setTableFilterAction('gte__date__profile.last_writer_accept_at', null));
    dispatch(writersTableStorePack.setTableFilterAction('lte__date__profile.last_writer_accept_at', null));
    dispatch(writersTableStorePack.getTableAction());
  },
  resetFilterTA: () => {
    dispatch(writersTableStorePack.setTableFilterAction('gte__int__profile.completed_orders_count', null));
    dispatch(writersTableStorePack.setTableFilterAction('lte__int__profile.completed_orders_count', null));
    dispatch(writersTableStorePack.getTableAction());
  },
  onResetFilters: () => {
    dispatch(writersTableStorePack.resetTableFilterAction());
    dispatch(writersTableStorePack.getTableAction());
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WritersPage));
