import React, { useState } from 'react';
import classnames from 'classnames';

import {
  controls,
} from '@verdaccio/crminfo';

import styles from './index.styl';

const NTPoolCalibration = ({ systemSettings, updateFinalData }) => {
  const { detain_nt_orders: detainNtOrders, detain_nt_tutors: detainNtTutors } = systemSettings;

  const [detainNTOrders, setDetainNTOrders] = useState(detainNtOrders);
  const [isTutorPanelDropOpened, toggleTutorPanelDropOpened] = useState(false);
  const [tutorsRole, setTutorsRole] = useState(detainNtTutors === undefined ||  detainNtTutors === true ? '#tutor' : 'non-#tutor');

  const onUpdateDetainNTOrders = (e) => {
    const { target: { validity: { valid }, value } } = e;
    
    if (!valid) return;

    let numValue = Number(value);
    if (numValue < 0) numValue = 0;

    if (numValue > 360) numValue = 360;
    
    setDetainNTOrders(numValue);
    updateFinalData((_prevValue) => {
      return {
        ..._prevValue, 
        'detain_nt_orders': numValue,
      }
    });
  };

  const onClickTutorPanel = () => {
    toggleTutorPanelDropOpened(_prevValue => !_prevValue);
  };

  const onChooseTutorsRole = (role) => {
    setTutorsRole(role);
    updateFinalData((_prevValue) => {
      return {
        ..._prevValue, 
        'detain_nt_tutors': role === '#tutor',
      }
    });
  }

  return (
    <div>
      <h2 className={styles.settings_page_main_content__header}>NT pool calibration</h2>
      <div className={styles.settings_page_main_content_item}>
        <div className={classnames(styles.settings_page_main_content_item__flex)}>
          <div className={classnames(styles.first_flex_item, styles.settings_page_main_content_item__flex, styles.settings_page_main_content_item__flex_gap_8)}>
            <div>Detain NT orders for</div>
            <controls.Button style={{ minWidth: '118px' }} className={styles.settings_page_main_content_item__drop_btn} onClick={onClickTutorPanel}>
              {tutorsRole}
              <i className={styles.settings_page_main_content_item__arrow} />
              <controls.Dropdown.Dropdown onOpenChange={onClickTutorPanel} isOpen={isTutorPanelDropOpened} contentClassName={styles.settings_page_main_content_item__drop_container_inner} className={styles.settings_page_main_content_item__drop_container}>
                <controls.Dropdown.Panel tight>
                    <ul>
                      {['#tutor', 'non-#tutor'].map(role =>
                        <li 
                          key={role} 
                          className={classnames(styles.settings_page_main_content_item__drop_container_inner_item, { [styles.settings_page_main_content_item__drop_container_inner_item_selected]: role === tutorsRole })}
                          onClick={() => onChooseTutorsRole(role)}
                          style={{ whiteSpace: 'nowrap' }}
                        >
                          {role}
                        </li>
                      )}
                    </ul>
                </controls.Dropdown.Panel>
            </controls.Dropdown.Dropdown>
            </controls.Button>
            <div>accounts for</div>
          </div>
          <div className={classnames(styles.settings_page_main_content_item__flex, styles.settings_page_main_content_item__flex_gap_8)}>
            <controls.Input min={0} max={360} pattern="[0-9]*" onChange={onUpdateDetainNTOrders} value={detainNTOrders} className={styles.settings_page_main_content_item__input} />
            <div>minutes</div>
          </div>
        </div>
      </div>
    </div>
  )
};

export default NTPoolCalibration;
