import React from 'react';

import { connect } from 'react-redux';

import {
  controls,
  shiftsDevicesTableStorePack
} from '@verdaccio/crminfo';

import styles from './show-only-safe-device.styl';

const ShowOnlySafeDevice = ({ filter, setFilter }) => (
  <div className={styles.show_only_safe_device}>
    <controls.Checkbox
      checked={filter.status === 'allowed'}
      onChange={() => setFilter('status', filter.status === 'allowed' ? 'default' : 'allowed')}
    >
      Show only safe device
    </controls.Checkbox>
  </div>
);

const mapStateToProps = (state, ownProps) => {
  return {
    filter: state.shiftsDevices.filter,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setFilter: (filterName, value) => {
      if (value === 'default') {
        dispatch(shiftsDevicesTableStorePack.resetTableFilterAction());
      } else {
        dispatch(shiftsDevicesTableStorePack.setTableFilterAction(filterName, value));
      }
      dispatch(shiftsDevicesTableStorePack.getTableAction());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ShowOnlySafeDevice);
