import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import debounce from 'debounce';

import {
  controls,
  systemSettingsApi,
} from '@verdaccio/crminfo';

import { Notice } from './components';

import styles from './index.styl';

const CHECKBOX_ITEMS = [
  {
    title: 'dashboard',
    value: 'hard_orders_dashboard',
    disabled: true,
  },
  {
    title: 'with Top Writer addon',
    value: 'hard_orders_top_addon',
  },
  {
    title: 'from 1st time clients',
    value: 'hard_orders_1st_paid',
    disabled: true,
  },
  {
    title: 'from #unicorn clients',
    value: 'hard_orders_unicorn',
  },
  {
    title: 'from #subscription_active clients',
    value: 'hard_orders_subscription_active',
  },
  {
    title: '#previous_cl_order_refunded',
    value: 'hard_orders_previous_cl_order_refunded',
  },
  {
    title: 'parallel orders',
    value: 'hard_orders_parallel_orders',
  },
];

const checkboxMapper = (systemSettings) => {
  return CHECKBOX_ITEMS.reduce((prev, next) => {
    const key = next.value;
    const value = systemSettings[key] || false;
    
    prev[key] = { value };

    return prev
    
  }, {});
};

const patchField = (field, value, updateFinalData, setShareOfEasyPercent, toggleLoading, checkBoxValues, extraObject) => {
  return new Promise((resolve, reject) => {
    toggleLoading(true);
    systemSettingsApi.updateCurrentSetting({
      ...Object.keys(checkBoxValues).reduce((prev, next) => {
        prev[next] = checkBoxValues[next].value;
        return prev
      }, {}),
      ...extraObject,
      [field]: value,
      simulate: true,
    })
    .then(response => {
      const { share_of_easy_percent } = response;
      setShareOfEasyPercent(share_of_easy_percent);
    })
    .catch(err => console.error(err))
    .finally(() => {
      toggleLoading(false);
      resolve();
    });
  });
};

const patchFieldDebounce = debounce((field, value, updateFinalData, setShareOfEasyPercent, toggleLoading, checkBoxValues, extraObject = {}) => {
  patchField(field, value, updateFinalData, setShareOfEasyPercent, toggleLoading, checkBoxValues, extraObject);
}, 1000);

const EasyOrders = ({ systemSettings, updateFinalData }) => {
  const { hard_orders_max_cdl = 1, hard_orders_min_size = 1, share_of_easy_percent, share_of_easy_based_days } = systemSettings;

  const [isEaseDropOpened, toggleEaseDropOpened] = useState(false);
  const [hardOrdersMaxCdl, setHardOrdersMaxCdl] = useState(hard_orders_max_cdl);
  const [hardOrdersMinSize, setHardOrdersMinSize] = useState(hard_orders_min_size);
  const [checkBoxValues, updateCheckboxValues] = useState(checkboxMapper(systemSettings));
  const [isLoading, toggleLoading] = useState(false);
  const [shareOfEasyPercent, setShareOfEasyPercent] = useState(share_of_easy_percent || 0);
  const [shareOfEasyBasedDays, setShareOfEasyBasedDays] = useState(share_of_easy_based_days || 30);

  const onClickEasyPanel = () => {
    toggleEaseDropOpened(!isEaseDropOpened);
  };

  const getExtraData = () => {
    return { 
      'share_of_easy_based_days': shareOfEasyBasedDays, 
      'hard_orders_max_cdl': Number(hardOrdersMaxCdl), 
      'hard_orders_min_size': Number(hardOrdersMinSize) 
    };
  }

  const onCheckEasyOrders = (e) => {
    const { target } = e;
    updateCheckboxValues((__prevUpdateCheckboxValues) => {
      return {
        ...__prevUpdateCheckboxValues,
        [target.name]: { value: target.checked, loading: true }
      }
    });
    patchField(target.name, target.checked, updateFinalData, setShareOfEasyPercent, toggleLoading, checkBoxValues, getExtraData())
    .then(() => {
      updateCheckboxValues((__prevUpdateCheckboxValues) => {
        return {
          ...__prevUpdateCheckboxValues,
          [target.name]: {...__prevUpdateCheckboxValues[target.name], loading: false }
        }
      });
    })
  };

  const onCommonInputHandler = (handler) => {
    return (e) => {
      const { target: { validity: { valid }, value, name } } = e;
    
      if (!valid) return;

      const numValue = Number(value);

      if (numValue < 0) {
        handler(0);
        return;
      }
  
      if (numValue > 999) {
        handler(999);
        return;
      }
  
      handler(numValue);

      patchFieldDebounce(name, numValue, null, setShareOfEasyPercent, toggleLoading, checkBoxValues, getExtraData());
    }
  };

  const onShareOfEasyBasedDays = (value) => {
    setShareOfEasyBasedDays(value);
    patchField('share_of_easy_based_days', value, updateFinalData, setShareOfEasyPercent, toggleLoading, checkBoxValues, { 'hard_orders_max_cdl': Number(hardOrdersMaxCdl), 'hard_orders_min_size': Number(hardOrdersMinSize) });
  }

  useEffect(() => {
    updateFinalData((_prevValue) => {
      return {
        ..._prevValue, 
        ...{
          hard_orders_max_cdl: hardOrdersMaxCdl,
          hard_orders_min_size: hardOrdersMinSize,
          share_of_easy_based_days: shareOfEasyBasedDays,
        }
      }
    })
  }, [hardOrdersMaxCdl, hardOrdersMinSize, shareOfEasyBasedDays]);

  useEffect(() => {
    const obj = Object.entries(checkBoxValues).reduce((prev, next) => {
      const [ key, value ] = next;
      prev[key] = value.value
      return prev;
    }, {});

    updateFinalData((_prevValue) => {
      return {
        ..._prevValue, 
        ...obj
      }
    })
  }, [checkBoxValues]);

  return (
    <div>
      <h2 className={styles.settings_page_main_content__header}>Easy orders modeling & calibration</h2>
        <div className={styles.settings_page_main_content_item}>
          <div className={classnames(styles.settings_page_main_content_item__flex, styles.settings_page_main_content_item__flex_gap_12)}>
            Share of easy orders is <strong>{shareOfEasyPercent}%</strong> based on the last
            <controls.Button className={styles.settings_page_main_content_item__drop_btn} disabled={isLoading} onClick={onClickEasyPanel}>
              {shareOfEasyBasedDays}
              <i className={styles.settings_page_main_content_item__arrow} />
              <controls.Dropdown.Dropdown onOpenChange={onClickEasyPanel} isOpen={isEaseDropOpened} contentClassName={styles.settings_page_main_content_item__drop_container_inner} className={styles.settings_page_main_content_item__drop_container}>
                <controls.Dropdown.Panel tight>
                    <ul>
                      {[7, 30].map(item =>
                        <li 
                          key={item} 
                          className={classnames(styles.settings_page_main_content_item__drop_container_inner_item, { [styles.settings_page_main_content_item__drop_container_inner_item_selected]: item === shareOfEasyBasedDays })}
                          onClick={() => onShareOfEasyBasedDays(item)}
                        >
                          {item}
                        </li>
                      )}
                    </ul>
                </controls.Dropdown.Panel>
            </controls.Dropdown.Dropdown>
            </controls.Button>
          days with the following settings:
          </div>
          <div className={styles.settings_page_main_content_sub_item}>
            <h3 className={styles.settings_page_main_content_item__header}>Do not include in easy orders:</h3>
            {
              CHECKBOX_ITEMS.map(check => <controls.Checkbox className={styles.settings_page_main_content_item__checkbox} key={check.title} isLoading={checkBoxValues[check.value].loading} checked={checkBoxValues[check.value].value} name={check.value} disabled={check.disabled || isLoading} onChange={onCheckEasyOrders}>{check.title}</controls.Checkbox>)
            }
          </div>
        </div>
        <div className={styles.settings_page_main_content_item}>
            <Notice text="Zero turns off order deadline and size limits below" />
            <div className={classnames(styles.settings_page_main_content_item__flex)}>
              <div className={styles.first_flex_item}>Easy order minimum deadline limit</div>
              <div className={classnames(styles.settings_page_main_content_item__flex, styles.settings_page_main_content_item__flex_gap_8)}>
                <controls.Input disabled={isLoading} name="hard_orders_max_cdl" pattern="[0-9]*" onChange={onCommonInputHandler(setHardOrdersMaxCdl)} value={hardOrdersMaxCdl} className={styles.settings_page_main_content_item__input} />
                <div>hours</div>
              </div>
            </div>
        </div>
        <div className={styles.settings_page_main_content_item}>
          <div className={classnames(styles.settings_page_main_content_item__flex)}>
              <div className={styles.first_flex_item}>Easy single order size limit</div>
              <div className={classnames(styles.settings_page_main_content_item__flex, styles.settings_page_main_content_item__flex_gap_8)}>
                <controls.Input disabled={isLoading} name="hard_orders_min_size" pattern="[0-9]*" onChange={onCommonInputHandler(setHardOrdersMinSize)} value={hardOrdersMinSize} className={styles.settings_page_main_content_item__input} />
                <div>pages</div>
              </div>
          </div>
        </div>
    </div>
  )
};

export default EasyOrders;
