import React from 'react';
import { WriterAllocationPlugin } from '@verdaccio/crminfo';

const AllocationPage = props => {
  const { match } = props;
  const { orderId, type } = match.params;
  return (
    <WriterAllocationPlugin data={{ orderId, type }} extended onCancel={() => window.close()} isPage />
  );
}

export default AllocationPage
