import React, { useRef } from 'react';
import BugForm from '@verdaccio/bug-report-tool';

import { isProductionMode } from '../../config';

import stylesMainNav from '../mainNav.styl';
import styles from './index.styl';

const ToBugFormBtn = () => {
  const ref = useRef(null);
  return (
    <>
      <ul ref={ref} className={stylesMainNav.main_nav}><li className={stylesMainNav.item}><a className={styles.link}>Report a bug</a></li></ul>
      <BugForm node={ref} isProduction={isProductionMode} />
    </>
  )
};

export default ToBugFormBtn;
