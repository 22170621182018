export const isOnlyQaffOrCsr = (profile) => {
  if (!profile || !profile.roles) return false;
  if (profile.roles.filter(r => r.indexOf('qaff') < 0 && (r.indexOf('csr') < 0)).length) {
    return false;
  }
  return true;
};

export const isIrff = (profile) => {
  if (!profile || !profile.roles) return false;

  return profile.roles.includes('irff');
}