import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  utils,
  Table,
  pluginConfigs,
  mainHubActions,
  shiftsDevicesTableStorePack,
} from '@verdaccio/crminfo';

const ShiftsDevices = ({ openPlugin, match, history, setSorting, ...other }) => {
  const goToShiftPlugin = () => {
    const deviceId = match.params.deviceId;
    if (deviceId && !isNaN(Number(deviceId))) {
      openPlugin(deviceId)
    } else if (deviceId) {
      history.push('/shifts/devices');
    }
  }
  useEffect(() => {
    goToShiftPlugin();
  }, [match.params]);
  const onClickRow = item => {
    history.push('/shifts/devices/' + item.fingerprint);
  }
  const tableConfig = {
    onClickRow: item => onClickRow(item),
    fields: [
      {
        name: 'Created at',
        formatter: item => utils.formatTime(item.created_at, 'dt'),
      },
      {
        name: 'Fingerprint',
        formatter: item => item.fingerprint,
      },
      {
        name: 'Users',
        formatter: item => item.users_count,
      },
      {
        name: 'isSafe',
        formatter: item => item.status === 'allowed' ? 'Safe' : 'Not safe',
      },
    ],
  };
  return (
    <div>
      <Table {...other} tableConfig={tableConfig} sticky stickyThreshold="0px" mainLoader infinity />
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    list: state.shiftsDevices.data.list || [],
    loading: state.shiftsDevices.loading,
    loadingNext: state.shiftsDevices.loadingNext,
    page: state.shiftsDevices.data.page,
    total: state.shiftsDevices.data.total,
    sorting: state.shiftsDevices.sorting,
    countList: state.shiftsDevices.data.countList,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    load: () => dispatch(shiftsDevicesTableStorePack.getTableAction()),
    loadNext: () => dispatch(shiftsDevicesTableStorePack.getTableNextAction()),
    setSorting: (field, isAsc) => {
      dispatch(shiftsDevicesTableStorePack.setTableSortingAction(isAsc === null ? null : field, isAsc));
    },
    openPlugin: (deviceId) => dispatch(mainHubActions.addNewConfig(pluginConfigs.ShiftDeviceUsersPlugin({ deviceId, options: { closeUrl: '/shifts/devices' } }))),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ShiftsDevices);
