import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';

import {
    notificationsEntityStorePack,
} from '@verdaccio/crminfo';


import styles from './financeNav.styl';

class FinanceNav extends Component {
    render() {
        const { cntPendingDlRefunds } = this.props;
        return (
            <nav className={styles.nav}>
                <NavLink activeClassName={styles.active} to='/finances'>Freelancer finance</NavLink>
                <NavLink activeClassName={styles.active} to='/writer_withdrawal'>Freelancer withdrawal</NavLink>
                <NavLink activeClassName={styles.active} to='/client_refunds'>
                    Client refunds
                    {!!cntPendingDlRefunds && <i className={styles.new}>{cntPendingDlRefunds}</i>}
                </NavLink>
                {/* <NavLink activeClassName={styles.active} to='/payment_accounts'>
                    Payment Accounts
                </NavLink> */}
            </nav>
        );
    }
};

const mapStateToProps = state => {
    const notificationsEntity = state[notificationsEntityStorePack.name];
    const loading =
        !notificationsEntity ||
        notificationsEntity.loading;

    return {
        loading,
        cntPendingDlRefunds: notificationsEntity.loading || notificationsEntity.error ? 0 : notificationsEntity.data.payments_pending_dl,
    };
};

export default withRouter(connect(mapStateToProps, null)(FinanceNav));
