import React, { useEffect, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classnames from 'classnames';

import {
  systemSettingsEntityStorePack,
  controls,
} from '@verdaccio/crminfo';

import { usePrevious } from '../../hooks';

import EasyOrders from './EasyOrders';
import FreelancersCalibration, { CAN_BE_DISABLED } from './FreelancersCalibration';
import OrdersSettings from './OrdersSettings';
import OtherSettings from './OtherSettings';
import Log from './Log';
import NTPoolCalibration from './NTPoolCalibration';
import GPTProjectPoolCalibration from './GPTProjectPoolCalibration';

import styles from './index.styl';

const Settings = ({ roleNames }) => {
  const systemSettings = useSelector(store => store.systemSettings) || {};
  const { data = {}, loading, updateLoading } = systemSettings;
  const dispatch = useDispatch();

  const [finalData, updateFinalData] = useState({});
  const [isShowNotification, toggleShowNotification] = useState(false);

  const prevUpdateLoading = usePrevious(updateLoading);

  const isSettingsChanged = useMemo(() => JSON.stringify(data) !== JSON.stringify(finalData), [finalData]);

  const onSaveData = () => {
    const __finalData = Object.entries(finalData).reduce((prev, next) => {
      const [key, value] = next;
      if (!CAN_BE_DISABLED.includes(key)) {
        return {
          ...prev,
          [key]: value
        };
      }

      const element = document.querySelector(`input[name="${key}"]`) || {};
      return {
        ...prev,
        [key]: element.checked ? value : -1,
      };
    
    }, {});

    dispatch(systemSettingsEntityStorePack.updateEntityAction(__finalData));
  }

  useEffect(() => {
    if (systemSettings && systemSettings.data && Object.keys(systemSettings.data).length > 0) return;

    dispatch(systemSettingsEntityStorePack.getEntityAction());
  }, []);

  useEffect(() => {
    updateFinalData(data);
  }, [data]);

  useEffect(() => {
    if (prevUpdateLoading && !updateLoading) {
      toggleShowNotification(true);

      setTimeout(() => {
        toggleShowNotification(false);
      }, 3000);
    }
  }, [prevUpdateLoading, updateLoading]);
  
  return (
    <div>
      {loading && <div className={styles.settings_page_loader_wrapper}><controls.Loader /></div>}
      {
        !loading && data && (
          <div className={styles.settings_page_content_wrapper}>
            <div className={styles.settings_page_main_content}>
              <div>
                <EasyOrders roleNames={roleNames} systemSettings={data} updateFinalData={updateFinalData} />
                <FreelancersCalibration roleNames={roleNames} systemSettings={data} updateFinalData={updateFinalData} />
                <OrdersSettings roleNames={roleNames} systemSettings={data} updateFinalData={updateFinalData} />
              </div>
              <div>
                <NTPoolCalibration roleNames={roleNames} systemSettings={data} updateFinalData={updateFinalData} />
                <GPTProjectPoolCalibration roleNames={roleNames} systemSettings={data} updateFinalData={updateFinalData} />
                <OtherSettings roleNames={roleNames} systemSettings={data} updateFinalData={updateFinalData} />
              </div>
            </div>
            <controls.Button className={styles.settings_page_submit} primary onClick={onSaveData} disabled={updateLoading || !isSettingsChanged}>
              { updateLoading ? 'Saving changes ...' : 'Save changes'}
              <div className={classnames(styles.settings_page_update_notification, { [styles.settings_page_update_notification_visible]: isShowNotification })}>Updated</div>
            </controls.Button>
          </div>
        )
      }
      {!loading && <Log />}
    </div>
  )

};

export default Settings;
