import React, { useState, useEffect } from 'react';
import classnames from 'classnames';

import {
  controls,
  capitalizeFirstLetter,
} from '@verdaccio/crminfo';

import { Notice } from './components';

import utils from './utils';

import styles from './index.styl';

const OTHER_SETTINGS = [
  {
    title: 'Disable automated PlagScan check',
    type: 'checkbox',
    field: 'disable_plagscan_check',
    canBeEditable: true,
    canBeEditableForRoles: ['csr_teamlead'],
  },
  {
    isGroup: true,
    subtitle: 'Automated “In detention” logic for frauds:',
    titles: [
      {
        title: 'Frauds',
        value: 'indetention_on_fraud',
      },
      {
        title: 'Undefined poor performers',
        value: 'indetention_on_poor',
      }
    ],
    type: 'checkbox',
    field: 'auto_reactivation_for_groups',
  },
  {
    isGroup: true,
    subtitle: 'Auto-reactivation for groups:',
    notice: 'Only Pro and Standard subgroups will be reactivated',
    titles: ['A', 'B', 'C', 'D', 'newbie', 'beginner'],
    type: 'checkbox',
    field: 'auto_reactivation_for_groups',
    extraItems: [
      {
        title: 'Maximum reactivated Freelancers per day <strong>(<dynamicField> reactivated in the last batch)</strong>',
        type: 'input',
        field: 'maximum_reactivation_per_day',
        minValue: 1,
        maxValue: 200,
        dynamicField: 'current_reactivation_per_day'
      },
      {
        subtitle: 'B-level #redeemable tag conditions:',
        title: 'Tag #redeemable all FRs with less than',
        type: 'input',
        field: 'redeemable_cnt_less',
        minValue: 5,
        maxValue: 20,
        size: 'scores',
        defaultValue: 10,
      },
      {
        title: 'Max 1&2 star scores allowed to be #redeemable',
        type: 'input',
        field: 'redeemable_bad_less',
        minValue: 0,
        maxValue: 10,
        size: 'scores',
        defaultValue: 5,
      }
    ],
  },
];

function mapOtherSettingsData() {
  const systemSettings = this;
  return OTHER_SETTINGS.reduce((prev, next) => {
    const { isGroup, field, extraItems = [], titles = [] } = next;

    if (isGroup) {
      prev[field] = (systemSettings[field] || []).map((value => value));

      if (extraItems.length > 0) {
        extraItems.forEach((extraItem) => {
          const { field, defaultValue } = extraItem;
          prev[field] = systemSettings[field] === undefined ? defaultValue : systemSettings[field];
        });
      }

      if (typeof titles[0] === 'object') {
        titles.forEach(_title => {
          prev[_title.value] = systemSettings[_title.value];
        })
      }

    } else {
      prev[field] = systemSettings[field]
    }
    return prev;
  }, {});
}

const OtherSettings = ({ systemSettings, updateFinalData, roleNames }) => {
  const [otherSettingsData, setOtherSettingsData] = useState(mapOtherSettingsData.bind(systemSettings));

  const onCheckBoxChange = (e) => {
    const { target: { name } } = e;
    const isGroup = e.target.getAttribute('data-is-group');
    const groupFiled = e.target.getAttribute('data-group-field');

    setOtherSettingsData(__prevValue => {
      if (isGroup) {
        let newValue = ''
        if (typeof __prevValue[groupFiled] === 'boolean') {
          newValue = !__prevValue[groupFiled];
        } else {
          newValue = __prevValue[groupFiled].includes(name) ? __prevValue[groupFiled].filter(_prevV => _prevV !== name) : [...__prevValue[groupFiled], name]; 
        }

        return {
          ...__prevValue,
          [groupFiled]: newValue,
        }
      }

      return {
        ...__prevValue,
        [name]: !__prevValue[name]
      }
    })
  };

  const onInputChange = (e) => {
    const { target: { validity: { valid }, value, min, max, name } } = e;

    if (!valid) return;

    let numValue = Number(value);
    let numMin = Number(min);
    let numMax = Number(max);

    if (numMin === 0) {
      if (numValue < numMin) numValue = numMin;
  
      if (numValue > numMax) numValue = numMax;
    }

    setOtherSettingsData(__prevValue => {
      return {
        ...__prevValue,
        [name]: numValue
      }
    })
  }

  const onInputBlur = (e) => {
    const { target: { value, min, max, name } } = e;


    let numValue = Number(value);

    if (numValue < min) numValue = min;

    if (numValue > max) numValue = max;

    setOtherSettingsData(__prevValue => {
      return {
        ...__prevValue,
        [name]: numValue
      }
    })
  }

  const createCheckbox = (item) => {
    const { title, field, isGroup, groupFiled, key } = item;
    return (
      <controls.Checkbox key={key} name={isGroup ? title : field} inputProps={{ 'data-group-field': groupFiled, 'data-is-group': isGroup }} className={styles.settings_page_main_content_item__checkbox} checked={isGroup ? field : otherSettingsData[field]} onChange={onCheckBoxChange}>{capitalizeFirstLetter(title)}</controls.Checkbox>
    )
  }

  const createInput = (item) => {
    const { title, field, minValue = 0, maxValue = 100, dynamicField, size, subtitle } = item;

    if (dynamicField) {
      var __title = title.replace('<dynamicField>', systemSettings[dynamicField]);
    }

    return (
      <div>
        {subtitle && <h3 className={styles.settings_page_main_content_item__header}>{subtitle}</h3>}
        <div className={classnames(styles.settings_page_main_content_item__flex, styles['settings_page_main_content_item--with-margin-b-21'])} key={title}>
          <div className={styles.first_flex_item} dangerouslySetInnerHTML={{ __html: __title || title }} />
          <div className={classnames(styles.settings_page_main_content_item__flex, styles.settings_page_main_content_item__flex_gap_8)}>
            <controls.Input name={field} min={minValue} max={maxValue} pattern="[0-9]*" onChange={onInputChange} onBlur={minValue !== 0 && onInputBlur} value={otherSettingsData[field]} className={styles.settings_page_main_content_item__input} />
            {size && <div>{size}</div>}
          </div>
        </div>
      </div>
    )
  }

  useEffect(() => {
    updateFinalData((_prevValue) => {
      return {
        ..._prevValue,
        ...otherSettingsData,
      }
    })
  }, [otherSettingsData]);


  const renderTitles = (_titles = [], _type, _field) => {

    return _titles.map(_title => {

      const isObject = typeof _title === 'object';
      if (_type === 'checkbox') {
        if (isObject) {
          const fieldItemOfGroup = _title.value;
          return createCheckbox({ key: `${fieldItemOfGroup}`, title: _title.title, groupFiled: fieldItemOfGroup, field: otherSettingsData[fieldItemOfGroup], isGroup: true });
        }
        return createCheckbox({ key: `${_field}_${_title}`, title: _title, groupFiled: _field, field: (otherSettingsData[_field] || []).includes(_title), isGroup: true });
      }

    })
  }

  return (
    <div>
      <h2 className={styles.settings_page_main_content__header}>Other settings</h2>
      {OTHER_SETTINGS.map(setting => {
        const { title, isGroup, field, titles, subtitle, type, minValue, maxValue, extraItems = [], notice } = setting;
        const parentClass = classnames(styles.settings_page_main_content_item, utils.setCanBeEditableClass(setting, roleNames));

        if (isGroup) {
          return (
            <div className={parentClass} key={subtitle}>
              <div className={classnames(styles.settings_page_main_content_sub_item, styles['settings_page_main_content_sub_item--no-t-b-p'])}>
                <h3 className={styles.settings_page_main_content_item__header}>{subtitle}</h3>
                {notice &&
                  <Notice text="Only Pro and Standard subgroups will be reactivated" />
                }
                <div className={classnames(styles.settings_page_main_content_sub_item, styles['settings_page_main_content_sub_item--no-t-b-p'])}>
                  {renderTitles(titles, type, field)}
                  {extraItems.map((extraItem) => createInput(extraItem))}
                </div>
              </div>
            </div>
          )
        }

        if (type === 'checkbox') {
          return (
            <div className={parentClass} key={field}>
              {createCheckbox({ title, field })}
            </div>
          );
        }

        return (
          <div className={parentClass} key={field}>
            {createInput({ title, field, minValue, maxValue })}
          </div>
        )

      })}
    </div>
  )
};

export default OtherSettings;
