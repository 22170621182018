import React, { useState } from 'react';
import classnames from 'classnames';

import gridStyles from '../../styles/grid.styl';

import {
  controls,
} from '@verdaccio/crminfo';

import { Notice } from './components';

import styles from './index.styl';

const GROUP_SETTINGS = [
  {
    subtitle: 'Apply to chosen products:',
    titles: [
      {
        title: 'Nerdify',
        parentField: 'gpt_pool_products',
      },
      {
        title: 'Geekly',
        field: 'GH',
        parentField: 'gpt_pool_products',
      },
      {
        title: 'Quiz Easy',
        field: 'QE',
        parentField: 'gpt_pool_products',
      },
      {
        title: 'Awesome Tutors',
        field: 'AT',
        parentField: 'gpt_pool_products',
      }
    ],
  },
  {
    subtitle: 'Apply to chosen primary types:',
    withColumns: true,
    titles: [
      {
        title: 'Writing',
        parentField: 'gpr_pool_primary_order_types',
        isFirstColumn: true,
      },
      {
        title: 'Calculations',
        parentField: 'gpr_pool_primary_order_types',
        isFirstColumn: true,
      },
      {
        title: 'Quiz',
        parentField: 'gpr_pool_primary_order_types',
        isFirstColumn: true,
      },
      {
        title: 'Complex',
        parentField: 'gpr_pool_primary_order_types',
        isFirstColumn: true,
      },
      {
        title: 'Presentation',
        parentField: 'gpr_pool_primary_order_types',
        isSecondColumn: true,
      },
      {
        title: 'Proofreading & Editing',
        parentField: 'gpr_pool_primary_order_types',
        isSecondColumn: true,
      },
      {
        title: 'Other',
        parentField: 'gpr_pool_primary_order_types',
        isSecondColumn: true,
      }
    ],
  }
];

function mapGroupSettingsData() {
  const systemSettings = this;

  return GROUP_SETTINGS.reduce((prev, next) => {
    const { titles } = next;

    const __prev = titles.reduce((prev, next) => {
      const { title, parentField, field } = next;
      const values = systemSettings[parentField] || [];
      return {...prev, [field || title]: values.includes(field || title)}
    }, {});

    return {...prev, ...__prev}
  }, {});
}

const GPTProjectPoolCalibration = ({ systemSettings, roleNames, updateFinalData }) => {
  const { gpt_minimum_order_deadline_hours: gptMinimumOrderDeadlineHours, gpt_orders_detain_minutes: gptOrdersDetainMinutes } = systemSettings;

  const [minimumOrderDeadlineLimit, setMinimumOrderDeadlineLimit] = useState(gptMinimumOrderDeadlineHours);
  const [isDetainOrdersPanelOpened, toggleDetainOrdersPanel] = useState(false);
  const [detainOrdersPanelValue, setDetainOrdersPanelValue] = useState(gptOrdersDetainMinutes);

  const [groupSettingsData, setGroupSettingsData] = useState(mapGroupSettingsData.bind(systemSettings));

  const onMinimumOrderDeadlineLimit = (e) => {
    const { target: { validity: { valid }, value } } = e;
    
    if (!valid) return;

    let numValue = Number(value);

    if (value < 0) numValue = 0;

    if (numValue > 120) numValue = 120;

    setMinimumOrderDeadlineLimit(numValue);

    updateFinalData((_prevValue) => {
      return {
        ..._prevValue, 
        'gpt_minimum_order_deadline_hours': numValue,
      }
    });
  };

  const onToggleDetainOrdersPanel = () => {
    toggleDetainOrdersPanel(__prevValue => !__prevValue);
  };

  const onSetDetainOrdersPanelValue = (value) => {
    setDetainOrdersPanelValue(value)
    updateFinalData((_prevValue) => {
      return {
        ..._prevValue, 
        'gpt_orders_detain_minutes': value,
      }
    });
  };

  const onCheckBoxChange = (parentField) => (e) => {
    const { target: { name } } = e;
    setGroupSettingsData((_prevValue) => {
      return {
        ..._prevValue,
        [name]: !_prevValue[name]
      }
    })
    updateFinalData((_prevValue) => {
      return {
        ..._prevValue, 
        [parentField]: (_prevValue[parentField] || []).includes(name) ? (_prevValue[parentField] || []).filter((it => it !== name)) : [...(_prevValue[parentField] || []), name],
      }
    });
  };

  const renderCheckbox = (item) => {
    const { title, parentField, field } = item;
    return (
      <controls.Checkbox
        key={title}
        name={field || title}
        className={styles.settings_page_main_content_item__checkbox}
        checked={groupSettingsData[field || title] || false}
        onChange={onCheckBoxChange(parentField)}>{title}
      </controls.Checkbox>
    )
  }

  const renderCheckboxes = (groupSetting) => {
    const { titles, withColumns } = groupSetting;

    if (withColumns) {
      const firstColumn = titles.filter(t => t.isFirstColumn);
      const secondColumn = titles.filter(t => t.isSecondColumn);
      return (
        <div className={classnames(gridStyles['row'], gridStyles['column_gap_240'])}>
          <div>
            {firstColumn.map(item => renderCheckbox(item))}
          </div>
          <div>
            {secondColumn.map(item => renderCheckbox(item))}
          </div>
        </div>
      )
    }

    return titles.map(item => renderCheckbox(item))
  }

  const renderGroupSettings = () => {
    return GROUP_SETTINGS.map(groupSetting => {
      const { subtitle } = groupSetting;
      return (
          <div className={styles.settings_page_main_content_item} key={subtitle}>
            <div className={classnames(styles.settings_page_main_content_sub_item, styles['settings_page_main_content_sub_item--no-t-b-p'])}>
              <h3 className={styles.settings_page_main_content_item__header}>{subtitle}</h3>              
              <div className={classnames(styles.settings_page_main_content_sub_item, styles['settings_page_main_content_sub_item--no-t-b-p'])}>
                {renderCheckboxes(groupSetting)}
              </div>
            </div>
          </div>
      )
    })
  }

  return (
    <div>
      <h2 className={styles.settings_page_main_content__header}>GPT Project pool calibration</h2>
      <div className={classnames(styles.settings_page_main_content_item, styles['settings_page_main_content_item--no-border'])}>
        <Notice isBase text="Detention affects orders of <strong>returning clients,</strong> to be submitted to the Final Files section, <strong>1+ page</strong> order size, <strong>no Preferred FR</strong> addon. Regular easy/hard detention logic will be overridden for the specified time of detention." />
        <div className={styles.settings_page_main_content_item}>
          <div className={classnames(styles.settings_page_main_content_item__flex)}>
            <div className={styles.first_flex_item}>Minimum order deadline limit</div>
            <div className={classnames(styles.settings_page_main_content_item__flex, styles.settings_page_main_content_item__flex_gap_8)}>
              <controls.Input pattern="[0-9]*" onChange={onMinimumOrderDeadlineLimit} value={minimumOrderDeadlineLimit} className={styles.settings_page_main_content_item__input} />
              <div>hours</div>
            </div>
          </div>
        </div>
        <div className={styles.settings_page_main_content_item}>
          <div className={classnames(styles.settings_page_main_content_item__flex)}>
            <div className={styles.first_flex_item}>Detain orders for #gpttest accounts for</div>
            <div className={classnames(styles.settings_page_main_content_item__flex, styles.settings_page_main_content_item__flex_gap_8)}>
              <controls.Button className={classnames(styles.settings_page_main_content_item__drop_btn, styles['settings_page_main_content_item__drop_btn--big'])} onClick={onToggleDetainOrdersPanel}>
                {detainOrdersPanelValue}
                <i className={styles.settings_page_main_content_item__arrow} />
                <controls.Dropdown.Dropdown onOpenChange={onToggleDetainOrdersPanel} isOpen={isDetainOrdersPanelOpened} contentClassName={styles.settings_page_main_content_item__drop_container_inner} className={classnames(styles.settings_page_main_content_item__drop_container)}>
                  <controls.Dropdown.Panel tight>
                    <ul>
                      {Array(19).fill().map((_, index) => index * 10).map(item =>
                        <li
                          onClick={() => onSetDetainOrdersPanelValue(item)}
                          key={`${item}`} 
                          className={classnames(styles.settings_page_main_content_item__drop_container_inner_item, { [styles.settings_page_main_content_item__drop_container_inner_item_selected]: item === detainOrdersPanelValue })}
                        >
                        {item}
                        </li>
                      )}
                    </ul>
                  </controls.Dropdown.Panel>
                </controls.Dropdown.Dropdown>
              </controls.Button>
              <div>minutes</div>
            </div>
          </div>
        </div>
      </div>
      {renderGroupSettings()}
    </div>
  )
};

export default GPTProjectPoolCalibration;
