import React from 'react';
import classnames from 'classnames';

import styles from '../index.styl';

const Notice = ({ text, isBase = false }) => {
  return (
    <div className={classnames(styles.settings_page_main_content_item__flex, styles.settings_page_main_content_item__notification_block, {[styles['settings_page_main_content_item__notification_block--base']]: isBase })}>
      <i className={styles.settings_page_main_content_item__notifications_icon} />
      <div className={styles.settings_page_main_content_item__notifications_value} dangerouslySetInnerHTML={{__html: text}} />
    </div>
  );
};

export default Notice;
