import React, { useMemo, useRef } from 'react';
import { connect } from 'react-redux';
import Notification  from 'react-web-notification';

import {
  profileStorePack,
  pushNotificationsRoles,
} from '@verdaccio/crminfo';

import audio from './sound/record.mp3';
import notificationsIcon from './icon/favicon-32x32.png';

const PushNotification = ({ roles = [], pushNotifications = {}}) => {
  const soundRef = useRef(null);
  const { title, options } = pushNotifications;

  const handleNotificationOnShow = () => {
    soundRef.current.muted = false;
    soundRef.current.play();
  };

  const handleNotificationOnClick = () => {
    const { callback } = options;

    if (!callback) return;

    callback();
  }

  const isOperatorForPush = useMemo(() => roles.some(role => pushNotificationsRoles.includes(role.toLocaleUpperCase()), [roles]));

  if (!isOperatorForPush) return null;

  return <>
    <Notification
      onShow={handleNotificationOnShow}
      onClick={handleNotificationOnClick}
      timeout={5000}
      title={title}
      options={{...options, icon: notificationsIcon }}
    />
    <audio id='sound' preload='auto' ref={soundRef} muted="muted">
      <source src={audio} type='audio/mpeg'  />
      <embed hidden={true} autostart='false' loop={false} src={audio} />
    </audio>
  </>
};

const mapStateToProps = state => {
  const profileEntity = state[profileStorePack.name];
  const roles = (!profileEntity.loading && profileEntity.data && profileEntity.data.roles) || [];
  return {
    roles,
    pushNotifications: state.pushNotifications
  };
};

const areEqual = (prev, next) => {
  return JSON.stringify(prev.pushNotifications) === JSON.stringify(next.pushNotifications)
};

export default connect(mapStateToProps, null, null, { areStatesEqual: areEqual })(PushNotification);
