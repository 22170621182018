import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { MainHub } from '@verdaccio/crminfo';

import styles from './../app.styl';

// const getScrollbarWidth = () => {
//   const outer = document.createElement('div');
//   outer.style.visibility = 'hidden';
//   outer.style.overflow = 'scroll';
//   outer.style.msOverflowStyle = 'scrollbar';
//   document.body.appendChild(outer);
//   const inner = document.createElement('div');
//   outer.appendChild(inner);
//   const scrollbarWidth = (outer.offsetWidth - inner.offsetWidth);
//   outer.parentNode.removeChild(outer);
//   return scrollbarWidth;
// }

class CrmPlugin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      backVisible: false,
    };

    this.hubRef = {
      wrappedInstance: {}
    }

    this.onCloseRoute = this.onCloseRoute.bind(this);
    this.onShowBack = this.onShowBack.bind(this);
    this.onBackgroundClick = this.onBackgroundClick.bind(this);
  }

  // componentDidMount() {
  //   this.scrollbarWidth = getScrollbarWidth();
  // }

  onShowBack(backVisible) {
    // if (backVisible) {
    //   document.body.classList.add(styles.no_scroll);
    //   document.body.style.paddingRight = `${this.scrollbarWidth}px`;
    // } else {
    //   document.body.classList.remove(styles.no_scroll);
    //   document.body.style.paddingRight = '';
    // }
    this.setState({ backVisible });
  }

  onCloseRoute(key, data) {
    if (!data.options || !data.options.closeUrl) {
      return;
    }
    const path = this.props.location.pathname;
    if (path === '/' || path.indexOf(data.options.closeUrl) !== 0) {
      return;
    }
    this.props.history.push(data.options.closeUrl);
  }

  onBackgroundClick() {
    if (this.hubRef && this.hubRef.wrappedInstance) {
      this.hubRef.wrappedInstance.minimizeAll();
    }
  }

  render() {
    const { backVisible } = this.state;
    return (
      <div>
        {backVisible && <div className={styles.background} onClick={this.onBackgroundClick} />}
        <MainHub
          height="100%"
          width={770}
          onConfigClose={this.onCloseRoute}
          hideResizeIcon
          onOpenChange={this.onShowBack}
          innerRef={this.hubRef}
        />
      </div>
    );
  }
}

export default withRouter(CrmPlugin);
