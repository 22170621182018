import styles from './index.styl';

const checkOnEditMode = (setting = {}, roleNames = []) => {
  const { canBeEditable, canBeEditableForRoles = [] } = setting;

  if (!canBeEditable) return false;

  if (!canBeEditableForRoles.length) return true;

  return canBeEditableForRoles.some(roleName => roleNames.includes(roleName));
};

const setCanBeEditableClass = (...args) => {
  return checkOnEditMode(...args) ? styles['settings_page--can_be_editable'] : '';
};

export default {
  checkOnEditMode,
  setCanBeEditableClass,
};
