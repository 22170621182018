import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    ordersTableStorePack,
    ticketsTableStorePack,
    threadsTableStorePack,
    writersTableStorePack,
    discountsTableStorePack,
    clientsTableStorePack,
    writerAllocationTableStorePack,
    campaignsStorePack,
    communicationsStorePack,
    sandboxWritersStorePack,
    financesTableStorePack,
    writerWithdrawalTableStorePack,
    clientRefundsTableStorePack,
    shiftsLoginsTableStorePack,
    bugTrackerTableStorePack,
    controls,
} from '@verdaccio/crminfo';
import styles from './../app.styl';
import Icon from './Icon';
import debounce from 'debounce';

const Order = () => (
    <Search
        placeholder="Find order"
        getFilter={(state) => state.orders.filter}
        setFilter={ordersTableStorePack.setTableFilterAction}
        searchAction={ordersTableStorePack.getTableAction}
    />
);

const Ticket = () => (
    <Search
        placeholder="Find ticket"
        getFilter={(state) => state.tickets.filter}
        setFilter={ticketsTableStorePack.setTableFilterAction}
        searchAction={ticketsTableStorePack.getTableAction}
    />
);

const Thread = () => (
    <Search
        placeholder="Find thread"
        getFilter={(state) => state.threads.filter}
        setFilter={threadsTableStorePack.setTableFilterAction}
        searchAction={threadsTableStorePack.getTableAction}
    />
);

const Writer = () => (
    <Search
        placeholder="Find freelancer"
        getFilter={(state) => state.writers.filter}
        setFilter={writersTableStorePack.setTableFilterAction}
        searchAction={writersTableStorePack.getTableAction}
    />
);

const Client = () => (
    <Search
        placeholder="Find client"
        getFilter={(state) => state.clients.filter}
        setFilter={clientsTableStorePack.setTableFilterAction}
        searchAction={clientsTableStorePack.getTableAction}
    />
);

const Allocation = () => (
    <Search
        placeholder="Find freelancer"
        getFilter={(state) => state[writerAllocationTableStorePack.name].filter}
        setFilter={writerAllocationTableStorePack.setTableFilterAction}
    />
);

const Promos = () => (
    <Search
        placeholder="Find promo"
        getFilter={(state) => state.discounts.filter}
        setFilter={discountsTableStorePack.setTableFilterAction}
        searchAction={discountsTableStorePack.getTableAction}
    />
);

const Bonus = () => (
    <Search
        placeholder="Find campaign"
        getFilter={(state) => state.campaigns.filter}
        setFilter={campaignsStorePack.setTableFilterAction}
    />
);

const Communications = () => (
    <Search
        placeholder="Find communications"
        getFilter={(state) => state.communications.filter}
        setFilter={communicationsStorePack.setTableFilterAction}
    />
);

const Sandbox = () => (
    <div>
        <Search
            placeholder="Find applicant"
            getFilter={(state) => state[sandboxWritersStorePack.name].filter}
            setFilter={sandboxWritersStorePack.setTableFilterAction}
            searchAction={sandboxWritersStorePack.getTableAction}
            searchProp="query"
        />
        <Search
            placeholder="Find applicant by IP"
            getFilter={(state) => state[sandboxWritersStorePack.name].filter}
            setFilter={sandboxWritersStorePack.setTableFilterAction}
            searchAction={sandboxWritersStorePack.getTableAction}
            searchProp="register_ip"
        />
    </div>
);

const Finances = () => (
    <Search
        placeholder="Find transaction"
        getFilter={(state) => state[financesTableStorePack.name].filter}
        setFilter={financesTableStorePack.setTableFilterAction}
        searchAction={financesTableStorePack.getTableAction}
    />
);

const Withdrawal = () => (
    <Search
        placeholder="Find transaction"
        getFilter={(state) => state[writerWithdrawalTableStorePack.name].filter}
        setFilter={writerWithdrawalTableStorePack.setTableFilterAction}
        searchAction={writerWithdrawalTableStorePack.getTableAction}
    />
);

const Refunds = () => (
    <Search
        placeholder="Find transaction"
        getFilter={(state) => state[clientRefundsTableStorePack.name].filter}
        setFilter={clientRefundsTableStorePack.setTableFilterAction}
        searchAction={clientRefundsTableStorePack.getTableAction}
    />
);

const ShiftsLogins = () => (
    <Search
        placeholder="Find user"
        getFilter={(state) => state[shiftsLoginsTableStorePack.name].filter}
        setFilter={shiftsLoginsTableStorePack.setTableFilterAction}
        searchAction={shiftsLoginsTableStorePack.getTableAction}
    />
);

const BugTracker = () => (
  <Search
    placeholder="Find bug"
    getFilter={(state) => state[bugTrackerTableStorePack.name].filter}
    setFilter={bugTrackerTableStorePack.setTableFilterAction}
    searchAction={bugTrackerTableStorePack.getTableAction}
  />
);



class BaseSearch extends Component {
    constructor(props) {
        super(props);
        if (props.searchAction) {
            this.searchAction = debounce(this.searchAction, 1000);
        }
    }

    searchAction() {
        this.props.searchAction()
    }

    render() {
        const { searchAction } = this.props;
        return (
            <span>
                <controls.Input placeholder={this.props.placeholder}
                    value={this.props.value || ''}
                    onChange={(e) => {
                        const searchValue = (e.target.value).trimLeft();
                        this.props.setFilter(searchValue);
                        if (!searchAction) {
                            return;
                        }
                        if (searchValue) {
                          this.searchAction();
                        }
                    }}
                />
                <i className={styles.icon_search}><Icon className="svg_icon" iconName="search" /></i>
            </span>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({
    value: ownProps.getFilter(state)[ownProps.searchProp || 'search'],
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    setFilter: value => dispatch(ownProps.setFilter(ownProps.searchProp || 'search', value)),
    searchAction: () => { if (ownProps.searchAction) { dispatch(ownProps.searchAction()); } }
});

const Search = connect(mapStateToProps, mapDispatchToProps)(BaseSearch);

export default { Order, Ticket, Thread, Writer, Client, Allocation, Promos, Bonus, Sandbox, Finances, Withdrawal, Refunds, ShiftsLogins, BugTracker };
