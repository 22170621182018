import {
  hoc,
} from '@verdaccio/crminfo';

const SocketsSubscription = () => {
  return null;
}

const { withTicketSocket, withThreadSocket, withWFHSocket } = hoc;

export default withTicketSocket(withThreadSocket(withWFHSocket(SocketsSubscription)));
