import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import classnames from 'classnames';

import {
  icons,
  mainHubActions,
  controls,
  modals,
} from '@verdaccio/crminfo';

import authFetch from '../../utils/authFetch';

import styles from './index.styl';

const SendSMSModal = ({ BASE_URL, setNewSMS }) => {
  const dispatch = useDispatch();
  const [phoneNumber, setPhoneNumber] = useState('');
  const [smsText, setSmsText] = useState('');
  const [isValidByPhone, toggleIsValidByPhone] = useState(false);
  const [isSendingForm, toggleSendingForm] = useState(false)

  const closeModal = () => {
    dispatch(mainHubActions.popModalComponent());
  };

  const onSetSMSText = (e) => {
    const { target: { value } } = e;
    setSmsText(value);
  };

  const onCheckValid = ({ isPossiblePhone, isValidPhone }) => {
    toggleIsValidByPhone(isPossiblePhone || isValidPhone);
  };

  const onSubmit = () => {
    toggleSendingForm(true);
    authFetch(`${BASE_URL}send`, {
      method: 'POST',
      body: JSON.stringify(
        {
          to: phoneNumber,
          body: smsText,
        }
      ),
    })
    .then(response => {
      if (!response) return;
      
      setNewSMS(response);
    })
    .catch(err => {
      console.error(err);
        setTimeout(() => {
          dispatch(mainHubActions.pushModalComponent(<modals.WarningModal message="Something went wrong." />))
        }, 1);
    })
    .finally(() => {
      closeModal();
    });
  };

  return (
    <div className={styles.send_sms_modal_container}>
      <div className={styles.send_sms_modal_container_header}>
        <h3 className={styles.send_sms_modal_container_header__header}>Send SMS</h3>
        <icons.CloseIcon onClick={closeModal} className={styles.send_sms_modal_container_header__close} />
      </div>
      <form className={classnames({[styles['send_sms_modal_container_form--sending']]: isSendingForm})}>
        <div className={styles.send_sms_modal_container_header_form_group}>
          <label htmlFor="phone_number" className={styles.send_sms_modal_container_header_form_group__label}>Phone number</label>
          <controls.InputPhone onCheckValid={onCheckValid} block name="phone_number" onChange={setPhoneNumber} value={phoneNumber} />
        </div>
        <div className={styles.send_sms_modal_container_header_form_group}>
          <label htmlFor="sms_text" className={styles.send_sms_modal_container_header_form_group__label}>SMS text</label>
          <controls.Textarea maxLength={300} block name="sms_text" onChange={onSetSMSText} value={smsText} placeholder="Enter SMS text" />
        </div>
      </form>
      <div className={styles.send_sms_modal_container__btns}>
          <controls.Button onClick={onSubmit} disabled={!(isValidByPhone && smsText.trim().length > 0) || isSendingForm} className={styles['send_sms_modal_container__btns--submit']} primary>{isSendingForm ? 'Sending ...' : 'Send SMS'}</controls.Button>
        </div>
    </div>
  )
};

export default SendSMSModal;
