import React, { Component } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import moment from 'moment-timezone';

import FinanceNav from './../components/FinanceNav';
import styles from './finances.styl';
import stylesWriter from './writer-page.styl';
import { PAGES, hasAcces } from './../utils/userAccess';

import {
  Table,
  clientRefundsTableStorePack,
  settingsActions,
  utils,
  pluginConfigs,
  mainHubActions,
  profileStorePack,
  controls,
} from '@verdaccio/crminfo';

const humanStatus = [
  ['Finished', 'Finished'],
  ['Pending TL', 'Pending TL'],
  ['Pending PS', 'Pending PS'],
  ['Pending LT', 'Pending LT'],
  ['Pending DL', 'Pending DL'],
  ['Pending', 'Pending'],
  ['Fail', 'Fail'],
];

const TYPES = [
  ['Refund to balance', 'order_account_refund'],
  ['Refund to card', 'refund_request']
];

const USERS = [
  ['A', 'N'],
  ['M', 'Y']
];
class ClientRefundsPage extends Component {
  componentWillMount() {
    const { profile, history } = this.props;

    if (!hasAcces(PAGES.FINANCES, profile.roles)) {
      history.replace('/tickets');
      return;
    }
    this.props.setFilter('status', ['Pending LT', 'Pending TL', 'Pending DL']);
  }

  isOrderRefund(item) {
    return Object.keys(item.order_info || {}).length > 0
  }

  getOrderLink(item) {
    return item.order_info.number || ''
  }

  getAuthorLink(item) {
    if (!item.user_info) return ''

    const { name = '', email = '', phone = '' } = item.user_info;
    return name || email || phone;
  }

  onClickOrder(item) {
    if (!item.order_info) return;

    return pluginConfigs.OrderPlugin({ orderId: item.order_info._id })
  }

  onClickAuthor(item) {
    if (!item.user_info) return;

    return pluginConfigs.ClientPlugin({ clientId: item.user_info._id });
  }

  renderTotal(sum) {
    if (sum < 0) {
      return `($${Math.abs(sum.toFixed(2))})`
    }
    if (sum >= 0) {
      return `$${sum.toFixed(2)}`
    }
  }

  render() {
    const { setSorting, today_sum, today_count, today_limit, today_end_at, updateStatus, setFilter } = this.props;

    const tableConfig = {
      fields: [
        {
          name: 'Created at',
          formatter: item => moment(item.created_at).format('MM/DD/YY HH:mm A'),
          sortingConfig: {
            name: 'created_at',
            onChange: isAsc => setSorting('created_at', isAsc),
          },
          dateRangeFilterConfig: {
            valueFrom: this.props.filter.created_at_gte,
            valueTo: this.props.filter.created_at_lte,
            onChange: (valueFrom, valueTo) => {
              this.props.setFilter('created_at_gte', valueFrom)
              this.props.setFilter('created_at_lte', valueTo)
            }
          },
          width: 1.2,
        },
        {
          name: 'A/M. Automatic or Manual',
          formatter: item => item.author_info ? 'M' : 'A',
          classnames: ['centered'],
        },
        {
          name: 'Type',
          formatter: item => item.human_type,
          filterConfig: {
            value: this.props.filter.type,
            options: TYPES,
            onChange: value => setFilter('type', value),
          },
        },
        {
          name: 'Title',
          formatter: item =>
            < span className={stylesWriter.tooltip} >
              <span className={stylesWriter.tooltip_trigger}>
                <span className={classnames(stylesWriter.order_ellipsis, stylesWriter.ellipsis_xxw)}>{item.comment}</span>
              </span>
              <span className={classnames(stylesWriter.tooltip_content, stylesWriter.tooltip_title_finances)}>
                {item.comment}
              </span>
            </span>,
          width: 2,
        },
        {
          name: 'Amount',
          formatter: item => this.renderTotal(item.amount),
          sortingConfig: {
            name: 'amount',
            onChange: isAsc => setSorting('amount', isAsc),
          },
          classnames: ['centered'],
        },
        {
          name: 'ID',
          formatter: item => this.isOrderRefund(item) ? this.getOrderLink(item) : this.getAuthorLink(item),
          onClick: item => this.props.pluginSetConfig(
            this.isOrderRefund(item) ? this.onClickOrder(item) : this.onClickAuthor(item)
          ),
        },
        {
          name: 'Created by',
          formatter: item => {
            if (item.is_manual) {
              return item.author_info ? `${item.author_info.name} (${item.author_info.role})` : ''
            }
            return 'system'
          },
          filterConfig: {
            value: this.props.filter.is_manual,
            options: USERS,
            onChange: value => setFilter('is_manual', value),
          },
        },
        {
          name: 'Status',
          formatter: item => {
            switch (item.human_status) {
              case 'Pending TL approval':
              case 'Pending LT':
              case 'Pending DL':
                return <controls.TransactionActionDropdown
                status={item.human_status}
                onChange={(refundsRow) => updateStatus(refundsRow)}
                refundsId={item.id}
                error={item._error}
                />

                case 'Fail':
                  return <controls.Tooltip content={item.fail_comment} positionRight>{item.human_status}</controls.Tooltip>
                default:
                  return item.human_status
            }
          },
          filterConfig: {
            value: this.props.filter.status,
            options: humanStatus,
            onChange: value => setFilter('status', value),
            isMultiple: true,
          },
        },
        {
          name: 'Timer',
          formatter: item => item.expected_on ? <controls.Counter deadline={item.expected_on} /> : '0:0',
          classnames: ['centered'],
        },
      ]
    };

    return (
      <div>
        <div className={styles.filter}>
          <FinanceNav />
          <div className={styles.header_wrapper}>
            <div className={styles.statistic}>
              <span className={styles.amounts}>
                <b>Today refunds statistic:</b><span className={styles.important}>{` $${today_sum.toFixed(2)}`}</span>{` (${today_count.toFixed(2)} transactions) out of`}<span className={styles.important}>{` $${today_limit.toFixed(2)}`}</span>. <b>Reset in</b> {utils.formatTime(today_end_at, 'tt')}
              </span>
            </div>
            <div>
              <controls.ClientRefundsApproveAll className={styles.btn_approve}>Approve all</controls.ClientRefundsApproveAll>
              <controls.ExportCSV filter={this.props.filter} sorting={this.props.sorting} dataType='isRefunds' />
            </div>
          </div>
        </div>
        <Table {...this.props} tableConfig={tableConfig} sticky stickyThreshold="164px" mainLoader />
      </div>
    )
  }
}
const mapStateToProps = state => {
  const profileEntity = state[profileStorePack.name];
  const profile = profileEntity && profileEntity.data;

  return {
    loading: state.clientRefunds && state.clientRefunds.loading,
    loadingNext: state.clientRefunds && state.clientRefunds.loadingNext,
    list: (state.clientRefunds && state.clientRefunds.data && state.clientRefunds.data.list) || [],
    total: state.clientRefunds && state.clientRefunds.data && state.clientRefunds.data.total,
    page: state.clientRefunds && state.clientRefunds.data && state.clientRefunds.data.page,
    error: state.clientRefunds && state.clientRefunds.error,
    filter: state.clientRefunds && state.clientRefunds.filter,
    sorting: state.clientRefunds && state.clientRefunds.sorting,
    profile,
    today_sum: (state.clientRefunds && state.clientRefunds.data.today_sum) || 0,
    today_count: (state.clientRefunds && state.clientRefunds.data.today_count) || 0,
    today_limit: (state.clientRefunds && state.clientRefunds.data.today_limit) || 0,
    today_end_at: (state.clientRefunds && state.clientRefunds.data.today_end_at) || '',
  }
};

const mapDispatchToProps = dispatch => ({
  load: () => dispatch(clientRefundsTableStorePack.getTableAction()),
  loadNext: () => dispatch(clientRefundsTableStorePack.getTableNextAction()),
  updateStatus: (refundsRow) => {
    dispatch(clientRefundsTableStorePack.updateTableAction(refundsRow))
  },
  onApproveAll: () => dispatch(clientRefundsTableStorePack.updateTableAction({ action: 'approve_all_refunds' })),
  pluginSetConfig: config => dispatch(mainHubActions.addNewConfig(config)),
	getSettings: () => dispatch(settingsActions.getSettingIfNeeded()),
  setSorting: (field, isAsc) => {
    dispatch(clientRefundsTableStorePack.setTableSortingAction(isAsc === null ? null : field, isAsc));
  },
  setFilter: (field, value) => {
    dispatch(clientRefundsTableStorePack.setTableFilterAction(field, value));
  },
  onResetFilters: () => {
    dispatch(clientRefundsTableStorePack.resetTableFilterAction());
    dispatch(clientRefundsTableStorePack.getTableAction());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ClientRefundsPage);
