import React from 'react';
import {
  withRouter
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import classnames from 'classnames';

import {
  controls,
} from '@verdaccio/crminfo';

import { profileStorePack } from '@verdaccio/crminfo';

import Icon from '../Icon';

import { useValidRole } from '../../hooks';

import styles from './index.styl';

const ToSettingsBtn = ({ history }) => {
  const profile = useSelector(state => state[profileStorePack.name]) || {};
  const { isValidRole: isPlatformManager } = useValidRole(profile, ['platform_manager', 'offf', 'fcm', 'csr_teamlead', 'irff']);

  const goOnSettingsPage = () => {
    history.push('/settings');
  };

  if (!isPlatformManager) return null;

  return (
    <div className={styles.container}>
      <controls.Button emptyDefault onClick={goOnSettingsPage} className={classnames(styles.settings_btn)}>
        <Icon iconName="display-settings" />
      </controls.Button>
    </div>
  )
};

export default withRouter(ToSettingsBtn);
