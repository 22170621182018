import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { profileStorePack } from '@verdaccio/crminfo';

import { useValidRole } from '../../hooks';

const ProtectedByRoleRoute = ({ needRole, children, redirectTo = '/', ...rest }) => {
  const profile = useSelector(state => state[profileStorePack.name]) || {};
  const { isValidRole } = useValidRole(profile, needRole);

  return (
    <Route
      {...rest}
      render={() => {
        return isValidRole ? (
          children
        ) : (
          <Redirect to={redirectTo} />
        );
      }}
    />
  );
};


export default ProtectedByRoleRoute;
