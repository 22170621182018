export default {
  // host for API server
  apiHost: 'https://api-stage.tn-company.com',
  apiHostTickets: 'https://tickettool-stage.dsh-agency.com',
  apiHostSandboxOld: 'https://writersandbox-stage.dsh-agency.com',
  apiHostSandboxNew: 'https://writersandbox-stage.dsh-agency.com',
  apiHostAuth: 'https://oauth-stage.dsh-agency.com',
  apiHostAuthBack: 'https://auth-back-stage.dsh-agency.com',
  apiVersionSmsTool: '/api',
  apiHostSmsTool: 'https://connect-stage.dsh-agency.com',
  baseUrlWFH: 'https://connect-stage.dsh-agency.com',
  wstknOld: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE1MjYzOTQwMjUsInN1YiI6MzYxfQ.Se7aPeF3PWbzMg7EDhxKDGwGNpLnVNQtv73ywR-60Yo',
  wstknNew: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE1MjYzOTQwMjUsInN1YiI6MzYxfQ.Se7aPeF3PWbzMg7EDhxKDGwGNpLnVNQtv73ywR-60Yo',

  writerToolSite: 'https://myaccount-stage.lrwriters.com',
  graderToolSite: 'https://gradertool-stage.lrwriters.com',
  sandboxSite: 'https://recrutingsandbox-stage.dsh-agency.com',
  lrtutorsSite: 'https://stage.lrtutors.com',

  apiHostSocket: 'wss://mqtt-stage.dsh-agency.com:8084/mqtt',

  mynerdifyLink: 'http://mynerdify-stage.dsh-agency.com',
//  geeklyLabSite: 'http://geeklylab-stage.dsh-agency.com',
//  geeklyHubSite: 'http://geeklyhub-stage.dsh-agency.com',
  geeklyLabSite: 'https://stage.geeklylab.com',
  geeklyHubSite: 'https://stage.geeklyhub.net',
  homeworkLabSite: 'http://homeworklab-stage.dsh-agency.com',
  clientChatMessages: 'https://xmpp-nt.dsh-agency.com/nodes/get_items',
  clientChatFiles: 'https://xmpp-nt.dsh-agency.com/nodes/get_files',
  removeClientChatFiles: 'https://xmpp-nt.dsh-agency.com/nodes/remove_files',
  restoreClientChatFiles: 'https://xmpp-nt.dsh-agency.com/nodes/restore_files',
  apiWriterTriggers: 'https://ps-stage.dsh-agency.com',

  // version of used API
  apiVersionPartV1: '/api/v1',
  apiVersionPart: '/api/v3',
  apiVersionPartV4: '/api/v4',

  a4Host: 'https://operator.tn-company.com',

  OCRProxy: 'https://api-stage.tn-company.com/h2c',
  OCRResults: 'https://api-stage.tn-company.com/ocrspace/parse/image',

  fileServerUrl: 'https://n4bybpe0o7.execute-api.eu-west-1.amazonaws.com/dev',

  lrTrackUrl: 'https://statistic-stage.dsh-agency.com',
  lrTrackId: 'TICKET_TOOL',

  isSmsTool: false,
};
