import React, { Component } from 'react';
import { connect } from 'react-redux';
import { profileStorePack, lrtracker, settingsEntityStorePack } from '@verdaccio/crminfo';

import styles from './data-preload.styl';

class DataPreload extends Component {
  componentDidMount() {
    const { load } = this.props;
    load();
  }

  componentDidUpdate(prevProps) {
    const { operatorId } = this.props;

    if (operatorId && operatorId !== prevProps.operatorId) {
      lrtracker.setCid(String(operatorId));
    }
  }

  render() {
    const { children, loaded } = this.props;
    if (loaded) {
      return children;
    }
    return (
      <div className={styles.preloading}><p /></div>
    );
  }
}

const mapStateToProps = (state) => {
  const profileState = state[profileStorePack.name];
  const loaded = !!profileState.data.id;
  const operatorId = state[settingsEntityStorePack.name]?.user_id;
  return { loaded, operatorId };
};

const mapDispatchToProps = dispatch => ({
  load: () => dispatch(profileStorePack.getEntityActionIfNeeded()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DataPreload);
