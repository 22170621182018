import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Table,
  utils,
  threadsTableStorePack,
  pluginConfigs,
  profileStorePack,
  mainHubActions,
  controls,
} from '@verdaccio/crminfo';
import { Link } from 'react-router-dom';

import { isOnlyQaffOrCsr, isIrff } from './../utils/roles';
import { getHiddenWriterLabel } from './../utils';
import Icon from './../components/Icon';
import classnames from 'classnames';

import styles from './writer-page.styl';

const ROLES = [
  ['Freelancer', 'writer'],
  ['Client', 'client'],
  // ['Grader', 'pregrader'],
  ['Operator', 'operator'],
];

class ThreadsPage extends Component {
  componentDidMount() {
    const entityId = this.props.match.params.entityId;
    const entity = this.props.match.params.entity;
    if (entityId) {
      this.props.pluginSetConfig(pluginConfigs.ThreadsPlugin({ entity, entityId, options: { closeUrl: '/threads' } }));
    }
  }

  componentDidUpdate(prevProps) {
    const entityId = this.props.match.params.entityId;
    const entity = this.props.match.params.entity;
    const prevEntityId = prevProps.match.params.entityId;
    if (entityId !== prevEntityId && entityId) {
      this.props.pluginSetConfig(pluginConfigs.ThreadsPlugin({ entity, entityId, options: { closeUrl: '/threads' } }));
    }
  }

  renderUserLink = (role, id, text) => {
    const { profile } = this.props;
    const isQaffOrCsr = isOnlyQaffOrCsr(profile);

    let textTooltip = text;
    let link = '';

    switch (role) {
      case 'pregrader':
        textTooltip = <a onClick={() => this.props.pluginSetConfig(pluginConfigs.GraderPlugin({ writerId: id }))}>{text}</a>
        link = `/writers/${id}/grader`;
        break;

      case 'writer':
        if (isQaffOrCsr) {
          textTooltip = <span>{getHiddenWriterLabel(id)}</span>
        } else {
          textTooltip = <a onClick={() => this.props.pluginSetConfig(pluginConfigs.WriterPlugin({ writerId: id }))}>{text}</a>
          link = `/writers/${id}`;
        }
        break;

      case 'client':
        textTooltip = <a onClick={() => this.props.pluginSetConfig(pluginConfigs.ClientPlugin({ clientId: id }))}>{text}</a>
        link = `/clients/${id}`;
        break;

      default:
        textTooltip = text;
        break;
    }

    if (text.length >= 17) {
      return (
        <div className={styles.flex_row}>
          <span className={styles.tooltip}>
            <span className={styles.tooltip_trigger}>
              <span className={classnames(styles.order_ellipsis, styles.ellipsis_xmw, { [styles.ellipsis_link]: !!link.length })}>{textTooltip}</span>
            </span>
            <span className={styles.tooltip_content}>
              {textTooltip}
            </span>
            {link &&
              <controls.NewWindowButton inTable href={link} />
            }
          </span>
        </div>
      );
    }

    return textTooltip;
  };

  render() {
    const { setSorting, setFilter } = this.props;

    const tableConfig = {
      flashRowKey: item => item.created_at,
      flashOnInit: item => item._isNew,
      selectable: false,
      fields: [
        {
          name: 'Reference ID',
          formatter: (item) => (
            <div className={styles.flex_row}>
              <a onClick={() => this.props.pluginSetConfig(pluginConfigs.OrderPlugin({ orderId: item.order_info._id }))}>
                {item.order_info.number}
              </a>
              <controls.NewWindowButton
                inTable
                className="icon_new_window"
                href={`/orders/${item.order_info._id}`}
              />
            </div>
          ),
          width: .8,
        },
        {
          name: 'From',
          formatter: (item) => this.renderUserLink(item.sender_role, item.sender_user, item.from_user.replace('Writer', 'Freelancer')),
          filterConfig: {
            value: this.props.filter.sender_role,
            options: ROLES,
            onChange: value => setFilter('sender_role', value),
            isMultiple: true,
          },
          width: 1,
        },
        {
          name: 'To',
          formatter: (item) => this.renderUserLink(item.recepient_role, item.recepient_user || item.recepient_name, item.to_user.replace('Writer', 'Freelancer')),
          filterConfig: {
            value: this.props.filter.recepient_role,
            options: ROLES,
            onChange: value => setFilter('recepient_role', value),
            isMultiple: true,
          },
        },
        {
          name: 'Messages',
          formatter: item => {
            return (
              <div className={styles.flex_row}>
                {item.has_attachments &&
                  <i className={styles.icon_attachment} />
                }
                {this.props.match.params.entityId === item.order_info._id
                  ? <a onClick={() => this.props.pluginSetConfig(pluginConfigs.ThreadsPlugin({ entity: 'orders', entityId: item.order_info._id, options: { closeUrl: '/threads' } }))}><span className={styles.overflow_message}>{item.title}</span></a>
                  : <Link to={'/threads/orders/' + item.order_info._id}><span className={styles.overflow_message}>{item.title}</span></Link>}
                <controls.NewWindowButton inTable className="icon_new_window" href={`/threads/orders/${item.order_info._id}`} />
              </div>
            )
          },
          width: 3,
        },
        {
          name: 'Date and time',
          formatter: (item) => {
            return (
              <div>
                {utils.formatTime(item.created_at, 'agot')} <br />
                <span className={styles.time_ago}>{utils.formatTime(item.created_at, 'ago1')}</span>
              </div>
            )
          },
          sortingConfig: {
            name: 'created_at',
            onChange: isAsc => setSorting('created_at', isAsc),
          },
        },
        {
          name: <Icon className="svg-icon" iconName="message" />,
          formatter: (item) => <span className={styles.icon_wrap}><Icon className="svg-icon" iconName="message" /> <i>{item.new_messages}</i></span>,
          classnames: ['centered'],
          width: 0.3,
        },
        {
          name: <Icon className="svg-icon" iconName="icon-ticket" />,
          formatter: item => {
            if (!item.order_info.open_tickets_count) {
              return null;
            }
            return (
              <span className={styles.icon_wrap}>
                <Icon className="svg-icon icon-ticket-bright" iconName="icon-ticket" />
                <i className={styles.message_count}>{item.order_info.open_tickets_count}</i>
              </span>
            )
          },
          classnames: ['centered'],
          width: 0.3,
          ddRight: true,
        },
      ]
    }
    return (
      <Table {...this.props} tableConfig={tableConfig} sticky stickyThreshold="58px" mainLoader />
    )
  }
}

const mapStateToProps = state => ({
  loading: state.threads.loading,
  loadingNext: state.threads.loadingNext,
  list: state.threads.data.list,
  total: state.threads.data.total,
  page: state.threads.data.page,
  error: state.threads.error,
  filter: state.threads.filter,
  sorting: state.threads.sorting,
  profile: state[profileStorePack.name] && state[profileStorePack.name].data,
});

const mapDispatchToProps = dispatch => ({
  load: () => dispatch(threadsTableStorePack.getTableAction()),
  loadNext: () => dispatch(threadsTableStorePack.getTableNextAction()),
  pluginSetConfig: config => dispatch(mainHubActions.addNewConfig(config)),
  setSorting: (field, isAsc) => {
    dispatch(threadsTableStorePack.setTableSortingAction(isAsc === null ? null : field, isAsc));
  },
  setFilter: (field, value) => {
    dispatch(threadsTableStorePack.setTableFilterAction(field, value));
  },
  // setSorting: (field, isAsc) => {
  //   dispatch(threadsTableStorePack.setTableSortingAction(field, isAsc));
  //   dispatch(threadsTableStorePack.getTableAction());
  // },
  // setFilter: (field, value) => {
  //   dispatch(threadsTableStorePack.setTableFilterAction(field, value));
  //   dispatch(threadsTableStorePack.getTableAction());
  // },
  onResetFilters: () => {
    dispatch(threadsTableStorePack.resetTableFilterAction());
    dispatch(threadsTableStorePack.getTableAction());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ThreadsPage);
