import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  controls,
  profileStorePack,
  styles as crmStyles,
} from '@verdaccio/crminfo';

import { urls } from '../config';
import Icon from './Icon';
import classnames from 'classnames';
import styles from './dropdown-profile.styl';
import { clearToken } from './../utils/tokenStorage';
import { WFHTool } from '@verdaccio/crminfo';


let menuItems = [
  {
    name: 'Templates Panel',
    url: urls.replyTemplates,
  },
  // {
  //   name: 'Logout',
  //   url: urls.logout,
  // },
];

class DropdownProfile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };

    this.onOpenChange = this.onOpenChange.bind(this);
    this.onLogout = this.onLogout.bind(this);
  }

  onOpenChange(value) {
    this.setState({ isOpen: value });
  }

  onLogout(event) {
    event.preventDefault();
    this.onOpenChange(false);
    clearToken();
    window.location.href = urls.logout;
  }

  render() {
    const { isOpen } = this.state;
    const { showMenuTemplates, agents, isTL } = this.props;

    return (
      <div className={styles.container}>
        <controls.Dropdown.Dropdown onOpenChange={this.onOpenChange} isOpen={isOpen}>
          <div className={styles.dropdown_content}>
            <controls.Dropdown.Panel className={styles.dropdown_panel}>
              <ul className={classnames(crmStyles.selectStyles.container, styles.project_list)}>
                <li className={classnames(styles.menu_item, styles.divider)}>
                  {isTL &&
                    <WFHTool.WFHButtonRC agents={agents} />
                  }
                  {!isTL &&
                    <WFHTool.WFHButtonBRB agents={agents} />
                  }
                </li>
                {showMenuTemplates &&
                  menuItems.map(item =>
                    <li className={styles.menu_item} key={item.name}>
                      <a href={item.url} target="_blank" rel="noopener noreferrer">{item.name}</a>
                    </li>
                  )
                }
                <li className={styles.menu_item}>
                  <i className={styles.icon_logout}><Icon className="svg_icon" iconName="logout" /></i>
                  <a onClick={this.onLogout}>Logout</a>
                </li>
              </ul>
            </controls.Dropdown.Panel>
          </div>
        </controls.Dropdown.Dropdown>
        <controls.Button emptyDefault onClick={() => { this.onOpenChange(true); }} className={styles.profile_btn}>
          <i className={styles.icon_profile}><Icon className="svg_icon" iconName="user" /></i>
        </controls.Button>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const profileEntity = state[profileStorePack.name];
  const profile = profileEntity && profileEntity.data;
  const agents = state.WFHPushNotifications || {};

  const showMenuTemplates = profile && profile.roles && (profile.roles.includes('manager') && profile.roles.includes('csr_manager'));
  const isTL = profile && profile.roles && profile.roles.includes('csr_teamlead');

  return {
    showMenuTemplates,
    agents,
    isTL,
  }
};

export default connect(mapStateToProps, null)(DropdownProfile);
