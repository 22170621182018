import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  NavLink,
  Route,
  Switch,
} from 'react-router-dom';
import {
  profileStorePack,
  settingsAuthEntityStorePack,
} from '@verdaccio/crminfo';

import Shifts from '../components/Shifts';
import ShiftsLogins from '../components/ShiftsLogins';
import ShiftsDevices from '../components/ShiftsDevices';
import Search from '../components/Search';
import ShowOnlySafeDevice from '../components/ShowOnlySafeDevice';

import styles from '../components/mainNav.styl';

import shiftsStyles from './shiftsStyles.styl';

const ShiftsPage = ({ match, location, profile, history, getAuthSettings }) => {
  useEffect(() => {
    const roles = (profile && profile.roles) || [];
    const isShiftAdmin = (roles.includes('csr_teamlead') || roles.includes('csr_manager'));
    
    if (!isShiftAdmin) {
      history.push('/');
    }
    getAuthSettings();
  }, []);
  const isFirstActive = !location.pathname.match('logins') && !location.pathname.match('devices')
  return (
    <div>
      <div className={shiftsStyles.link_search_container}>
        <ul className={styles.main_nav}>
          <li className={styles.item}>
          <NavLink activeClassName={isFirstActive ? styles.active : null} to="/shifts">
              Shifts
          </NavLink>
          </li>
          <li className={styles.item}>
            <NavLink activeClassName={location.pathname.match('logins') ? styles.active : null} to="/shifts/logins">
              Logins
            </NavLink>
          </li>
          <li className={styles.item}>
            <NavLink activeClassName={location.pathname.match('devices') ? styles.active : null} to="/shifts/devices">
              Devices
            </NavLink>
          </li>
        </ul>
        <div className={shiftsStyles.search_wrap}>
          <Route path="/shifts/logins" component={Search.ShiftsLogins} />
          <Route path="/shifts/devices" component={ShowOnlySafeDevice} exact />
        </div>
      </div>
      <Switch>
        <Route path={`${match.url}/devices/:deviceId`} component={ShiftsDevices} exact/>
        <Route path={`${match.url}/devices`} component={ShiftsDevices} exact/>
        <Route path={`${match.url}/logins/:userId`} component={ShiftsLogins} exact/>
        <Route path={`${match.url}/logins`} component={ShiftsLogins} exact/>
        <Route path={`${match.url}/:shiftsId`} component={Shifts} exact/>
        <Route path={`${match.url}`} component={Shifts} exact/>
      </Switch>
    </div>
  )
}

const mapStateToProps = state => {
  const profileEntity = state[profileStorePack.name];
  return {
      profile: profileEntity && profileEntity.data,
  };
};

const mapDispatchToProps = dispatch => ({
  getAuthSettings: () => {
    dispatch(settingsAuthEntityStorePack.getEntityAction());
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(ShiftsPage);
