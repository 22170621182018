import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames';

import { profileStorePack } from '@verdaccio/crminfo';

import Settings from './Settings';
import Info from './Info';

import styles from './index.styl';

const SettingPage = () => {
  const [activeTab, setActiveTab] = useState('settings');
  const [lineStyles, setLineStyles] = useState({});
  const profile = useSelector(state => state[profileStorePack.name]) || {};
  const { role_names: roleNames = [] } = (profile.data || {});

  const settingRef = useRef({
    cacheInfoData: null,
    cacheInfoFileLoadings: null,
  });
  const settingRefTab = useRef(); 

  const onTabClick = (tab) => {
    return () => {
      setActiveTab(tab);
    }
  };

  const setCacheInfoData = (data) => {
    settingRef.current.cacheInfoData = data;
  };

  const setCacheInfoLoadingsData = (data) => {
    settingRef.current.cacheInfoFileLoadings = {...data};
  };

  useEffect(() => {
    const activeTab = settingRefTab.current.querySelector(`.${[styles.active]}`);
    const { width, left } = activeTab.getBoundingClientRect();
    setLineStyles({
      width, left
    });
  }, [activeTab])

  return (
    <div>
      <div ref={settingRefTab} className={styles.settings_tabs}>
        <div onClick={onTabClick('settings')} className={classnames(styles.settings_tabs__item, {[[styles.active]]: activeTab === 'settings'})}>System settings</div>
        <div onClick={onTabClick('info')} className={classnames(styles.settings_tabs__item, {[[styles.active]]: activeTab === 'info'})}>System information</div>
        <div className={styles.line} style={{...lineStyles}} />
      </div>
      <div className={classnames(styles.settings_page, { [styles['settings_page--read-only']]: !roleNames.includes('platform_manager')})}>
        {
          activeTab === 'settings' && <Settings roleNames={roleNames} />
        }
        {
          activeTab === 'info' && <Info
            setCacheInfoData={setCacheInfoData}
            setCacheInfoLoadingsData={setCacheInfoLoadingsData}
            cacheInfoData={settingRef.current.cacheInfoData}
            cacheInfoFileLoadings={settingRef.current.cacheInfoFileLoadings}
          />
        }
      </div>
    </div>
  )
};

export default SettingPage;
