const comparator = (a, b, asc, getter) => {
  const a_ = getter(a);
  const b_ = getter(b);
  if (!a_ && b_) return asc * -1;
  if (!a_ && !b_) return 0;
  if (a_ && !b_) return asc;
  return (a_ > b_ && asc) || asc * -1;
};

const dateToMsNumber = date => new Date(date).getTime();

const getPercentValue = (value, capmaign) => value / capmaign.stat_total;

export const SORTINGS = {
  reward: (a, b, asc) => comparator(a, b, asc, c => c.reward),
  start_at: (a, b, asc) => comparator(a, b, asc, c => dateToMsNumber(c.start_at)),
  deadline: (a, b, asc) => comparator(a, b, asc, c => dateToMsNumber(c.deadline)),
  created_at: (a, b, asc) => comparator(a, b, asc, c => dateToMsNumber(c.created_at)),
  stat_total: (a, b, asc) => comparator(a, b, asc, c => c.stat_total),
  stat_progress: (a, b, asc) => comparator(a, b, asc, c => getPercentValue(c.stat_progress, c)),
  stat_done: (a, b, asc) => comparator(a, b, asc, c => getPercentValue(c.stat_done, c)),
  stat_deleted: (a, b, asc) => comparator(a, b, asc, c => getPercentValue(c.stat_deleted, c)),
  subject_category: (a, b, asc) => comparator(a, b, asc, c => {
    if (!c.subject_category || !c.subject_category.length) return Number.MAX_VALUE
    return c.subject_category.length
  }),
};
