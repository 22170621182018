import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Table,
  pluginConfigs,
  utils,
  ticketsTableStorePack,
  csrsTableStorePack,
  mainHubActions,
  profileStorePack,
  profileTicketToolStorePack,
  controls,
  ticketSettingsEntityStorePack,
  settingsActions,
  icons,
  statsEntityStorePack,
} from '@verdaccio/crminfo';

//import CreateTicketDropdown from './../components/CreateTicketDropdown';
import { isOnlyQaffOrCsr, isIrff } from './../utils/roles';
import { getHiddenWriterLabel } from './../utils';
import classnames from 'classnames';

import commonStyles from '../app.styl';
import gridStyles from './../styles/grid.styl';
import styles from './writer-page.styl';

const TICKET_STATUSES = [
  ['Active', 'active'],
  ['Snooze', 'snoozed'],
  ['Closed', 'closed'],
  ['Canceled', 'canceled'],
];

const TICKET_TEAMS = [
  ['CSR', 'CSR'],
  ['QAFF', 'QAFF'],
  ['OFFF', 'OFFF'],
  ['FCM', 'FCM'],
  ['CSR_TEAMLEAD', 'CSR_TEAMLEAD'],
  ['FINANCE_MANAGER', 'FINANCE_MANAGER'],
  ['OFFF_GEEKLY', 'OFFF_GEEKLY'],
  ['IRFF', 'IRFF'],
]

const PLAGIARIZM_SUBTYPE = 'Plagiarism';

const MISSED_TYPE = 'missed_ddl';
const MISSED_TYPE_ACTIONS = {
  cdd_missed: 'Client deadline missed',
  nt_cdd_missed: 'Client deadline missed',
  wdd_missed: 'Writer deadline missed',
  nt_wdd_missed: 'Writer deadline missed',
  rdd_missed: 'Revision deadline missed',
  nt_rdd_missed: 'Revision deadline missed',
};
const TECH_ERROR = 'Tech error';

const CUSTOM = 'Custom';
const STREAMS = [
  ['1 OF Ticket stream', '1 OF Ticket stream'],
  ['2 OP Tickets', '2 OP Tickets'],
  ['2 OP Writers', '2 OP Writers'],
  ['3 OP Tickets', '3 OP Tickets'],
  ['3 OP Requests', '3 OP Requests'],
  ['3 OP Writers', '3 OP Writers'],
  ['4 OP Tickets', '4 OP Tickets'],
  ['4 OP Requests', '4 OP Requests'],
  ['4 OP Writers', '4 OP Writers'],
  ['4 OP Orders', '4 OP Orders'],
  ['5 OP Tickets', '5 OP Tickets'],
  ['5 OP Requests', '5 OP Requests'],
  ['5 OP Writers', '5 OP Writers'],
  ['5 OP Orders', '5 OP Orders'],
  ['5 OP Help', '5 OP Help'],
  ['Refund stream', 'Refund stream'],
];

const STREAMS_TYPE_TICKET = {
  'All': [
    'missed_ddl',
    'plagiarism',
    'recurring_plagiarism',
    'not_confirmed',
    'retraction',
    'tech_error',
    'revision',
    'cl_request',
    'cl_reply',
    'no_writer',
    'issue_resolution',
    'update',
    'order_rejected',
    'direct_message',
    'refund',
    'other',
    'force_action',
  ],
  '1 OF Ticket stream': [
    'missed_ddl',
    'plagiarism',
    'recurring_plagiarism',
    'not_confirmed',
    'retraction',
    'tech_error',
    'revision',
    'cl_request',
    'cl_reply',
    'no_writer',
    'issue_resolution',
    'update',
    'order_rejected',
    'direct_message',
    'other',
    'force_action'
  ],
  '2 OP Tickets': [
    'missed_ddl',
    'plagiarism',
    'recurring_plagiarism',
    'not_confirmed',
    'retraction',
    'tech_error',
    'revision',
    'cl_request',
    'cl_reply',
    'force_action',
    'other',
    'no_writer',
  ],
  '2 OP Writers': [
    'issue_resolution',
    'update',
    'order_rejected',
    'direct_message',
    'cl_request',
  ],
  '3 OP Requests': [
    'cl_request',
    'cl_reply',
    'tech_error',
    'direct_message',
    'writer_f-level',
    'add-on',
  ],
  '3 OP Tickets': [
    'missed_ddl',
    'plagiarism',
    'recurring_plagiarism',
    'not_confirmed',
    'retraction',
    'order_rejected',
    'revision',
    'no_writer',
    'other',
    'force_action',
  ],
  '3 OP Requests': [
    'cl_request',
    'cl_reply',
    'issue_resolution',
    'tech_error',
    'direct_message',
  ],
  '3 OP Writers': [
    'issue_resolution',
    'update',
  ],
  '4 OP Tickets': [
    'missed_ddl',
    'plagiarism',
    'recurring_plagiarism',
    'not_confirmed',
    'retraction',
    'order_rejected',
    'revision',
    'cl_reply',
    'cl_request',
    'order_rejected',
  ],
  '4 OP Requests': [
    'cl_request',
    'other',
    'tech_error',
    'direct_message',
  ],
  '4 OP Writers': [
    'issue_resolution',
  ],
  '4 OP Orders': [
    'issue_resolution',
    'no_writer',
    'update',
    'force_action',
  ],
  '5 OP Tickets': [
    'missed_ddl',
    'not_confirmed',
    'retraction',
  ],
  '5 OP Requests': [
    'cl_request',
    'cl_reply',
    'plagiarism',
    'recurring_plagiarism',
    'revision',
  ],
  '5 OP Writers': [
    'issue_resolution',
  ],
  '5 OP Orders': [
    'issue_resolution',
    'no_writer',
    'force_action',
    'order_rejected',
  ],
  '5 OP Help': [
    'update',
    'tech_error',
    'direct_message',
    'other',
  ],
  'Refund stream': [
    'issue_resolution',
    'revision',
    'refund',
  ]
};

const STREAMS_SUB_TYPE_TICKET = {
  'All': [
    'Client’s DDL',
    'WR’s DDL', 
    'Revision DDL', 
    'Plagiarism', 
    'No confirmation about working', 
    'CL cancells the order', 
    'All subtypes', 
    'Disabled writer - parallel order needed', 
    'WR in detention - parallel order needed', 
    'Revision reject - unfair fine', 
    'Change of instructions', 
    'Partial delivery needed', 
    'Specific topic chosen', 
    'WR reply needed', 
    'Extension needed', 
    'Not accepted in 1 hr after last recommendation', 
    'WR feedback', 
    'Clarifications needed', 
    'Issue with the final files', 
    'Lack of files/instructions are not clear', 
    'System bug', 
    "I do not agree with Client's Score", 
    'Need to access order details', 
    'Unfair warning', 'Review penalties', 
    "Can't find my order in progress", 
    "Can't submit an order in progress", 
    'Issue with dashboard login details', 
    'Unclear order details', 
    'Review DSHB instructions', 
    'Approve needed', 
    'Client refund request', 
    'Extra payment', 
    'Lateness', 
    'ORM', 
    'Poor quality',
    'Refund needed',
    'Other',
    'Specific WR requested by CL',
    'Tech error',
    'Missed payment',
    'Order Status Error',
  ],
  '1 OF Ticket stream': [
    'Missed DDL (any one incl. revision)',
    'Plagiarism',
    'No confirmation about working',
    'CL cancells the order',
    'Tech error',
    'Missed payment',
    'Order Status Error',
    'Disabled writer - parallel order needed',
    'WR in detention - parallel order needed',
    'Change of instructions',
    'Partial delivery needed',
    'Specific topic chosen',
    'Specific WR requested by CL',
    'WR reply needed',
    'Extension needed',
    'Not accepted in 1 hr after last recommendation',
    'WR feedback',
    'Clarifications needed',
    'Issue with the final files',
    'Lack of files/instructions are not clear',
    'System bug',
    'Need to access order details',
    'Unfair warning',
    'Review penalties',
    "Can't find my order in progress",
    "Can't submit an order in progress",
    'Issue with dashboard login details',
    'Unclear order details',
    'Review DSHB instructions',
    'Approve needed',
    'Other',
    'Refund needed',
    'Unjustified revision',
  ],
  '2 OP Tickets': [
    'Client’s DDL', 
    'WR’s DDL', 
    'Revision DDL', 
    'Plagiarism', 
    'No confirmation about working', 
    'CL cancells the order', 
    'All subtypes', 
    'Disabled writer - parallel order needed', 
    'WR in detention - parallel order needed', 
    'Change of instructions', 
    'Partial delivery needed', 
    'Specific topic chosen', 
    'Extension needed', 
    'Refund needed', 
    'Not accepted in 1 hr after last recommendation', 
    'WR feedback',
    'Missed DDL (any one incl. revision)',
    'Other',
    'Specific WR requested by CL',
    'Tech error',
    'Missed payment',
    'Order Status Error',
  ],
  '2 OP Writers': [
    'Clarifications needed', 
    'Issue with the final files', 
    'Lack of files/instructions are not clear', 
    'System bug', 
    'Unfair warning', 
    'Unjustified revision', 
    'Review penalties', 
    "Can't find my order in progress", 
    "Can't submit an order in progress", 
    'Issue with dashboard login details', 
    'Need to access order details', 
    'Unclear order details', 
    'Review DSHB instructions', 
    'Approve needed', 
    'WR reply needed'
  ],
  '3 OP Tickets': [
    'Client’s DDL', 
    'WR’s DDL', 
    'Revision DDL', 
    'Plagiarism', 
    'No confirmation about working', 
    'CL cancells the order', 
    'Review DSHB instructions', 
    'Disabled writer - parallel order needed', 
    'WR in detention - parallel order needed', 
    'Not accepted in 1 hr after last recommendation', 
    'WR feedback', 
    'Refund needed',
    'Missed DDL (any one incl. revision)',
    'Other',
  ],
  '3 OP Requests': [
    'Change of instructions', 
    'Partial delivery needed', 
    'Specific topic chosen', 
    'Extension needed', 
    'Issue with dashboard login details', 
    'All subtypes', 
    'Approve needed',
    'WR reply needed',
    'Specific WR requested by CL',
    'Tech error',
    'Missed payment',
    'Order Status Error',
  ],
  '3 OP Writers': [
    'Clarifications needed', 
    'Issue with the final files', 
    'Lack of files/instructions are not clear', 
    'System bug',
    'Unjustified revision',
    "Can't find my order in progress", 
    "Can't submit an order in progress", 
    'Unfair warning', 
    'Review penalties', 
    'Need to access order details', 
    'Unclear order details'
  ],
  '4 OP Tickets': [
    'Client’s DDL', 
    'WR’s DDL', 
    'Revision DDL', 
    'Plagiarism', 
    'No confirmation about working', 
    'CL cancells the order', 
    'Review DSHB instructions', 
    'Disabled writer - parallel order needed', 
    'WR in detention - parallel order needed', 
    'Extension needed', 
    'Change of instructions', 
    'Review DSHB instructions',
    'Missed DDL (any one incl. revision)',
  ],
  '4 OP Requests': [
    'Partial delivery needed', 
    'Specific topic chosen', 
    'WR reply needed', 
    'All subtypes', 
    'Approve needed',
    'Other',
    'Specific WR requested by CL',
    'Tech error',
    'Missed payment',
    'Order Status Error',
  ],
  '4 OP Writers': [
    'Clarifications needed', 
    'Issue with the final files', 
    'Lack of files/instructions are not clear', 
    'System bug', 
    'Issue with dashboard login details', 
    "Can't submit an order in progress", 
    'Unfair warning', 
    'Review penalties', 
    'Unjustified revision',
  ],
  '4 OP Orders': [
    "Can't find my order in progress", 
    'Need to access order details', 
    'Not accepted in 1 hr after last recommendation', 
    'WR feedback', 
    'Unclear order details', 
    'Refund needed'
  ],
  '5 OP Tickets': [
    'Missed DDL (any one incl. revision)',
    'No confirmation about working',
    'CL cancells the order'
  ],
  '5 OP Requests': [
    'Partial delivery needed',
    'Specific topic chosen',
    'Specific WR requested by CL',
    'WR reply needed',
    'Change of instructions',
    'Extension needed',
    'Plagiarism',
    'Disabled writer - parallel order needed',
    'WR in detention - parallel order needed',
  ],
  '5 OP Writers': [
    "Can't find my order in progress",
    "Can't submit an order in progress",
    'Clarifications needed',
    'Issue with dashboard login details',
    'Issue with the final files',
    'Lack of files/instructions are not clear',
  ],
  '5 OP Orders': [
    'Review penalties',
    'System bug',
    'Unjustified revision',
    'Need to access order details',
    'Unfair warning',
    'Not accepted in 1 hr after last recommendation',
    'WR feedback',
    'Refund needed',
    'Review DSHB instructions',
  ],
  '5 OP Help': [
    'Unclear order details',
    'Tech error',
    'Missed payment',
    'Order Status Error',
    'Approve needed',
    'Other',
  ],
  'Refund stream': [
    "I do not agree with Client's Score", 
    'Revision reject - unfair fine', 
    'Client refund request', 
    'Extra payment', 
    'Lateness', 
    'ORM', 
    'Poor quality'
  ],
}

class TicketsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      streams: localStorage.getItem('streams') || '',
    }
    this.getTypeText = this.getTypeText.bind(this);
    this.renderSubType = this.renderSubType.bind(this);
    this.onClickRow = this.onClickRow.bind(this);
    this.setLocalFilter = this.setLocalFilter.bind(this);
    this.loadTotal = this.loadTotal.bind(this);
  }

  componentDidMount() {
    this.props.getTicketToolProfile();
    // this.props.getCsrs();
    this.props.getTicketsSettings();
    this.props.getSettings();

    const ticketParentObjectType = this.props.match.params.parentType;
    const ticketParentObjectId = this.props.match.params.parentId;
    const ticketId = this.props.match.params.ticketId;
    const ticketType = this.props.match.params.ticketType;
    const ticketSubtype = this.props.match.params.ticketSubtype;

    if (ticketId) {
      this.props.pluginSetConfig(pluginConfigs.TicketPlugin({
        ticketId,
        ticketParentObjectType,
        ticketParentObjectId,
        ticketType,
        ticketSubtype,
        options: { closeUrl: '/tickets' },
      }));
    }
    const prevSteams = localStorage.getItem('streams');

    if (Object.keys(this.props.filter || {}).length !== 0 && prevSteams) return;
    this.props.setFilter('by_state', ['active']);

    if (!prevSteams) {
      this.props.setFilter('by_ticket_type', STREAMS_TYPE_TICKET['All']);
      this.props.setFilter('by_subtype', (STREAMS_SUB_TYPE_TICKET['All'] || []));
    } else if (localStorage.getItem('streams') === CUSTOM) {
      this.props.setFilter('by_ticket_type', []);
    }
  }

  componentDidUpdate(prevProps) {
    const ticketParentObjectType = this.props.match.params.parentType;
    const ticketParentObjectId = this.props.match.params.parentId;
    const ticketId = this.props.match.params.ticketId;
    const ticketType = this.props.match.params.ticketType;
    const ticketSubtype = this.props.match.params.ticketSubtype;
    const prevTicketId = prevProps.match.params.ticketId;
    if (prevProps.loading && !this.props.loading) {
      const prevStreams = localStorage.getItem('streams');
      if (!prevStreams || prevStreams === CUSTOM) {
        this.setState({ streams: prevStreams || ''});
        return;
      }
      
      const isPrevSteamInSTREAMS = STREAMS.some(it => it.some(__it => __it === prevStreams));

      if (isPrevSteamInSTREAMS) {
        this.setState({ streams: prevStreams});
        return
      }

      this.setState({ streams: ''});
      this.setLocalFilter();
    }

    if (ticketId !== prevTicketId && ticketId) {
      this.props.pluginSetConfig(pluginConfigs.TicketPlugin({
        ticketId,
        ticketParentObjectType,
        ticketParentObjectId,
        ticketType,
        ticketSubtype,
        options: { closeUrl: '/tickets' },
      }));
    }
  }

  getTypeText(item) {
    const { typeItems } = this.props;
    const ticketTypes = Object.values(typeItems).map(item => [item.text, item.id]);
    const ticketTypeMap = ticketTypes.reduce((result, item) => ({ ...result, [item[1]]: item[0] }), {});
    return ticketTypeMap[item.ticket_type];
  }

  getSubtypeOptions() {
    // const { typeItems } = this.props;
    // const result = [].concat.apply([], Object.values(typeItems).map(item => item.sub || []));
    // return result.map(item => [item, item]);

    const { subTypeItems } = this.props;

    let ticketSubTypeOptions = [];
    Object.values(subTypeItems).forEach(item => {
      const subTypeText = item.sub;
      if (subTypeText) {
        subTypeText.map(st => {
          if (ticketSubTypeOptions.indexOf(st) < 0) {
            ticketSubTypeOptions.push(st);
          }
        });
      }
    });
    return ticketSubTypeOptions.map(item => [item, item]);
  }

  renderSubType(item) {
    let missedText = null;
    if (item.ticket_type === MISSED_TYPE) {
      missedText = MISSED_TYPE_ACTIONS[item.last_event];
    }

    let subtypeText = item.subtype;
    if (item.subtype === PLAGIARIZM_SUBTYPE && item.comment) {
      subtypeText = `Plagiarism percentage ${item.comment}`;
    }
    if (item.subtype === TECH_ERROR && item.last_event) {
      subtypeText = `${subtypeText} ${item.last_event}`;
    }

    return (
      <span>
        {subtypeText}
        {!!missedText && <br />}
        {missedText}
      </span>
    );
  }

  onClickRow(item) {
    const routeTicketId = this.props.match.params.ticketId;
    routeTicketId === item.id
      ? this.props.pluginSetConfig(pluginConfigs.TicketPlugin({
        ticketId: item.id,
        ticketParentObjectType: item.parent_object_type,
        ticketParentObjectId: item.parent_object_id,
        ticketType: item.subtype,
        ticketSubtype: item.ticket_type,
        options: { closeUrl: '/tickets' },
      }))
      : this.props.history.push(`/tickets/${item.parent_object_type}/${item.parent_object_id}/${item.id}`);
  }

  renderReferenceId(item) {
    if (utils.isIssueSubtype(item.subtype)) {
      return item.info.order_info.number;
    }
    const { profile } = this.props;
    const isQaffOrCsr = isOnlyQaffOrCsr(profile);
    const isHideWriterName = item.parent_object_type === 'writer' && isQaffOrCsr;
    return (
      <div>
        {utils.getTicketObjectParentTypeLabel(item.parent_object_type) + ' ' +
          item.reference ? (isHideWriterName ? getHiddenWriterLabel(item.parent_object_id) : item.reference) : item.parent_object_id}
          <controls.NewWindowButton inTable className="icon_new_window" href={`/tickets/${item.parent_object_type}/${item.parent_object_id}/${item.id}`} />
        {' '}
        {item._isNew && <span className={commonStyles.tag}>new</span>}
      </div>
    );
  }

  setLocalFilter(v) {
    if (!v) {
      localStorage.removeItem('streams');
      this.setState({ streams: '' });
      this.props.onlyResetFilter();
      this.props.setFilter('by_ticket_type', STREAMS_TYPE_TICKET['All']);
      this.props.setFilter('by_subtype', (STREAMS_SUB_TYPE_TICKET['All'] || []));
      this.props.load();
      return;
    }

    this.setState({ streams: v });
    localStorage.setItem('streams', v);
    if (v && v !== CUSTOM) {
      this.props.setFilter('by_state', ['active']);
      this.props.setFilter('by_assignee_group', ['OFFF', 'IRFF']);
      this.props.setFilter('by_ticket_type', STREAMS_TYPE_TICKET[v]);
      this.props.setFilter('by_subtype', (STREAMS_SUB_TYPE_TICKET[v] || []));
      this.props.setFilter('by_assignee', []);
      return this.props.load();
    } else {
      // this.props.setFilter('by_ticket_type', []);
    }
  }

  loadTotal() {
      this.props.setFilter('is_total', true);
      this.props.load();
      this.props.setFilter('is_total', '');
  }

  renderPriorityIcon(ticket) {
    const { is_prioritized: isPrioritized, priority } = ticket;

    if (isPrioritized) {
      return (
        <icons.TicketPrioritize />
      )
    }

    if (priority === 'high') {
      return (
        <i className={commonStyles.new} />
      );
    }

    return null;
  }

  render() {
    const { calculateTicketsTotal, setSorting, setFilter, typeItems,
      cntTypeStatus, profile, csrs, loading, total, lastOfProperty = {},
      closedOfCount = 0, closedQaCount = 0, resetCounterStats } = this.props;
    let typeText = '';

    const ticketTypeOptions = Object.values(typeItems).map(item => {
      typeText = item.text;
      if (cntTypeStatus) {
        const cnt = cntTypeStatus[item.id] || 0;
        typeText = item.text + ' (' + cnt + ')';
      }
      return (
        [typeText, item.id]
      )
    });

    if (this.props.settingsLoading) {
      return null;
    }

    const tableConfig = {
      onClickRow: item => this.onClickRow(item),
      flashRowKey: item => item.updated_at,
      flashOnInit: item => item._isNew,
      fieldsClassnames: [(item = {}) => {
        if (item.id === lastOfProperty.id) return 'table_row_group'
      }],
      fields: [
        {
          formatter: (item) => this.renderPriorityIcon(item),
          width: 0.3,
          sortingConfig: {
            name: 'priority',
            onChange: isAsc => setSorting('priority', isAsc),
          },
          classnames: ['centered'],
        },
        {
          name: 'Reference ID',
          formatter: item => this.renderReferenceId(item),
          width: 1.5,
          sortingConfig: {
            name: 'reference',
            onChange: isAsc => setSorting('reference', isAsc),
          },
        },
        {
          name: 'Type',
          formatter: this.getTypeText,
          width: 1.2,
          filterConfig: {
            value: this.props.filter.by_ticket_type,
            isMultiple: true,
            options: ticketTypeOptions,
            onChange: value => { this.setLocalFilter(CUSTOM); setFilter('by_ticket_type', value)},
          },
        },
        {
          name: 'Subtype',
          formatter: (item) => {
            return this.renderSubType(item);
          },
          width: 5,
          filterConfig: {
            value: this.props.filter.by_subtype,
            isMultiple: true,
            options: this.getSubtypeOptions(),
            onChange: value => { this.setLocalFilter(CUSTOM); setFilter('by_subtype', value)},
          },
        },
        {
          name: 'Team role',
          // formatter: (item) => (item.assignee_group && item.assignee_group.name) || '',
          formatter: (item) => (item.assignee_group && item.assignee_group.name) || '',
          filterConfig: {
            value: this.props.filter.by_assignee_group,
            options: TICKET_TEAMS,
            isMultiple: true,
            onChange: value => { this.setLocalFilter(CUSTOM); setFilter('by_assignee_group', value)},
          },
          width: 1.4,
          classnames: ['centered'],
        },
        {
          name: 'Assign person',
          formatter: (item) => {
            const assignee = (item.assignee && item.assignee.email) || '';
            if (assignee && assignee.length > 19) {
              return (
                <span className={styles.tooltip}>
                  <span className={styles.tooltip_trigger}>
                    <span className={classnames(styles.order_ellipsis, styles.ellipsis_xw)}>{assignee}</span>
                  </span>
                  <span className={styles.tooltip_content}>
                    {assignee}
                  </span>
                </span>)
            }
            return assignee
          },
          width: 1.5,
          filterConfig: {
            filterValue: this.props.filter.by_assignee,
            value: this.props.filter.by_assignee,
            getOptions: csrs.length === 0 ? () => this.props.getCsrs() : () => { return null },
            options: csrs,
            isMultiple: true,
            onChange: value => { this.setLocalFilter(CUSTOM); setFilter('by_assignee', value)},
          },
        },
        {
          name: 'Time alive',
          formatter: (item) => utils.millisecondsToDurationString(item.lifetime * 1000),
          sortingConfig: {
            name: 'lifetime',
            onChange: isAsc => setSorting('lifetime', isAsc),
          },
        },
        {
          name: 'Ticket deadline',
          formatter: (item) => {
            const remainData = utils.calculateRemainTime(item.deadline);
            let days = 0;
            let hours = 0;
            let minutes = 0;
            if (remainData) {
              days = remainData.remainDays;
              hours = remainData.remainHours;
              minutes = remainData.remainMinutes;
              return (
                <span className={styles.deadline_container}>
                  <span className={styles.tooltip}>
                    <span className={styles.tooltip_trigger}>
                      {utils.formatTime(item.deadline, 'dt')}
                    </span>
                    <span className={styles.tooltip_content}>
                      Time left: {days}d {hours}h {minutes}m
                    </span>
                  </span>
                </span>
              )
            }
            return (
              utils.formatTime(item.deadline, 'dt')
            )
          },
          width: 1.5,
          sortingConfig: {
            name: 'deadline',
            onChange: isAsc => setSorting('deadline', isAsc),
          },
        },
        {
          name: 'Status',
          formatter: (item) => {
            const isDisabledDropDownExternal = utils.isAgentOrSale(profile) && item.ticket_type === 'issue_resolution'
            return (
              <controls.TicketStatusDropdown
                status={item.aasm_state || ''}
                onSubmit={(value, deadline) => this.props.updateRow({ id: item.id, aasm_state: value, deadline })}
                deadline={item.deadline}
                cancelBubble
                profile={profile}
                parentObjectId={item.parent_object_type === 'order' ? item.parent_object_id : null}
                ticketId={item.id}
                ticketType={item.ticket_type}
                ticketSubtype={item.subtype}
                isDisabledDropDownExternal={isDisabledDropDownExternal}
              />
            );
          },
          filterConfig: {
            value: this.props.filter.by_state,
            isMultiple: true,
            options: TICKET_STATUSES,
            onChange: value => { this.setLocalFilter(CUSTOM); setFilter('by_state', value)},
          },
          classnames: ['centered'],
          ddRight: true,
        }
      ]
    }

    return (
      <div>
        <div className={classnames(gridStyles.row, gridStyles.space_between, styles.filter)} style={{ minWidth: 'auto' }}>
          <div className={classnames(gridStyles.row, gridStyles.vertical, gridStyles.column_gap_12)}>
            <div>
              <h4 className={styles.label_filter}>Streams:</h4>
              <controls.DropdownChildren
                text={this.state.streams ? this.state.streams : 'All'}
                loading={loading}
              >
                <controls.FilterPanelType3
                  filterValue={this.state.streams || ''}
                  fieldId='streams'
                  onChange={this.setLocalFilter}
                  value={this.state.streams}
                  options={STREAMS}
                  name='writer_type'
                  highOptions={[['Custom', 'Custom']]}
                  disabledOptions={['Custom']}
                  withSort={false}
                />
              </controls.DropdownChildren>
            </div>
            <div>
              <h4 className={styles.label_filter}>
                <controls.Tooltip className={styles.tooltip_tickets} content="Number of tickets is calculated based on all filters applied. Reload the page to recalculate with different filtering conditions." positionDown widthMedium>
                  <i className={styles.icon_question} />
                </controls.Tooltip>
                <span>Info</span>
              </h4>
              <div className={styles.calculate_tickets}>
                <controls.Button onClick={this.loadTotal} className={styles.settings_btn}>
                  Calculate tickets
                </controls.Button>
                {(total > 0) &&
                  <div className={styles.calculate_total}>Total number of tickets: <b>{total}</b></div>
                }
              </div>
            </div>
          </div>
          <div className={classnames(gridStyles.row, gridStyles.vertical, gridStyles.column_gap_24)}>
            <div>QA tickets: {closedQaCount}</div>
            <div>OF tickets: {closedOfCount}</div>
            <controls.Button onClick={resetCounterStats} style={{ minWidth: '100px' }}>
              Reset
            </controls.Button>
          </div>
        </div>
        <Table {...this.props} onResetFilters={this.setLocalFilter} tableConfig={tableConfig} sticky stickyThreshold="58px" mainLoader infinity />
      </div>
    )
  }
}

const mapStateToProps = state => {
  const settings = state.settings;
  const hasSettings = !!settings.roles;
  const settingsLoading = !hasSettings || settings.loading;

  // const csrs = (state.csrs.data.list || []).map(csr => ([csr.username, csr.username]));

  const csrs = (state.csrs.data.list || []).reduce((csrs, csr) => {
    if (csr && csr.email) {
      const csrItem = csr.email.trim().toLowerCase();
      csrs.push([csrItem, csrItem]);
    }
    return csrs;
  }, []);

  csrs.sort(function (a, b) {
    if (a < b) return -1;
    if (a > b) return 1;
    return 0;
  })

  let typeItems = {};
  let subTypeItems = [];
  const ticketSettings = state.ticketSettings.data.structure || {};
  for (const parentTypeKey in ticketSettings) {
    typeItems = { ...typeItems, ...ticketSettings[parentTypeKey] };
    subTypeItems = [...subTypeItems, ...Object.values(ticketSettings[parentTypeKey])];
  }

  const tickets = state.tickets.data.list || [];
  const sortedTickets = [...tickets].sort((a, b) => b.is_prioritized - a.is_prioritized);

  const lastOfProperty = sortedTickets.filter(ticket => ticket.is_prioritized).at(-1);

  const statsEntity = state[statsEntityStorePack.name];

  const closedOfCount = (!statsEntity.loading && statsEntity.data && statsEntity.data.closed_of_count) || 0;
  const closedQaCount = (!statsEntity.loading && statsEntity.data && statsEntity.data.closed_qa_count) || 0;

  return {
    loading: state.tickets.loading,
    loadingNext: state.tickets.loadingNext,
    list: sortedTickets,
    total: state.tickets.data.total,
    page: state.tickets.data.page,
    countList: state.tickets.data.countList,
    error: state.tickets.error,
    sorting: state.tickets.sorting,
    filter: state.tickets.filter,
    profile: state[profileStorePack.name] && state[profileStorePack.name].data,
    csrs,
    typeItems,
    settingsLoading,
    subTypeItems,
    cntTypeStatus: state.stats.data && state.stats.data.tickets_by_type ? state.stats.data.tickets_by_type : null,
    lastOfProperty,
    closedOfCount,
    closedQaCount,
  }
};

const mapDispatchToProps = dispatch => ({
  load: () => dispatch(ticketsTableStorePack.getTableAction()),
  loadNext: () => dispatch(ticketsTableStorePack.getTableNextAction()),
  resetCounterStats: () => dispatch(statsEntityStorePack.updateEntityAction()),
  getCsrs: () => dispatch(csrsTableStorePack.getTableAction()),
  setSorting: (field, isAsc) => {
    dispatch(ticketsTableStorePack.setTableSortingAction(isAsc === null ? null : field, isAsc));
  },
  getTicketsSettings: () => dispatch(ticketSettingsEntityStorePack.getEntityActionIfNeeded()),
  changeFilterParentId: event => dispatch(ticketsTableStorePack.setTableFilterAction('parent_object_id', event.target.value)),
  pluginSetConfig: config => dispatch(mainHubActions.addNewConfig(config)),
  // setFilterUpdate: (field, value) => {
  //   dispatch(ticketsTableStorePack.setTableFilterAction(field, value));
  //   dispatch(ticketsTableStorePack.getTableAction());
  // },
  setFilter: (field, value) => {
    dispatch(ticketsTableStorePack.setTableFilterAction(field, value));
  },
  updateRow: ticketRow => dispatch(ticketsTableStorePack.updateTableAction(ticketRow)),
  // onResetFilters: () => {
  //   dispatch(ticketsTableStorePack.resetTableFilterAction());
  //   dispatch(ticketsTableStorePack.getTableAction());
  // },
  getSettings: () => dispatch(settingsActions.getSettingIfNeeded()),
  getTicketToolProfile: () => dispatch(profileTicketToolStorePack.getEntityActionIfNeeded()),
  onlyResetFilter: () => dispatch(ticketsTableStorePack.resetTableFilterAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(TicketsPage);
