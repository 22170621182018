import React, { useMemo, useState } from 'react';
import classnames from 'classnames';

import debounce from 'debounce';

import {
  controls,
  systemSettingsApi,
} from '@verdaccio/crminfo';

import { Notice } from './components';

import styles from './index.styl';

export const CAN_BE_DISABLED = [
  'show_easy_orders_for_C',
  'show_hard_orders_for_newbies',
  'show_hard_orders_for_C'
];

export const MAX_ITEMS_VALUES = {
  '0-30': 7, 
  '0-50': 11,
  '0-100': 21, 
};

const EASY_ORDERS = [
  {
    title: 'Newbies from',
    field:'show_easy_orders_for_newbies',
    isOpen: false,
    maxItems: 1,
    isDisabled: true,
    disabledValue: 0,
  },
  {
    title: 'Beginners from',
    field: 'show_easy_orders_for_beginners',
    isOpen: false,
    maxItems: MAX_ITEMS_VALUES['0-30'],
    pairField: 'show_easy_orders_for_Ap',
    first: true,
  },
  {
    title: 'A+ level from',
    field: 'show_easy_orders_for_Ap',
    isOpen: false,
    maxItems: MAX_ITEMS_VALUES['0-50'],
    pairField: 'show_easy_orders_for_A',
    prevPairField: 'show_easy_orders_for_beginners',
    first: true,
    middle: true,
  },
  {
    title: 'A-level from',
    field: 'show_easy_orders_for_A',
    isOpen: false,
    maxItems: MAX_ITEMS_VALUES['0-50'],
    pairField: 'show_easy_orders_for_B_redeemable',
    prevPairField: 'show_easy_orders_for_Ap',
    first: true,
    middle: true,
  },
  {
    title: '#redeemable B-level from',
    field: 'show_easy_orders_for_B_redeemable',
    isOpen: false,
    maxItems: MAX_ITEMS_VALUES['0-50'],
    pairField: 'show_easy_orders_for_B_nonredeemable',
    prevPairField: 'show_easy_orders_for_A',
    first: true,
    middle: true,
    isDisabled: true,
    optionsDisabled: true,
    disabledValue: 50,
  },
  {
    title: 'non-#redeemable B-level from',
    field: 'show_easy_orders_for_B_nonredeemable',
    isOpen: false,
    maxItems: MAX_ITEMS_VALUES['0-50'],
    pairField: 'show_easy_orders_for_C',
    prevPairField: 'show_easy_orders_for_B_redeemable',
    first: true,
    middle: true,
    isDisabled: true,
    optionsDisabled: true,
    disabledValue: 50,
  },
  {
    title: 'C-level from',
    field: 'show_easy_orders_for_C',
    isOpen: false,
    maxItems: MAX_ITEMS_VALUES['0-100'],
    pairField: 'show_easy_orders_for_B_nonredeemable',
    isDisabled: true,
    optionsDisabled: true,
    disabledValue: 100,
    sliceIndex: 1,
  }
];

const HARD_ORDERS = [
  {
    title: 'A+ level from',
    field: 'show_hard_orders_for_Ap',
    isOpen: false,
    maxItems: 1,
    isDisabled: true,
    disabledValue: 0,
  },
  {
    title: 'A-level from',
    field: 'show_hard_orders_for_A',
    isOpen: false,
    maxItems: MAX_ITEMS_VALUES['0-100'],
    pairField: 'show_hard_orders_for_beginners',
    prevPairField: 'show_hard_orders_for_Ap',
    first: true,
    middle: true,
  },
  {
    title: 'Beginners from',
    field: 'show_hard_orders_for_beginners',
    isOpen: false,
    maxItems: MAX_ITEMS_VALUES['0-50'],
    pairField: 'show_hard_orders_for_B_redeemable',
    prevPairField: 'show_hard_orders_for_A',
    first: true,
    middle: true,
  },
  {
    title: '#redeemable B-level from',
    field: 'show_hard_orders_for_B_redeemable',
    isOpen: false,
    maxItems: MAX_ITEMS_VALUES['0-50'],
    pairField: 'show_hard_orders_for_B_nonredeemable',
    prevPairField: 'show_hard_orders_for_beginners',
    first: true,
    middle: true,
    isDisabled: true,
    optionsDisabled: true,
    disabledValue: 50,
  },
  {
    title: 'non-#redeemable B-level from',
    field: 'show_hard_orders_for_B_nonredeemable',
    isOpen: false,
    maxItems: MAX_ITEMS_VALUES['0-50'],
    pairField: 'show_hard_orders_for_C',
    prevPairField: 'show_hard_orders_for_B_redeemable',
    first: true,
    middle: true,
    isDisabled: true,
    optionsDisabled: true,
    disabledValue: 50,
  },
  {
    title: 'C-level from',
    field: 'show_hard_orders_for_C',
    isOpen: false,
    maxItems: MAX_ITEMS_VALUES['0-100'],
    pairField: 'show_hard_orders_for_newbies',
    prevPairField: 'show_hard_orders_for_B_nonredeemable',
    first: true,
    middle: true,
    isDisabled: true,
    optionsDisabled: true,
    disabledValue: 100,
    
  },
  {
    title: 'Newbies from',
    field: 'show_hard_orders_for_newbies',
    isOpen: false,
    maxItems: MAX_ITEMS_VALUES['0-100'],
    pairField: 'show_hard_orders_for_C',
    isDisabled: true,
    optionsDisabled: true,
    disabledValue: 100,
  }
];

const DROP_MAP = [
  {
    key: 'easy_orders',
    title: 'Show easy orders to:',
    fields: EASY_ORDERS,
  },
  {
    key: 'hard_orders',
    title: 'Show hard orders to:',
    fields: HARD_ORDERS,
  },
];

const CL_ORDERS_TO_FREELANCERS = [
  ['A+ level', 'a_plus_level'],
  ['A-level', 'a_level'],
  ['Beginners', 'beginners'],
  ['#redeemable B-level', 'redeemable_b_level'],
  ['non-#redeemable B-level', 'non_redeemable_b_level'],
  ['C-F levels', 'c_f_levels'],
  ['Newbies', 'newbies'],
];

function dropMapper() {
  const { systemSettings } = this;
  return DROP_MAP.reduce((prev, next) => {
    next.fields.forEach((item) => {
      const { field, disabledValue, optionsDisabled, isDisabled = false } = item;
      const value = systemSettings[field] >= 0 ? systemSettings[field] : disabledValue || 0;
  
      let __disabledValue = isDisabled;

      if (optionsDisabled && systemSettings[field] >= 0) {
        __disabledValue = false
      }

      prev[field] = {...item, value, isDisabled: __disabledValue, checked: !__disabledValue };
    });

    return prev
    
  }, {});
};

const patchMinCredibility = debounce((value, toggleLoading, updateFinalData, setActiveDashboardFreelancers) => {
  toggleLoading(true);
    systemSettingsApi.updateCurrentSetting({
      'min_credibility_score_to_see_dashboard': value,
      simulate: true,
    })
    .then(response => {
      updateFinalData((_prevValue) => {
        return {
          ..._prevValue, 
          min_credibility_score_to_see_dashboard: value,
        }
      })
      const { active_dashboard_freelancers } = response;
      setActiveDashboardFreelancers(active_dashboard_freelancers);
    })
    .catch(err => console.error(err))
    .finally(() => toggleLoading(false))
}, 1000);


const FreelancersCalibration = ({ systemSettings, updateFinalData }) => {
  const { min_credibility_score_to_see_dashboard, active_dashboard_freelancers } = systemSettings;

  const [dropDownValues, setDropDownValue] = useState(dropMapper.bind({ systemSettings }));
  const [minCredibilityScoreToSeeDashboard, setMinCredibilityScoreToSeeDashboard] = useState(min_credibility_score_to_see_dashboard);
  const [isLoading, toggleLoading] = useState(false);
  const [closeDropDown, toggleCloseDropDown] = useState(false);
  const [activeDashboardFreelancers, setActiveDashboardFreelancers] = useState(active_dashboard_freelancers);
  
  const [clOrdersToFreelancers, setClOrdersToFreelancers] = useState(systemSettings.cl_orders_to_freelancers || CL_ORDERS_TO_FREELANCERS.map(item => item[1]));

  const clOrdersToFreelancersText = useMemo(() => {
    const _clOrdersToFreelancers = clOrdersToFreelancers
    .map(clOrdersToFreelancer => {
      return CL_ORDERS_TO_FREELANCERS
        .find(clOrdersToFreelancerConst => clOrdersToFreelancerConst[1] === clOrdersToFreelancer)[0]
    });

    if (_clOrdersToFreelancers.length === CL_ORDERS_TO_FREELANCERS.length) return 'All';

    return _clOrdersToFreelancers.join(', ');

  }, [clOrdersToFreelancers]);

  const commonDropToggleHandler = (field) => {
    return () => {
      setDropDownValue(__prevValue => {
        return {
          ...__prevValue,
          [field]: {
            ...__prevValue[field],
            isOpen: !__prevValue[field].isOpen
          }
        };
      });
    };
  };

  const commonDropValueHandler = (field, value, isInner) => {
    const { maxItems, middle = false } = dropDownValues[field];
    const maxValue = maxItems * 5;

    const __value = value > maxValue ? maxItems * 5 : value;

    setDropDownValue(__prevValue => {
      return {
        ...__prevValue,
        [field]: {
          ...__prevValue[field],
          value: __value
        }
      };
    });
    updateFinalData(__prevValue => {
      return {
        ...__prevValue,
        ...{
          [field]: __value
        }
      }
    });

    if (isInner && !middle) return;

    const { pairField, first = false, prevPairField } = dropDownValues[field];
    const __pairField = middle ? prevPairField: pairField;
    const pairValue = dropDownValues[__pairField].value;

    if (first && value > pairValue && !middle) {
      commonDropValueHandler(__pairField, value, true);
      return;
    }
    

    if ((!first || middle) && value < pairValue) {
      commonDropValueHandler(__pairField, value, true);
    }

  };

  const onMinCredibilityScoreToSeeDashboard = (e) => {
    const { target: { validity: { valid }, value } } = e;

    if (!valid) return;

    let numValue = Number(value);

    if (numValue < 0) numValue = 0;

		if (numValue > 225) numValue = 225;


    setMinCredibilityScoreToSeeDashboard(numValue);
    patchMinCredibility(numValue,toggleLoading, updateFinalData, setActiveDashboardFreelancers);
  };

  const onOptionDisabled = (e) => {
    const { target: { name, dataset = {} } } = e;
    const { prevPairField = '', pairField = '' } = dataset;
    const prevPairItem = (prevPairField && dropDownValues[prevPairField]) || {};
    const { value: prevPairItemValue } = prevPairItem;
    const { value: currentValue } = dropDownValues[name];
    const isChangeOthers = prevPairItemValue && prevPairItemValue > currentValue;
    var value = {};
    setDropDownValue(__prevValue => {
      if (!__prevValue[name].isDisabled) {
        value.value = -1;
      } else {
        value.value = isChangeOthers ? prevPairItemValue : currentValue;
      }

      return {
        ...__prevValue,
        [name]: {
          ...__prevValue[name],
          checked: !__prevValue[name].checked,
          isDisabled: !__prevValue[name].isDisabled,
          ...value,
        }
      };
    });

   setTimeout(() => {
     updateFinalData(__prevValue => {
       return {
         ...__prevValue,
         ...{
           [name]: value.value
         }
       }
     });
  
     if (!isChangeOthers) return;
  
     const pairItem = (pairField && dropDownValues[pairField]) || {};
     const { value: pairItemValue } = pairItem;

     if (pairItemValue === -1) return;
     
     if (prevPairItemValue <= pairItemValue) return;
  
     setDropDownValue(__prevValue => {
       return {
         ...__prevValue,
         [pairField]: {
           ...__prevValue[pairField],
           value: prevPairItemValue,
         }
       };
     });
  
     updateFinalData(__prevValue => {
       return {
         ...__prevValue,
         ...{
           [pairField]: prevPairItemValue
         }
       }
     });
   }, 10);

  };

  const onCLOrdersToFreelancers = (values, isAllCheck) => {
    let __values = values;

    if (values.length === 0) {
      if (!isAllCheck) {
        __values = ['a_plus_level'];
      } else {
        __values = CL_ORDERS_TO_FREELANCERS.map(it => it[1]);
      }
    }

    setClOrdersToFreelancers(__values);
    updateFinalData(__prevValue => {
      return {
        ...__prevValue,
        ...{
          'cl_orders_to_freelancers': __values
        }
      }
    });
    toggleCloseDropDown(true);

    setTimeout(() => toggleCloseDropDown(false), 10);
  }

  return (
    <div>
      <h2 className={styles.settings_page_main_content__header}>Freelancers pool calibration</h2>
      <div className={styles.settings_page_main_content_item}>
        <div className={classnames(styles.settings_page_main_content_item__flex)}>
          <div className={styles.first_flex_item}>Show 1-st time CL orders to Freelancers</div>
          <div className={classnames(styles.settings_page_main_content_item__flex, styles.settings_page_main_content_item__flex_gap_8)}>
          <controls.DropdownChildren
              text={clOrdersToFreelancersText}
              loading={closeDropDown}
            >
              <controls.FilterPanelType3
                filterValue={clOrdersToFreelancers}
                fieldId='cl_orders_to_freelancers'
                onChange={onCLOrdersToFreelancers}
                options={CL_ORDERS_TO_FREELANCERS}
                name='cl_orders_to_freelancers'
                isMultiple
                withSearch={false}
                withSort={false}
                disabledOptions={['a_plus_level']}
              />
            </controls.DropdownChildren>
          </div>
        </div>
        <Notice text="Unselected groups will not see 1st-time client orders regardless of these settings." />
      </div>
      <div className={classnames(styles.settings_page_main_content__two_column)}>
        {
          DROP_MAP.map(({ key, title, fields }) => {
            return (
              <div className={classnames(styles['item_304'], styles.settings_page_main_content_item, styles['settings_page_main_content_item--no-border'])} key={key}>
                <h3 className={styles.settings_page_main_content_item__header}>{title}</h3>
                {
                  fields.map((dropItem) => {
                    const { field: __field } = dropItem
                    const dropDownValue = dropDownValues[__field] || {};
                    const { value, disabledValue, isOpen, field, title, maxItems, pairField, first, isDisabled, optionsDisabled, checked = false, prevPairField } = dropDownValue;
                    const pairItem = (pairField && dropDownValues[pairField]) || {};
                    let { value: pairValue = 0, isDisabled: isPairDisabled, middle, pairField: pairFieldPairField } = pairItem;
                    
                    if (isPairDisabled && middle && pairFieldPairField) {
                      const pairItem = dropDownValues[pairFieldPairField] || {};
                      const { value: __pairValue = 0, isDisabled: __isPairDisabled } = pairItem;

                      if (__isPairDisabled) {
                        pairValue = +Infinity;
                      } else {
                        pairValue = __pairValue;
                      }
                    } else if (isPairDisabled) {
                      pairValue = +Infinity;
                    }

                    return (
                      <div key={field} className={classnames(styles.settings_page_main_content_item, styles['settings_page_main_content_item--no-border'])}>
                        {dropItem.withNotice && (
                          <Notice text="Easy orders shown by default for Newbies. Hard orders shown by default for A group." />
                        )}
                        <div className={classnames(styles.settings_page_main_content_item__flex, styles.settings_page_main_content_item__flex_gap_40)}>
                          <div className={styles.first_flex_item}> { optionsDisabled && <controls.Checkbox className={styles.settings_page_main_content_item__checkbox} checked={checked} name={__field} dataset={{'data-value': value, 'data-pair-field': pairField, 'data-prev-pair-field': prevPairField }} onChange={onOptionDisabled}></controls.Checkbox> }{title}</div>
                          <div className={classnames(styles.settings_page_main_content_item__flex, styles.settings_page_main_content_item__flex_gap_8)}>
                              <controls.Button disabled={isDisabled} className={classnames(styles.settings_page_main_content_item__drop_btn, {[styles['settings_page_main_content_item__drop_btn--big']]: maxItems === 21 })} onClick={commonDropToggleHandler(field)}>
                                {value >= 0 ? value : disabledValue || null}
                                  <i className={styles.settings_page_main_content_item__arrow} />
                                  <controls.Dropdown.Dropdown onOpenChange={commonDropToggleHandler(field)} isOpen={isOpen} contentClassName={styles.settings_page_main_content_item__drop_container_inner} className={classnames(styles.settings_page_main_content_item__drop_container, styles[`drop_top_${maxItems > 11 ? 11 : maxItems }`])}>
                                    <controls.Dropdown.Panel tight>
                                        <ul>
                                          {Array(maxItems).fill().map((_, index) => index * 5).map(item =>
                                            <li
                                              onClick={() => commonDropValueHandler(field, item)}
                                              key={`${item}_${field}`} 
                                              className={classnames(styles.settings_page_main_content_item__drop_container_inner_item, { [styles.settings_page_main_content_item__drop_container_inner_item_selected]: item === value, [styles.settings_page_main_content_item__drop_container_inner_item_disabled]: (first && item > pairValue  ) })}
                                            >
                                              {item}
                                            </li>
                                          )}
                                        </ul>
                                    </controls.Dropdown.Panel>
                                  </controls.Dropdown.Dropdown>
                                </controls.Button>
                            % CDDL
                          </div>
                        </div>
                      </div>
                    )
                  })
                }
              </div>
            )
          })
        }
      </div>
      <div className={styles.settings_page_main_content_item}>
        <div className={classnames(styles.settings_page_main_content_item__flex)}>
            <div className={styles.first_flex_item}>Min credibility score to see Dashboard orders <strong>({activeDashboardFreelancers} active Freelancers)</strong></div>
            <div className={classnames(styles.settings_page_main_content_item__flex, styles.settings_page_main_content_item__flex_gap_8)}>
              <controls.Input disabled={isLoading} pattern="[0-9]*" onChange={onMinCredibilityScoreToSeeDashboard} value={minCredibilityScoreToSeeDashboard} className={styles.settings_page_main_content_item__input} />
              <div>out of 225</div>
            </div>
          </div>
      </div>
    </div>
  )
};

export default FreelancersCalibration;
