import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
// import uuidv4 from 'uuid/v4';

import {
  Table,
  bugTrackerTableStorePack,
  utils,
  mainHubActions,
  pluginConfigs,
  controls,
} from '@verdaccio/crminfo';

import styles from './bug_tracker.styl';

const URL_JIRA = 'https://vls-research-group.atlassian.net/browse/';
const PRIORITY = {
  'Critical': 'highest',
  'High': 'high',
  'Medium': 'medium',
  'Low': 'low',
  'Lowest': 'lowest',
};

const SERVICES_FILTER = [
  ['Service accessibility', 'Service accessibility'],
  ['Payments', 'Payments'],
  ['Messaging', 'Messaging'],
  ['User Interface', 'User Interface'],
  ['Ordering system', 'Ordering system'],
  ['Ticketing system', 'Ticketing system'],
  ['User functional', 'User functional'],
  ['System logics', 'System logics'],
  ['User account', 'User account'],
  ['Other', 'Other'],
];

const PRODUCT_FILTER = [
  ['NT_ST', 'NT_ST'],
  ['QE_ST', 'QE_ST'],
  ['Geekly_ST', 'Geekly_ST'],
  ['A5', 'A5'],
  ['Freelancer_Platform', 'Freelancer_Platform'],
  ['NerdyTutors', 'NerdyTutors'],
  ['Geekly_Family', 'Geekly_Family'],
  ['Nerdify', 'Nerdify'],
  ['Quiz_Easy', 'Quiz_Easy'],
  ['Email', 'Email'],
  ['AT_ST', 'AT_ST'],
  ['AwesomeTutors', 'AwesomeTutors'],
];

const TEAM_FILTER = [
  ['OFFF', 'OFFF'],
  ['IRFF', 'IRFF'],
  ['QAFF', 'QAFF'],
  ['Nerdify CSR', 'Nerdify CSR'],
  ['Geekly CSR', 'Geekly CSR'],
  ['QE CSR', 'QE CSR'],
  ['Shift TL', 'Shift TL'],
  ['CSM', 'CSM'],
  ['Managerial', 'Managerial'],
  ['FCM', 'FCM']
];

const PRIORITY_FILTER = [
  ['Critical', 'Critical'],
  ['High', 'High'],
  ['Medium', 'Medium'],
  ['Low', 'Low'],
  ['Lowest', 'Lowest'],
];

const STATUS_FILTER = [
  ['New', 'New'],
  ['Under review', 'Under review'],
  ['Clarifications required', 'Clarifications required'],
  ['In work', 'In work'],
  ['PO to develop', 'PO to develop'],
  ['IT to develop', 'IT to develop'],
  ['Confirmed by PO', 'Confirmed by PO'],
  ['Glitch/Unable to reproduce', 'Glitch/Unable to reproduce'],
  ['Duplicated report', 'Duplicated report'],
  ['Declined by JPM', 'Declined by JPM'],
  ['Declined by PO', 'Declined by PO'],
  ['Feature developed', 'Feature developed'],
  ['Declined by IT', 'Declined by IT'],
  ['Bug fixed', 'Bug fixed'],
];

const BugTrackerPage = (props) => {
  const { setFilter, setSorting, pluginSetConfig, match, history } = props;

  const getIssueNumber = ({ key = '' }) => {
    return key.split('-')[1];
  };

  const renderIssueNumber = (issue) => {
    return (
      <div>
        {getIssueNumber(issue)}
        <controls.NewWindowButton inTable className="icon_new_window" href={`${URL_JIRA}${issue.key}`} />
      </div>
    )
  };

  const onClickRow = (issue) => {
    const { id, key } = issue;
    const routeEntityId = match.params.entityId;

    if (routeEntityId === id) {
      pluginSetConfig(pluginConfigs.JiraIssuePlugin({ entityId: key, id, options: { closeUrl: '/bug_tracker' } }));
      return;
    }

    history.push(`/bug_tracker/${key}`);
  };

  const tableConfig = {
    onClickRow: issue => onClickRow(issue),
    fields: [
      {
        name: 'Number',
        formatter: issue => renderIssueNumber(issue),
        width: 0.5,
        classnames: ['centered'],
        sortingConfig: {
          name: 'key',
          onChange: isAsc => setSorting('key', isAsc),
        },
      },
      {
        name: 'Title',
        formatter: issue => issue.fields.summary,
        width: 3,
      },
      {
        name: 'Services',
        formatter: issue => {
          if (issue.fields.customfield_10373 && issue.fields.customfield_10373.length > 0) {
            return issue.fields.customfield_10373.map(field => <div key={`${issue.id}${field.value}`}>{field.value}</div>)
          }
        },
        filterConfig: {
          value: props.filter.customfield_10373,
          options: SERVICES_FILTER,
          onChange: value => setFilter('customfield_10373', value),
          isMultiple: true,
        },

      },
      {
        name: 'Product',
        formatter: issue => {
          if (issue.fields.labels && issue.fields.labels.length > 0) {
            return issue.fields.labels.map((value, index) => <div key={index}>{value}</div>)
          }
        },
        filterConfig: {
          value: props.filter.labels,
          options: PRODUCT_FILTER,
          onChange: value => setFilter('labels', value),
          isMultiple: true,
        },
      },
      {
        name: 'Team',
        formatter: issue => (issue.fields.customfield_10372 && issue.fields.customfield_10372.value) || '',
        width: 0.5,
        filterConfig: {
          value: props.filter.customfield_10372,
          options: TEAM_FILTER,
          onChange: value => setFilter('customfield_10372', value),
          isMultiple: true,
        },
      },
      {
        name: 'P',
        formatter: issue => <i className={classnames(styles.priority, { [styles[PRIORITY[issue.fields.priority.name]]]: issue.fields.priority.name })} />,
        width: 0.5,
        classnames: ['centered'],
        filterConfig: {
          value: props.filter.priority,
          options: PRIORITY_FILTER,
          onChange: value => setFilter('priority', value),
          isMultiple: true,
        },
      },
      {
        name: 'Status',
        formatter: issue => <span className={styles.status}>{(issue.fields.status && issue.fields.status.name) || ''}</span>,
        width: 1,
        classnames: ['centered'],
        filterConfig: {
          value: props.filter.status,
          options: STATUS_FILTER,
          onChange: value => setFilter('status', value),
          isMultiple: true,
        },
      },
      {
        name: 'Created',
        formatter: issue => utils.formatTime(issue.fields.created, 'dy'),
        sortingConfig: {
          name: 'created',
          onChange: isAsc => setSorting('created', isAsc),
        },
      },
      {
        name: 'Updated',
        formatter: issue => utils.formatTime(issue.fields.updated, 'dy'),
      },
      // {
      //   name: 'Comment',
      //   formatter: issue => (issue.fields.description && issue.fields.description.name) || '',
      //   width: 2,
      // },
    ]
  };

  useEffect(() => {
    const { entityId } = match.params;
    if (!entityId) return;

    pluginSetConfig(pluginConfigs.JiraIssuePlugin({ entityId, id: entityId, options: { closeUrl: '/bug_tracker' } }));

  }, [match]);

  return (
    <div>
      <Table {...props} tableConfig={tableConfig} mainLoader sticky stickyThreshold="58px" infinity />
    </div>
  )
}

const mapStateToProps = state => {
  const bugList = state.bugTracker;
  if (!bugList) return null;

  return {
    filter: bugList.filter,
    sorting: bugList.sorting,
    loading: bugList.loading,
    loadingNext: bugList.loadingNext,
    list: bugList.data && bugList.data.list,
    total: bugList.data.total,
    page: bugList.data.page,
    countList: bugList.data.total,
    perPage: bugList.data.perPage,
  };
};

const mapDispatchToProps = dispatch => ({
  load: () => dispatch(bugTrackerTableStorePack.getTableAction()),
  loadNext: () => dispatch(bugTrackerTableStorePack.getTableNextAction()),
  setSorting: (field, isAsc) => {
    dispatch(bugTrackerTableStorePack.setTableSortingAction(isAsc === null ? null : field, isAsc));
  },
  setFilter: (field, value) => {
    dispatch(bugTrackerTableStorePack.setTableFilterAction(field, value));
  },
  onResetFilters: () => {
    dispatch(bugTrackerTableStorePack.resetTableFilterAction());
    dispatch(bugTrackerTableStorePack.getTableAction());
  },
  pluginSetConfig: config => dispatch(mainHubActions.addNewConfig(config)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BugTrackerPage);
