import { urls } from './../config';
import { getToken, clearToken } from './tokenStorage';

const authFetch = (url, init, autoContentType = false) => {
    let headers = {
        Authorization: getToken(),
        'X-Role': 'operator',
      };
    
    if (!autoContentType) headers = { ...headers, 'Content-Type': 'application/json' };
    const myInit = Object.assign({
        headers,
    }, init);
    return fetch(url, myInit).then(r => {
        if (r.ok) {
            return r.json();
        }
        if (r.status === 401 || r.status === 403) {
            clearToken();
            const path = `?redirect_url=${Buffer.from(window.location.href).toString('base64')}`;
            window.location = `${urls.login}${path}`;
            return;
        }
        return Promise.reject({ status: r.status, statusText: r.statusText, instance: r });
    });
}

export const authFetchFormData = (url, init) => authFetch(url, init, true);

export default authFetch;
