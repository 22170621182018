import thunkMiddleware from 'redux-thunk';
import { createStore, combineReducers, applyMiddleware, compose } from 'redux';

import {
    orderReducer,
    threadReducer,
    filesReducer,
    // threadsListReducer,
    assignerReducer,
    clientNamedReducer,
    chatFilesNamedReducer,
    orderFilesNamedReducer,
    ticketNamedReducer,
    discountNamedReducer,
    settingsReducer,
    orderReportNamedReducer,
    mainHubReducer,
    ordersTableStorePack,
    clientsTableStorePack,
    writersTableStorePack,
    discountsTableStorePack,
    threadsTableStorePack,
    ticketsTableStorePack,
    callbacksTableStorePack,
    financesTableStorePack,
    writerWithdrawalTableStorePack,
    operatorsTableStorePack,
    writersFeedbackTableStorePack,
    operatorLoginsTableStorePack,
    deviceUsersTableStorePack,
    clientRefundsTableStorePack,
    financeOperatorListTableStorePack,
    csrsTableStorePack,
    profileStorePack,
    profileTicketToolStorePack,
    ticketSettingsEntityStorePack,
    orderHistoryTableByIdStorePack,
    // preferredWriterTableByIdStorePack,
    orderNoticesEntityStorePack,
    entityLogTableByIdStorePack,
    entityApplicantLogTableByIdStorePack,
    orderRejectsTableByIdStorePack,
    clientTransactionsTableByIdStorePack,
    clientSubscriptionTableByIdStorePack,
    clientPaymentMethodsTableByIdStorePack,
    sameClientsTableByIdStorePack,
    clientReferalTableByIdStorePack,
    clientReferredByTableByIdStorePack,
    entityTicketsTableByIdStorePack,
    entityTicketsGradingTableByIdStorePack,
    writerListSameFeedbackOrderTableByIdStorePack,
    discountStatsTableByIdStorePack,
    writerAllocationTableStorePack,
    relatedWriterAllocationTableByIdStorePack,
    orderAllocationTableStorePack,
    writerEntityStorePack,
    settingsAuthEntityStorePack,
    threadsEntityStorePack,
    writerFinancesTableByIdStorePack,
    writerBonusesTableByIdStorePack,
    orderBonusesTableByIdStorePack,
    writerTasksTableByIdStorePack,
    notificationsEntityStorePack,
    statsEntityStorePack,
    callsTableByIdStorePack,
    campaignsStorePack,
    communicationsStorePack,
    campaignStorePack,
    sandboxWriterStorePack,
    sandboxWritersStorePack,
    sandboxSettingsStorePack,
    sandboxWriterLogsStorePack,
    sandboxFCMOperatorsStorePack,
    ticketPrioritizeNamedReducer,
    writerPerformanceEntityStorePack,
    writerNoticesEntityStorePack,
    writerNoticeTableByIdStorePack,
    clientPerformanceEntityStorePack,
    orderReactionLogStorePack,
    shiftsTableStorePack,
    shiftsLoginsTableStorePack,
    shiftsDevicesTableStorePack,
    clientChatEntityStorePack,
    writerListEntityStorePack,
    systemSettingsEntityStorePack,
    replyTemplateStorePack,
    replyAnswersStorePack,
    pushNotificationsReducer,
    bugTrackerTableStorePack,
    orderIssuesTableStorePack,
    orderIssueEntityStorePack,
    WFHPushNotificationsReducer,
    communicationEntityStorePack,
  } from '@verdaccio/crminfo';

  const rootReducer = combineReducers({
    ...ordersTableStorePack.tableNamedReducer,
    ...clientsTableStorePack.tableNamedReducer,
    ...writersTableStorePack.tableNamedReducer,
    ...writersFeedbackTableStorePack.tableNamedReducer,
    ...operatorsTableStorePack.tableNamedReducer,
    ...operatorLoginsTableStorePack.tableNamedReducer,
    ...deviceUsersTableStorePack.tableNamedReducer,
    ...shiftsTableStorePack.tableNamedReducer,
    ...shiftsLoginsTableStorePack.tableNamedReducer,
    ...shiftsDevicesTableStorePack.tableNamedReducer,
    ...discountsTableStorePack.tableNamedReducer,
    ...threadsTableStorePack.tableNamedReducer,
    ...ticketsTableStorePack.tableNamedReducer,
    ...callbacksTableStorePack.tableNamedReducer,
    ...writerWithdrawalTableStorePack.tableNamedReducer,
    ...financesTableStorePack.tableNamedReducer,
    ...clientRefundsTableStorePack.tableNamedReducer,
    ...financeOperatorListTableStorePack.tableNamedReducer,
    ...csrsTableStorePack.tableNamedReducer,
    ...orderHistoryTableByIdStorePack.tableNamedReducer,
    // ...preferredWriterTableByIdStorePack.tableNamedReducer,
    ...orderNoticesEntityStorePack.entityNamedReducer,
    ...entityLogTableByIdStorePack.tableNamedReducer,
    ...entityApplicantLogTableByIdStorePack.tableNamedReducer,
    ...orderRejectsTableByIdStorePack.tableNamedReducer,
    ...clientReferalTableByIdStorePack.tableNamedReducer,
    ...clientReferredByTableByIdStorePack.tableNamedReducer,
    ...clientSubscriptionTableByIdStorePack.tableNamedReducer,
    ...clientPaymentMethodsTableByIdStorePack.tableNamedReducer,
    ...clientTransactionsTableByIdStorePack.tableNamedReducer,
    ...sameClientsTableByIdStorePack.tableNamedReducer,
    ...entityTicketsTableByIdStorePack.tableNamedReducer,
    ...entityTicketsGradingTableByIdStorePack.tableNamedReducer,
    ...writerListSameFeedbackOrderTableByIdStorePack.tableNamedReducer,
    ...writerAllocationTableStorePack.tableNamedReducer,
    ...discountStatsTableByIdStorePack.tableNamedReducer,
    ...relatedWriterAllocationTableByIdStorePack.tableNamedReducer,
    ...writerPerformanceEntityStorePack.entityNamedReducer,
    ...writerNoticesEntityStorePack.entityNamedReducer,
    ...writerNoticeTableByIdStorePack.tableNamedReducer,
    ...clientPerformanceEntityStorePack.entityNamedReducer,
    ...orderAllocationTableStorePack.tableNamedReducer,
    ...profileStorePack.entityNamedReducer,
    ...profileTicketToolStorePack.entityNamedReducer,
    ...notificationsEntityStorePack.entityNamedReducer,
    ...statsEntityStorePack.entityNamedReducer,
    ...ticketSettingsEntityStorePack.entityNamedReducer,
    ...writerFinancesTableByIdStorePack.tableNamedReducer,
    ...writerBonusesTableByIdStorePack.tableNamedReducer,
    ...orderBonusesTableByIdStorePack.tableNamedReducer,
    ...writerTasksTableByIdStorePack.tableNamedReducer,
    ...callsTableByIdStorePack.tableNamedReducer,
    ...campaignsStorePack.tableNamedReducer,
    ...communicationsStorePack.tableNamedReducer,
    ...campaignStorePack.entityNamedReducer,
    ...sandboxWriterStorePack.entityNamedReducer,
    ...sandboxWritersStorePack.tableNamedReducer,
    ...sandboxSettingsStorePack.entityNamedReducer,
    ...sandboxFCMOperatorsStorePack.entityNamedReducer,
    ...sandboxWriterLogsStorePack.tableNamedReducer,
    ...orderReactionLogStorePack.entityNamedReducer,
    ...bugTrackerTableStorePack.tableNamedReducer,
    order: orderReducer,
    thread: threadReducer,
    assigner: assignerReducer,
    settings: settingsReducer,
    pushNotifications: pushNotificationsReducer,
    WFHPushNotifications: WFHPushNotificationsReducer,
    ...clientNamedReducer,
    ...chatFilesNamedReducer,
    ...ticketNamedReducer,
    ...orderReportNamedReducer,
    ...discountNamedReducer,
    ...writerEntityStorePack.entityNamedReducer,
    ...settingsAuthEntityStorePack.entityNamedReducer,
    ...threadsEntityStorePack.entityNamedReducer,
    mainHub: mainHubReducer,
    files: filesReducer,
    // threadsList: threadsListReducer,
    ...orderFilesNamedReducer,
    ...clientChatEntityStorePack.entityNamedReducer,
    ...writerListEntityStorePack.entityNamedReducer,
    ...systemSettingsEntityStorePack.entityNamedReducer,
    ...replyTemplateStorePack.entityNamedReducer,
    ...replyAnswersStorePack.entityNamedReducer,
    ...ticketPrioritizeNamedReducer,
    ...orderIssuesTableStorePack.tableNamedReducer,
    ...orderIssueEntityStorePack.entityNamedReducer,
		...communicationEntityStorePack.entityNamedReducer,
  });
  
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const loadStore = (key) => {
  try {
    const serializedState = localStorage.getItem(key);
    if (!serializedState) {
      return null;
    }
    return JSON.parse(serializedState);
  } catch (e) {
    return null;
  }
};

const saveStore = state => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('state', serializedState);
  } catch (err) { }
};

const getStore = preloadedState => {
  const loadedStore = loadStore('state') || {};
  if (loadedStore && loadedStore.orders &&
    loadedStore.orders.filter && loadedStore.orders.filter.notcoming) {
      delete loadedStore.orders.filter.notcoming;
      loadedStore.orders.filter.coming = true;
      saveStore(loadedStore);
    }

  if (loadedStore && loadedStore.orders &&
    loadedStore.orders.filter && loadedStore.orders.filter.custom_tags) {
      const set = new Set(loadedStore.orders.filter.custom_tags);
      loadedStore.orders.filter.custom_tags = Array.from(set);
    } 

  const store = createStore(
    rootReducer,
    loadedStore,
    composeEnhancers(applyMiddleware(thunkMiddleware))
  );
  store.subscribe(() => {
    const state = store.getState();

    const stateToSave = [
      ticketsTableStorePack,
      ordersTableStorePack,
      threadsTableStorePack,
      writersTableStorePack,
      clientsTableStorePack,
      bugTrackerTableStorePack
    ].reduce((result, storePack) => {
      const { filter, sorting } = state[storePack.name];
      const newFilter = { ...filter };
      delete newFilter.search;
      result[storePack.name] = {
        ...storePack.defaultState,
        filter: newFilter,
        sorting,
      };
      return result;
    }, {});

    saveStore(stateToSave);
  });
  return store;
};

export default getStore;
