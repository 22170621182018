import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  NavLink,
  Route,
  Switch,
} from 'react-router-dom';
import {
  profileStorePack,
  settingsAuthEntityStorePack,
} from '@verdaccio/crminfo';

import BonusCampaignsPage from './BonusCampaignsPage';
import Communications from './Communications';

import styles from '../../components/mainNav.styl';

const FreelancePlatform = ({ match, location }) => {
  return (
    <div>
      <ul className={styles.main_nav}>
        <li className={styles.item}>
          <NavLink activeClassName={location.pathname.match('communications') ? null : styles.active}  to="/campaigns">
            Bonus campaigns
          </NavLink>
        </li>
        <li className={styles.item}>
          <NavLink activeClassName={location.pathname.match('communications') ? styles.active : null} to="/campaigns/communications">
            Communications
          </NavLink>
        </li>
      </ul>

      <Switch>
        <Route path={`${match.url}/communications`} component={Communications} exact />
        <Route path={`${match.url}`} component={BonusCampaignsPage} exact />
      </Switch>
    </div>
  )
}

const mapStateToProps = state => {
  const profileEntity = state[profileStorePack.name];
  return {
    profile: profileEntity && profileEntity.data,
  };
};

const mapDispatchToProps = dispatch => ({
  getAuthSettings: () => {
    dispatch(settingsAuthEntityStorePack.getEntityAction());
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(FreelancePlatform);
