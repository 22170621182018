const TOKEN_KEY = 'token';

let memoryToken = null;

export const setToken = token => {
  localStorage.setItem(TOKEN_KEY, token);
  return token;
};

export const getToken = () => {
  if (!memoryToken) {
    memoryToken = localStorage.getItem(TOKEN_KEY);
  }
  return memoryToken;
};

export const getTokenExact = () => (getToken() || '').replace(/^(Bearer\s)/, '');

export const clearToken = () => {
  localStorage.removeItem(TOKEN_KEY);
  memoryToken = null;
};
