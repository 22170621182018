import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  utils,
  Table,
  pluginConfigs,
  mainHubActions,
  shiftsLoginsTableStorePack,
} from '@verdaccio/crminfo';

const ShiftsLogins = ({ openPlugin, openFingerprintPlugin, match, history, setSorting, ...other }) => {
  const goToShiftPlugin = () => {
    const userId = match.params.userId;
    if (userId && !isNaN(Number(userId))) {
      openPlugin(userId)
    } else if (userId) {
      history.push('/shifts/logins');
    }
  }
  useEffect(() => {
    goToShiftPlugin();
  }, [match.params]);
  const onClickRow = item => {
    history.push('/shifts/logins/' + item.user_id);
  }
  const tableConfig = {
    fields: [
      {
        name: 'Login date',
        formatter: item => utils.formatTime(item.created_at, 'dt'),
      },
      {
        name: 'User name',
        formatter: item => item.username,
      },
      {
        name: 'User email',
        formatter: item => item.email,
        onClick: item => onClickRow(item),
      },
      {
        name: 'Fingerprint',
        onClick: item => item.fingerprint && openFingerprintPlugin(item.fingerprint),
        formatter: item => item.fingerprint,
      },
    ],
  };
  return (
    <div>
      <Table {...other} tableConfig={tableConfig} sticky stickyThreshold="0px" mainLoader infinity />
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  console.log('state.shiftsLogins', state.shiftsLogins);
  return {
    list: state.shiftsLogins.data.list || [],
    loading: state.shiftsLogins.loading,
    loadingNext: state.shiftsLogins.loadingNext,
    page: state.shiftsLogins.data.page,
    total: state.shiftsLogins.data.total,
    sorting: state.shiftsLogins.sorting,
    countList: state.shiftsLogins.data.countList,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    load: () => dispatch(shiftsLoginsTableStorePack.getTableAction()),
    loadNext: () => dispatch(shiftsLoginsTableStorePack.getTableNextAction()),
    setSorting: (field, isAsc) => {
      dispatch(shiftsLoginsTableStorePack.setTableSortingAction(isAsc === null ? null : field, isAsc));
    },
    openPlugin: (userId) => dispatch(mainHubActions.addNewConfig(pluginConfigs.ShiftLoginPlugin({ userId, options: { closeUrl: '/shifts/logins' } }))),
    openFingerprintPlugin: (deviceId) => dispatch(mainHubActions.addNewConfig(pluginConfigs.ShiftDeviceUsersPlugin({ deviceId }))),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ShiftsLogins);
