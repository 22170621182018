import React from 'react';
import { connect } from 'react-redux';

import {
  controls,
  writerTags,
  writersTableStorePack,
} from '@verdaccio/crminfo';

const TAG_OPTIONS = writerTags.map(t => [t, t]);

const WriterTagFilterDropdown = (props) => {
  const { filterTags, setFilter, loading } = props;
  const filterTagsSelected = filterTags.length ? filterTags.join(', ') : 'All';

  return (
    <controls.DropdownChildren text={filterTagsSelected} loading={loading}>
      <controls.FilterPanelType3
        filterValue={filterTags || ''}
        fieldId='tags'
        onChange={setFilter}
        value={filterTags}
        options={TAG_OPTIONS}
        name='tags'
        width='widthMd'
        isMultiple
        withCustom
      />
    </controls.DropdownChildren>
  );
};

const mapStateToProps = (state) => {
  return {
    filterTags: state.writers.filter.tags || [],
    loading: state.writers.loading || false,
  };
};

const mapDispatchToProps = dispatch => ({
  setFilter: (value) => {
    dispatch(writersTableStorePack.setTableFilterAction('tags', value));
    dispatch(writersTableStorePack.getTableAction());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(WriterTagFilterDropdown);
