import React, { Component } from 'react';
import { IconMap } from './components/Icon';
import { Provider } from 'react-redux';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';

import DataPreload from './components/DataPreload';
import MainNav from './components/MainNav';
import CrmPlugin from './components/CrmPlugin';
import Search from './components/Search';
import DropdownSelectProject from './components/DropdownSelectProject';
import DropdownProfile from './components/DropdownProfile';
import PushNotification from './components/PushNotification';
import SocketsSubscription from './components/SocketsSubscription';
import ToSettingsBtn from './components/ToSettingsBtn';
import ToBugFormBtn from './components/ToBugFormBtn';
import ProtectedByRoleRoute from './components/ProtectedByRoleRoute';
import ToBugTrackerBtn from './components/ToBugTrackerBtn';


import TicketsPage from './pages/TicketsPage';
import OrdersPage from './pages/OrdersPage';
import PaymentAccountsPage from './pages/PaymentAccountsPage';
import PromosPage from './pages/PromosPage';
import CallbacksPage from './pages/CallbacksPage';
import ClientRefundsPage from './pages/ClientRefundsPage';
import FinancesPage from './pages/FinancesPage';
import WriterWithdrawalPage from './pages/WriterWithdrawalPage';
import ClientsPage from './pages/ClientsPage';
import WritersPage from './pages/WritersPage';
import ThreadsPage from './pages/ThreadsPage';
import AllocationPage from './pages/AllocationPage';
import FreelancePlatform from './pages/FreelancePlatform';
import SandboxPage from './pages/SandboxPage';
import ReportsPage from './pages/ReportsPage';
import LoginPage from './pages/LoginPage';
import ShiftsPage from './pages/ShiftsPage';
import SettingPage from './pages/SettingPage';
import BugTrackerPage from './pages/BugTrackerPage';
import DefenseControls from './pages/DefenseControls';
import SMSSender from './pages/SMSSender';
import { WFHTool } from '@verdaccio/crminfo';

import styles from './app.styl';

const NotFoundPage = () => <p className={styles.error}>Invalid request. Check request details and try again.</p>;

const LoggedContainer = () => {
  return (
    <DataPreload>
      <div className={styles.main_container}>
        <IconMap />
        <div className={styles.header}>
          <div className={styles.main_content}>
            <div className={styles.header_left}>
              <MainNav />
              <div className={styles.search_wrap}>
                <Route path="/tickets" component={Search.Ticket} />
                <Route path="/orders" component={Search.Order} />
                <Route path="/clients" component={Search.Client} />
                <Route path="/writers" component={Search.Writer} />
                <Route path="/threads" component={Search.Thread} />
                <Route path="/promos" component={Search.Promos} />
                <Route path="/allocation" component={Search.Allocation} />
                <Route path="/campaigns" component={Search.Bonus} />
                <Route path="/applicants" component={Search.Sandbox} />
                <Route path="/finances" component={Search.Finances} />
                <Route path="/writer_withdrawal" component={Search.Withdrawal} />
                <Route path="/client_refunds" component={Search.Refunds} />
                <Route path="/bug_tracker" component={Search.BugTracker} />
              </div>
              <ToBugFormBtn />
              <ToBugTrackerBtn />
            </div>
            <div className={styles.header_right}>
              <ToSettingsBtn />
              <DropdownSelectProject className={styles.project_btn} />
              <DropdownProfile />
            </div>
          </div>
        </div>
        <div className={styles.main_content}>
          <Switch>
            <ProtectedByRoleRoute needRole={['platform_manager', 'offf', 'fcm', 'csr_teamlead', 'irff']} path="/settings">
              <SettingPage />
            </ProtectedByRoleRoute>
            <Route path="/tickets" component={TicketsPage} exact />
            <Route path="/tickets/:parentType/:parentId/:ticketId" component={TicketsPage} exact />
            <Route path="/orders" component={OrdersPage} exact />
            <Route path="/orders/:orderId" component={OrdersPage} exact />
            <Route path="/allocation/:orderId/:type" component={AllocationPage} exact />
            <Route path="/clients" component={ClientsPage} exact />
            <Route path="/clients/:clientId" component={ClientsPage} exact />
            <ProtectedByRoleRoute needRole={['irff', 'offf_geekly', 'manager', 'offf', 'fcm', 'ntff']} path="/writers" exact redirectTo='/tickets'>
              <WritersPage />
            </ProtectedByRoleRoute>
            <ProtectedByRoleRoute needRole={['irff', 'offf_geekly', 'manager', 'offf', 'fcm', 'ntff']} path="/writers/:writerId" exact redirectTo='/tickets'>
              <WritersPage />
            </ProtectedByRoleRoute>
            <ProtectedByRoleRoute needRole={['irff', 'offf_geekly', 'manager', 'offf', 'fcm', 'ntff']} path="/writers/:writerId/:isGrader" exact redirectTo='/tickets'>
              <WritersPage />
            </ProtectedByRoleRoute>
            <Route path="/threads" component={ThreadsPage} exact />
            <Route path="/threads/:entity/:entityId" component={ThreadsPage} exact />
            <ProtectedByRoleRoute needRole={['manager']} path="/promos" exact redirectTo='/tickets'>
              <PromosPage />
            </ProtectedByRoleRoute>
            <ProtectedByRoleRoute needRole={['manager']} path="/promos/:discountId" exact redirectTo='/tickets'>
              <PromosPage />
            </ProtectedByRoleRoute>            
            <Route path="/campaigns" component={FreelancePlatform} />

            <ProtectedByRoleRoute needRole={['manager', 'csr', 'csr_at']} path="/callbacks" exact redirectTo='/tickets'>
              <CallbacksPage />
            </ProtectedByRoleRoute>
            
            <Route path="/reports" component={ReportsPage} exact />
            <Route path="/finances" component={FinancesPage} exact />
            <Route path="/writer_withdrawal" component={WriterWithdrawalPage} exact />
            <Route path="/client_refunds" component={ClientRefundsPage} exact />
            <Route path="/payment_accounts" component={PaymentAccountsPage} exact />
            <ProtectedByRoleRoute needRole={['irff', 'offf_geekly', 'manager', 'offf', 'fcm', 'ntff']} path="/applicants" exact redirectTo='/tickets'>
              <SandboxPage />
            </ProtectedByRoleRoute>
            <ProtectedByRoleRoute needRole={['irff', 'offf_geekly', 'manager', 'offf', 'fcm', 'ntff']} path="/applicants/:entityId" exact redirectTo='/tickets'>
              <SandboxPage />
            </ProtectedByRoleRoute>
            
            <Route path="/shifts" component={ShiftsPage} />
            <Route path="/bug_tracker" component={BugTrackerPage} exact />
            <Route path="/bug_tracker/:entityId" component={BugTrackerPage} exact />
            <ProtectedByRoleRoute needRole={['defense_controller']} path="/defense_controls">
              <DefenseControls />
            </ProtectedByRoleRoute>
            <ProtectedByRoleRoute needRole={['sms_sender']} path="/sms_sender">
              <SMSSender />
            </ProtectedByRoleRoute>
            <Redirect from="/" exact to="/tickets" />
            <Route component={NotFoundPage} />
          </Switch>
          <CrmPlugin />
        </div>
      </div>
      <SocketsSubscription />
      <PushNotification />
      <WFHTool.WFHContainerWrapper />
    </DataPreload>
  )
};

class App extends Component {
  render() {
    const { store } = this.props;
    return (
      <Provider store={store}>
        <Router>
          <Switch>
            <Route path="/login/:token" component={LoginPage} />
            <Route component={LoggedContainer} />
          </Switch>
        </Router>
      </Provider>
    );
  }
}

export default App;
