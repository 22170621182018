import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  pluginConfigs,
  mainHubActions,
  controls
} from '@verdaccio/crminfo';

import styles from './create-client-dropdown.styl';

class CreateClientButton extends Component {

  render() {
    return (
      <div>
        <div className={styles.btn_add}>
          <controls.Button onClick={() => { this.props.createClient(); }}>+</controls.Button>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    createClient: parentType => dispatch(mainHubActions.addNewConfig(pluginConfigs.CreateClientPlugin({ parentType }))),
  };
};

export default connect(null, mapDispatchToProps)(CreateClientButton);
