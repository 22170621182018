import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  utils,
  Table,
  pluginConfigs,
  mainHubActions,
  controls,
  shiftsTableStorePack,
} from '@verdaccio/crminfo';

import styles from './create-client-dropdown.styl';

const Shifts = ({ createShift, match, history, setSorting, ...other }) => {
  const goToShiftPlugin = () => {
    const shiftsId = match.params.shiftsId;
    if (shiftsId && !isNaN(Number(shiftsId))) {
      createShift(true, shiftsId)
    } else if (shiftsId) {
      history.push('/shifts');
    }
  }
  useEffect(() => {
    goToShiftPlugin();
  }, [match.params]);
  const onClickRow = item => {
    history.push('/shifts/' + item.id);
  }
  const tableConfig = {
    onClickRow: item => onClickRow(item),
    fields: [
      {
        name: 'Shift number',
        formatter: item => {
          return item.id
        },
      },
      {
        name: 'Shift start date',
        formatter: item => utils.formatTime(item.begin_time, 'dt'),
        sortingConfig: {
          name: 'begin_time',
          onChange: isAsc => setSorting('begin_time', isAsc),
        },
      },
      {
        name: 'Shift end date',
        formatter: item => utils.formatTime(item.end_time, 'dt'),
        sortingConfig: {
          name: 'end_time',
          onChange: isAsc => setSorting('end_time', isAsc),
        },
      },
      {
        name: 'Operators',
        formatter: item => item.users_count,
      },
      {
        name: 'Status',
        formatter: item => item.status,
      },
    ],
  };
  return (
    <div>
      <Table {...other} tableConfig={tableConfig} sticky stickyThreshold="0px" mainLoader infinity perPage={20} />
      <div className={styles.btn_add}>
        <controls.Button onClick={() => createShift()}>+</controls.Button>
      </div>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    list: state.shifts.data.list || [],
    loading: state.shifts.loading,
    loadingNext: state.shifts.loadingNext,
    page: state.shifts.data.page,
    total: state.shifts.data.total,
    sorting: state.shifts.sorting,
    countList: state.shifts.data.countList,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    load: () => dispatch(shiftsTableStorePack.getTableAction()),
    loadNext: () => dispatch(shiftsTableStorePack.getTableNextAction()),
    setSorting: (field, isAsc) => {
      dispatch(shiftsTableStorePack.setTableSortingAction(isAsc === null ? null : field, isAsc));
    },
    createShift: (editMode = false, shiftsId) => dispatch(mainHubActions.addNewConfig(pluginConfigs.ShiftPlugin({ editMode, shiftsId, options: { closeUrl: '/shifts' } }))),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Shifts);
