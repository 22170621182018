export const PAGES = {
  ORDERS: 'ORDERS',
  TICKETS: 'TICKETS',
  THREADS: 'THREADS',
  WRITERS: 'WRITERS',
  CLIENTS: 'CLIENTS',
  CAMPAIGNS: 'CAMPAIGNS',
  PROMOS: 'PROMOS',
  APPLICANTS: 'APPLICANTS',
  CALLBACKS: 'CALLBACKS',
  FINANCES: 'FINANCES',
  SMSTOOL: 'SMSTOOL',
};

const roles = {
  manager: 'manager',
  qaff: 'qaff',
  offf: 'offf',
  irff: 'irff',
  offf_geekly: 'offf_geekly',
  sm: 'fcm',
  csr: 'csr',
  csr_at: 'csr_at',
  finance_manager: 'finance_manager',
  ntff: 'ntff',
};

export const hasAcces = (pageId, userRoles) => {
  switch (pageId) {
    case PAGES.CAMPAIGNS:
      return !userRoles.includes(roles.ntff);
    case PAGES.FINANCES:
      return userRoles.includes(roles.finance_manager);
    default:
      return true;
  }
};
